//列表页面的基础model

<script>
import Background from "../components/Background.vue";
import {
  Table,
  TableColumn,
  Button,
  Form,
  FormItem,
  Input,
  Tag,
  Divider,
  Select,
  Option,
  // Dialog
} from "element-ui";
export default {
  components: {
    background: Background,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-button": Button,
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-tag": Tag,
    "el-divider": Divider,
    "el-select": Select,
    "el-option": Option,
  },
  //需要列表加载参数可以增加 params 对象
  //url 参数为必需，为列表API
  data() {
    return {
      searchText: "",
      tableData: [],
      loading: false,
      total: 1
    };
  },

  methods: {
    async beforeLoad() {
      //等待继承
    },
    async reload() {
      this.loading = true;
      //从继承子组件获取 url 与params
      let res;
      if (this.params) {
        res = await this.$axios.get(this.url, { params: this.params });
      } else {
        res = await this.$axios.get(this.url);
      }
      if (!res.data.success) {
        this.$message.error(res.data.reason);
      } else {
        this.tableData = res.data.result.list;
        this.total = res.data.result.recordAmount;
      }
      this.loading = false;
    },
    checkLoad() {
      //等待继承
    }
  },
  async created() {
    this.loading = true;
    await this.beforeLoad();
    await this.reload();
    console.log(this.tableData);
    this.checkLoad();
    this.loading = false;
  }
};
</script>
