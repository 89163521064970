<template>
    <div v-loading="isLoading">
        <el-container style="justify-content: center; align-items: center;  margin-bottom: 40px;">
            <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%; margin-right: 10px;">
                <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
                    :value="option.name"></el-option>
            </el-select>
            <el-select v-model="selectedType" placeholder="选择课程类型查询" style="width: 100%; margin-right: 10px;">
                <el-option v-for="(option, index) in courseTypeList" :key="index" :label="option"
                    :value="option"></el-option>
            </el-select>
            <el-button type="primary" @click="fetchCourses()">查询</el-button>
        </el-container>
        <el-divider>
            <h3 style="text-align: center; margin-bottom: 20px;">已选课程列表</h3>
        </el-divider>
        <el-table :data="courseList">
            <el-table-column prop="courseName" label="课程名称"></el-table-column>
            <el-table-column prop="startTime" label="课程开始时间"></el-table-column>
            <el-table-column prop="site" label="上课地点"></el-table-column>
            <el-table-column label="课程签到次数" align="center">
                <template slot-scope="scope">
                    <span :style="{
                        color: (scope.row.checkInTimes === 0 && scope.row.allTimes !== 0) ? 'red' :
                            (scope.row.checkInTimes === scope.row.allTimes || (scope.row.checkInTimes === 0 && scope.row.allTimes === 0)) ? 'green' :
                                '#FFA500'
                    }">
                        {{ scope.row.checkInTimes }}/{{ scope.row.allTimes }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.canCancel === 1" class="button-group">
                        <el-button @click="openDetailDialog(scope.row)" size="mini">查看</el-button>
                        <el-button @click="cancelSignup(scope.row)" size="mini">退课</el-button>
                    </div>
                    <div v-else-if="scope.row.canView===1" class="button-group">
                        <el-button @click="openDetailDialog(scope.row)" size="mini">查看</el-button>
                        <el-button @click="openGain(scope.row)" size="mini">查看成果</el-button>
                    </div>
                    <div v-else-if="scope.row.canSubmitGain===1" class="button-group">
                        <el-button @click="openDetailDialog(scope.row)" size="mini">查看</el-button>
                        <el-button @click="submitGain(scope.row)" size="mini">成果填报</el-button>
                    </div>
                    <div v-else>
                        <el-button @click="openDetailDialog(scope.row)" size="mini">查看</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>


        <!-- 课程详情弹窗 -->
        <el-dialog :visible.sync="detailDialogVisible" title="课程详细信息" width="500px">
            <el-form :model="currentCourse" label-width="120px">
                <el-form-item label="课程名称">
                    <el-input v-model="currentCourse.courseName" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程类型">
                    <el-input v-model="currentCourse.courseType" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程负责人姓名">
                    <el-input v-model="currentCourse.manager" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="授课容量">
                    <el-input v-model="currentCourse.capacity" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程开始时间">
                    <el-input v-model="currentCourse.startTime" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程结束时间">
                    <el-input v-model="currentCourse.endTime" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="报名开始时间">
                    <el-input v-model="currentCourse.signupStart" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="报名结束时间">
                    <el-input v-model="currentCourse.signupEnd" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="上课地点">
                    <el-input v-model="currentCourse.site" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="签到情况">
                    <el-collapse>
                        <el-collapse-item v-for="(item, index) in currentCourse.checkInList" :key="index" :title="'签到轮次 ' + item.round">
                            <el-row>
                                <strong>发布时间:</strong> {{ item.releaseTime }}
                            </el-row>
                            <el-row>
                                <strong>签到时间:</strong> {{ item.checkInTime }}
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :visible.sync="gainDialogVisible" title="填报成果" width="500px">
            <el-form label-width="120px">
                <el-form-item label="上传图片">
                    <el-upload
                        action="#"
                        list-type="picture-card"
                        :limit="3"
                        :file-list="uploadedImages"
                        :auto-upload="false"
                        :on-change="(file, fileList) => { handleImageChange(file, fileList); }"
                        :on-remove="(file, fileList) => { handleImageRemove(file, fileList); }"
                        accept="image/*" 
                    >
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">最多上传3张图片,且单张不超过5MB</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="心得">
                    <el-input
                        type="textarea"
                        v-model="gainDescription"
                        maxlength="200"
                        placeholder="请输入心得内容（0-200字）"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="gainDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitGainData" :disabled="!isUploadAllowed">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="gainViewVisible" title="查看成果" width="500px">
            <el-form label-width="120px">
                <el-form-item label="成果文字">
                    <el-input
                        type="textarea"
                        v-model="gainDescription"
                        :disabled="!isEditing"
                        maxlength="200"
                        placeholder="请输入心得内容（0-200字）"
                    ></el-input>
                </el-form-item>
                <el-form-item label="成果照片">
                    <el-upload
                        v-if="isEditing"
                        action="#"
                        list-type="picture-card"
                        :limit="3"
                        :file-list="uploadedImages"
                        :auto-upload="false"
                        :on-change="(file, fileList) => {  handleImageChange(file, fileList); }"
                        :on-remove="(file, fileList) => {  handleImageRemove(file, fileList); }"
                        accept="image/*" 
                    >
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">最多上传3张图片,且单张不超过5MB</div>
                    </el-upload>
                    <el-row v-else>
                        <el-col v-for="(image, index) in uploadedImages" :key="index" :span="8">
                            <el-image
                                :src="image.url"
                                :preview-src-list="uploadedImages.map(img => img.url)"
                                style="width: 100%; height: 100px; object-fit: cover; margin-bottom: 10px;"
                            ></el-image>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="gainViewVisible = false">关闭</el-button>
                <el-button 
                    v-if="currentCourse.canSubmitGain === 1" 
                    type="primary" 
                    @click="isEditing ? submitGainData() : toggleEdit()" :disabled="!isUploadAllowed"
                >{{ isEditing ? '保存修改并提交' : '修改' }}</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script>
import {
    Button,
    Form,
    TableColumn,
    Select,
    Option,
    Row,
    Table,
    Dialog,
    FormItem,
    Divider,
    Container,
    Collapse,
    CollapseItem,
    Upload,
    Image,
} from "element-ui";

export default {
    components: {
        "el-table": Table,
        "el-button": Button,
        "el-form": Form,
        "el-table-column": TableColumn,
        "el-select": Select,
        "el-option": Option,
        "el-row": Row,
        "el-dialog": Dialog,
        "el-form-item": FormItem,
        "el-divider": Divider,
        "el-container": Container,
        "el-collapse": Collapse,
        "el-collapse-item": CollapseItem,
        "el-upload": Upload,
        "el-image": Image,
    }, 
    data() {
        return {
            isLoading: true,
            selectedTerm: '',
            currentTerm: '',
            termList: [],
            courseTypeList: ["选修", "必修"],
            detailDialogVisible: false,
            gainDialogVisible: false,
            gainViewVisible: false,
            courseList: [],
            currentCourse: {},
            uploadedImages: [],
            gainDescription: '',
            selectedType: '',
            isEditing: false,
            isUploadAllowed: true,
        };
    },
    watch: {
        // 监视查看成果对话框的可见性
        gainViewVisible(newValue, oldValue) {
            if (!newValue && oldValue) {
                // 当对话框从可见变为不可见时，清空数据
                this.resetGainFormData();
            }
        }
    },
    methods: {

        resetGainFormData() {
            this.uploadedImages = [];
            this.gainDescription = '';
        },

        async loadTerm() {
            try {
                let termList = await this.$axios.get("/term");
                if (termList.data.success) {
                    this.termList = termList.data.result.list.reverse().slice(0, 2);
                    this.termList.push({ name: '全部学期', startDate: null, endDate: null });
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },

        async fetchCourses() {
            try {
                const response = await this.$axios.get(`/assistance/course/selected`, {
                    params: {
                        term: this.selectedTerm === '' ? null : this.selectedTerm,
                        isMandatory: this.selectedType === '选修' ? 1 : this.selectedType === '' ? null : 2,
                    },
                });
                if (response.data.success && Array.isArray(response.data.result)) {
                    this.courseList = response.data.result.map(course => ({
                        ...course,
                        startTime: this.formatDateString(course.startTime),
                        endTime: this.formatDateString(course.endTime),
                        signupStart: this.formatDateString(course.signupStart),
                        signupEnd: this.formatDateString(course.signupEnd),
                    }));
                    /*
                    this.courseList =[{
                        id: 1,
                        courseName: "资助专员答辩",
                        manager: "伍浩",
                        term: "2024-2025-秋季",
                        capacity: 32,
                        startTime: "2024-08-25 15:00",
                        endTime: "2024-08-25 16:00",
                        signupStart: "2024-08-25 15:00",
                        signupEnd: "2024-08-25 16:00",
                        site: "J1-302",
                        allTimes: 3,
                        checkInTimes: 2,
                        checkInList: [
                            {
                                round : 1,
                                releaseTime : "2024-08-25 15:00",
                                checkInTime : "2024-08-25 15:00",
                            },
                            {
                                round : 2,
                                releaseTime : "2024-08-25 15:00",
                                checkInTime : "2024-08-25 15:00",
                            },
                            {
                                round : 3,
                                releaseTime : "2024-08-25 15:00",
                                checkInTime : "",
                            },
                        ],
                        canCancel : 2,
                        canSubmitGain : 1,
                        canView: 1,

                    },]
                    */
                    
                } else {
                    this.$message.warning("查询失败");
                }
            } catch (error) {
                this.$message.error("查询课程列表时出错");
            }
        },

        handleImageChange(file, fileList) {
            console.log('Image Changed:', fileList);
            this.checkFilesBeforeUpdate(fileList);
        },

        handleImageRemove(file, fileList) {
            console.log('Image Removed:', fileList);
            this.checkFilesBeforeUpdate(fileList);
        },

        checkFilesBeforeUpdate(fileList) {
            // 检查所有文件是否满足大小要求
            const allFilesValid = fileList.every(file => file.size / 1024 / 1024 < 5);
            if (allFilesValid) {
                this.uploadedImages = fileList;
                this.isUploadAllowed = true; // 允许上传状态
            } else {
                this.$message.error('所有文件必须小于 5MB');
                this.isUploadAllowed = false; // 不允许上传状态
            }
        },

        openDetailDialog(course) {
            this.currentCourse = { ...course };

            // 自动设置课程类型
            const mandatoryCourses = [
                '资助专员答辩',
                '资助宣传大使答辩',
                '家访优秀团队答辩',
                '资助政策宣讲'
            ];

            const electiveCourses = [
                '德育培训班',
                '智育培训班',
                '体育培训班',
                '美育培训班',
                '劳育培训班',
                '其他'
            ];

            if (mandatoryCourses.includes(this.currentCourse.courseName)) {
                this.currentCourse.courseType = '必修';
            } else if (electiveCourses.includes(this.currentCourse.courseName)) {
                this.currentCourse.courseType = '选修';
            } else {
                this.currentCourse.courseType = '其他';
            }

            this.detailDialogVisible = true;
        },

        async cancelSignup(course) {
            try {
                const response = await this.$axios.delete(`/assistance/course/signout`, {
                    params: {
                        courseId: course.id,
                    },
                });
                if (response.data.success) {
                    this.$message.success("退课成功");
                    this.fetchCourses();
                } else {
                    this.$message.warning("退课失败");
                }
            } catch (error) {
                this.$message.error("退课时出错");
            }
        },

        submitGain(course) {
            this.currentCourse = { ...course };
            this.gainDialogVisible = true;
        },

        async submitGainData() {
            try {
                const formData = new FormData();
                formData.append("courseId", this.currentCourse.id);
                formData.append("text", this.gainDescription);

                // 保留原有的未删除的图片，将它们的 URL 转换为 File 对象并附加到 formData 中
                for (let i = 0; i < this.uploadedImages.length; i++) {
                    const file = this.uploadedImages[i];
                    if (file.url && !file.raw) {
                        // 将 URL 转换为 Blob，再创建 File 对象
                        const blob = await fetch(file.url).then(res => res.blob());
                        const fileToUpload = new File([blob], `photo${i + 1}.jpg`, { type: blob.type });
                        formData.append(`photo${i + 1}`, fileToUpload);
                    } else if (file.raw) {
                        // 新添加的图片使用 raw 属性进行上传
                        formData.append(`photo${i + 1}`, file.raw);
                    }
                }

                const response = await this.$axios.post(`/assistance/course/gain`, formData);

                if (response.data.success) {
                    this.$message.success("成果提交成功");
                    try {
                        const response = await this.$axios.get(`/assistance/course/gain`, {
                            params: {
                                courseID: this.currentCourse.id,
                            },
                        });

                        if (response.data.success) {
                            this.gainDescription = response.data.result.text;
                            this.uploadedImages = response.data.result.photos.map((url, index) => ({
                                name: `photo${index + 1}`,
                                url: url,
                            }));
                            //console.log(this.uploadedImages);
                            this.gainViewVisible = true;
                        } else {
                            this.$message.warning("获取成果信息失败");
                        }
                    } catch (error) {
                        this.$message.error("获取成果信息时出错");
                    }
                    // 检查 response.data.result 是否存在
                    this.isEditing = false; // 成功提交后退出编辑状态
                    this.gainDialogVisible = false;
                    this.fetchCourses();
                } else {
                    this.$message.warning("成果提交失败");
                }
            } catch (error) {
                console.log("提交成果时出错", error);
                this.$message.error("提交成果时出错");
            }
        },
        
        async openGain(course) {
            this.isEditing = false;
            this.currentCourse = { ...course };
            try {
                const response = await this.$axios.get(`/assistance/course/gain`, {
                    params: {
                        courseID: course.id,
                    },
                });

                if (response.data.success) {
                    this.gainDescription = response.data.result.text;
                    this.uploadedImages = response.data.result.photos.map((url, index) => ({
                        name: `photo${index + 1}`,
                        url: url,
                        size: 1,
                    }));
                    //console.log(this.uploadedImages);
                    this.gainViewVisible = true;
                } else {
                    this.$message.warning("获取成果信息失败");
                    this.gainViewVisible = true;
                }
            } catch (error) {
                this.$message.error("获取成果信息时出错");
            }
        },

        formatDateString(dateString) {
            const date = new Date(dateString);

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },

        toggleEdit() {
            this.isEditing = true;
        },
    },


    async created() {
        this.isLoading = true;
        await this.loadTerm();
        await this.fetchCourses();
        this.isLoading = false;
    },
};
</script>

<style scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-row {
    margin-bottom: 5px;
}

.el-col {
    padding-right: 10px;
}

.custom-popper {
    margin-left: 15px;
}

.centered-title {
    text-align: center;
    margin-bottom: 20px;
}

.dialog-footer {
    text-align: right;
}

::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
  color: black !important;
  /* 设置文本颜色为黑色 */
}
</style>