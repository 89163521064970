<template>
  <div id="app">
    <router-view v-if="isPreview || (hasLogin && hasRole)"></router-view>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import qs from "querystring";
export default {
  name: "app",
  components: {},
  data() {
    return {
      idsTicket: "",
      wechatCode: "",
      user: {},
      appid: "AAA4BA715B625EF7E0534577C03A7206",
    };
  },
  methods: {
    async loadWx() {
      let res = await this.$axios.post("/wxConfig", {
        url: window.location.href,
      });
      if (
        res.data.success &&
        (this.$device.isWechat || this.$device.isWxwork)
      ) {
        window.wx.config(res.data.result);
        window.wx.ready(function () { });
      }
    },
    async loadFunction() {
      let functionList = {};
      if (this.$store.state.role) {
        let res = await this.$axios.get(
          `/admin/authorization${this.$store.state.role.roleId
            ? "?role=" + this.$store.state.role.roleId
            : ""
          }`
        );
        if (res.data.success) {
          res.data.result.total.forEach((x) => {
            functionList[x.name] = false;
          });
          res.data.result.hasBound.forEach((item) => {
            functionList[item.name] = true;
          });
        }
      }
      let res2 = await this.$axios.get("/vote/list/participate");
      if (res2.data.success) {
        if (res2.data.result.list > 0) {
          functionList["参与投票"] = true;
        } else {
          functionList["参与投票"] = false;
        }
      }
      this.$store.commit("function", functionList);
    },
    async loadPermission() {
      let res = await this.$axios.get("/admin");
      if (!res.data.success) {
        this.$store.commit("permission", []);
        this.$message.error("权限获取失败，请刷新重试");
        return;
      }
      this.$store.commit("roleHasLoad", true);
      if (res.data.result.length == 0) {
        this.$store.commit("permission", []);
        return;
      }
      this.$store.commit("permission", res.data.result);
      if (
        !this.$store.state.role ||
        this.$store.state.permission.findIndex((x) => {
          return x.identityId == this.$store.state.role.identityId;
        }) == -1
      ) {
        this.$store.commit("role", this.$store.state.permission[0]);
      }
      this.$axios.defaults.headers[
        "x-api-role"
      ] = this.$store.state.role.identityId;
      await this.loadFunction();
    },
    initAxios() {
      this.$axios.defaults.baseURL = "https://xgbxscwx.seu.edu.cn/api/";
      this.$axios.defaults.headers["x-api-token"] = this.$store.state.token;
      if (this.$store.state.role.identityId) {
        this.$axios.defaults.headers[
          "x-api-role"
        ] = this.$store.state.role.identityId;
      }
    },
    getQueryString(key) {
      var after = window.location.search;
      if (window.location.href.indexOf("?") === -1) return null; //如果url中没有传参直接返回空

      //key存在先通过search取值如果取不到就通过hash来取
      after = after.substr(1) || window.location.hash.split("?")[1];

      if (after) {
        var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
        var r = after.match(reg);
        if (r != null) {
          return decodeURIComponent(r[2]);
        } else {
          return null;
        }
      }
    },
    // async login() {
    //   let loading = this.$loading({ fullscreen: true });
    //   //判断是否带参数，注意此处不能用原生search，tommyOauth传入参数无法从search获取到
    //   if (window.location.href.indexOf("?") !== -1) {
    //     // 检测当前页面的ticket和heraldCode
    //     let ticket = qs.parse(window.location.search.replace("?", "")).ticket;
    //     let code = qs.parse(window.location.search.replace("?", "")).code;
    //     let heraldCode = this.getQueryString("heraldCode");
    //     // let tommyState = this.getQueryString("tommyState");
    //     if (ticket) {
    //       // 包含 ticket，开启登录流程
    //       let res = await this.$axios.post("/auth", {
    //         ticket,
    //         service: "https://xgbxscwx.seu.edu.cn",
    //         platform: this.$device.platform(),
    //       });
    //       if (res.data.success) {
    //         this.$store.commit("token", res.data.result);
    //         //通知页面刷新
    //         window.location.search = "";
    //         loading.close();
    //         return; // 很重要，不要进入后面的流程
    //       } // 执行到此处说明ticket无效，给他清理了就是了
    //     } else if (code) {
    //       // 企业微信对接
    //       let res = await this.$axios.post("/auth", {
    //         code,
    //         platform: this.$device.platform(),
    //       });
    //       if (res.data.success) {
    //         this.$store.commit("token", res.data.result);
    //         window.location.search = "";
    //         loading.close();
    //         return; // 很重要，不要进入后面的流程
    //       } // 执行到此处说明ticket无效，给他清理了就是了
    //     } else if (heraldCode) {
    //       //接收heraldCode，直接通过后端认证
    //       let res = await this.$axios.post("/auth", {
    //         heraldCode: heraldCode,
    //         platform: this.$device.platform(),
    //       });
    //       if (res.data.success) {
    //         //tommy的oauth流程不保留路由 故流程特殊
    //         this.$store.commit("token", res.data.result);

    //         this.$message.success(`认证成功，请稍等~`);
    //         window.location.search = "";
    //         loading.close();
    //         return;
    //         // this.initAxios();
    //         // // await this.loadPermission();
    //         // //直接访问传入路由
    //         // this.$router.replace(
    //         //   window.location.href.split("#")[1].split("?")[0]
    //         // );
    //         //在webview中，性能较差，为避免异步问题与刷新页面造成问题，等待一秒
    //         // await new Promise(resolve => setTimeout(resolve, 1000));
    //       }
    //       //window.location.search = ''
    //     }
    //   }

    //   let res = await this.$axios.get("/user");
    //   if (window.navigator.webdriver || window.navigator.calledSelenium) {
    //     this.$message.error("恶意脚本不可取哦~");
    //     return;
    //   }

    //   if (!res.data.success) {
    //     // 如果获取失败，就说明 token 无效了
    //     // 首先保存当前路由
    //     this.$store.commit("clearCacheWithoutRoute");
    //     this.$store.commit("saveUnfinishedRoute", {
    //       params: this.$route.params,
    //       fullPath: this.$route.fullPath,
    //     });
    //     await new Promise((resolve) => setTimeout(resolve, 1000));
    //     // console.log(this.$store.state.unfinishedRoute);
    //     // eslint-disable-next-line no-constant-condition
    //     //if (window.navigator.userAgent == "TommyApp/3.x.x (iOS; Android)") {
    //     if (window.navigator.userAgent.includes("tommy")) {
    //       //小猴app接入的url由app维护，不需要单独存储
    //       //检测到小猴环境，进入小猴oauth流程
    //       window.location = `tommy://oauth.seu.edu.cn?appid=${this.appid}&redirectUri=https://xgbxscwx.seu.edu.cn/`;
    //     } else if (this.$device.isWxwork) {
    //       // 企业微信
    //       window.location = `http://121.248.63.185/sso/oauth2/authorize?response_type=code&redirect_uri=https://xgbxscwx.seu.edu.cn&client_id=iLIJpZEzDT7oeq4HO5rw`;
    //     } else if (this.$device.isWechat) {
    //       window.location = `https://xgbxscwx.seu.edu.cn/cas-we-can/login?service=https://xgbxscwx.seu.edu.cn`;
    //     } else {
    //       window.location = `https://auth.seu.edu.cn/dist/#/dist/main/login?service=https://xgbxscwx.seu.edu.cn`;
    //     }
    //     return; //其实并不会执行到这 如果token无效现在已跳转到登录界面
    //   }
    //   //用户信息获取成功 先保存一卡通
    //   this.$store.commit("cardnum", res.data.result.cardnum);
    //   //加载保存的路由
    //   if (this.$store.state.hasUnfinishedRoute) {
    //     // token 有效，并且还有未完成的路由
    //     //如果保存路由是首页就不用动啦
    //     if (this.$store.state.unfinishedRoute.fullPath !== "/") {
    //       this.$router.push({
    //         path: this.$store.state.unfinishedRoute.fullPath,
    //         params: this.$store.state.unfinishedRoute.params,
    //       });
    //     }
    //     //清理保存路由
    //     this.$store.commit("clearUnfinishedRoute");
    //   }
    //   //获取权限
    //   await this.loadPermission();
    //   loading.close();
    // },
    async login() {
      let loading = this.$loading({ fullscreen: true });
      //判断是否带参数，注意此处不能用原生search，tommyOauth传入参数无法从search获取到
      if (window.location.href.indexOf("?") !== -1) {
        // 检测当前页面的ticket和heraldCode
        let ticket = qs.parse(window.location.search.replace("?", "")).ticket;
        let code = qs.parse(window.location.search.replace("?", "")).code;
        let heraldCode = this.getQueryString("heraldCode");
        // let tommyState = this.getQueryString("tommyState");

        if (this.$device.isWxwork && ticket && !code && !heraldCode) {
          // 企业微信环境下，使用 CAS-We-Can 认证方式
          let res = await this.$axios.post("/auth", {
            ticket,
            service: "https://xgbxscwx.seu.edu.cn",
            platform: this.$device.platform(),
          });
          if (res.data.success) {
            this.$store.commit("token", res.data.result);
            //通知页面刷新
            window.location.search = "";
            loading.close();
            return; // 很重要，不要进入后面的流程
          } // 执行到此处说明ticket无效，给他清理了就是了
        } else if (ticket && !this.$device.isWxwork) {
          // 包含 ticket，开启登录流程（非企业微信环境）
          let res = await this.$axios.post("/auth", {
            ticket,
            service: "https://xgbxscwx.seu.edu.cn",
            platform: this.$device.platform(),
          });
          if (res.data.success) {
            this.$store.commit("token", res.data.result);
            //通知页面刷新
            window.location.search = "";
            loading.close();
            return; // 很重要，不要进入后面的流程
          } // 执行到此处说明ticket无效，给他清理了就是了
        } else if (heraldCode && !this.$device.isWxwork) {
          //接收heraldCode，直接通过后端认证（非企业微信环境）
          let res = await this.$axios.post("/auth", {
            heraldCode: heraldCode,
            platform: this.$device.platform(),
          });
          if (res.data.success) {
            //tommy的oauth流程不保留路由 故流程特殊
            this.$store.commit("token", res.data.result);

            this.$message.success(`认证成功，请稍等~`);
            window.location.search = "";
            loading.close();
            return;
            // this.initAxios();
            // // await this.loadPermission();
            // //直接访问传入路由
            // this.$router.replace(
            //   window.location.href.split("#")[1].split("?")[0]
            // );
            //在webview中，性能较差，为避免异步问题与刷新页面造成问题，等待一秒
            // await new Promise(resolve => setTimeout(resolve, 1000));
          }
          //window.location.search = ''
        } else if (code && !this.$device.isWxwork) {
          // 企业微信对接（非企业微信环境，此处保留以防万一）
          let res = await this.$axios.post("/auth", {
            code,
            platform: this.$device.platform(),
          });
          if (res.data.success) {
            this.$store.commit("token", res.data.result);
            window.location.search = "";
            loading.close();
            return; // 很重要，不要进入后面的流程
          } // 执行到此处说明code无效，给他清理了就是了
        }
      }

      let res = await this.$axios.get("/user");
      if (window.navigator.webdriver || window.navigator.calledSelenium) {
        this.$message.error("恶意脚本不可取哦~");
        return;
      }

      if (!res.data.success) {
        // 如果获取失败，就说明 token 无效了
        // 首先保存当前路由
        this.$store.commit("clearCacheWithoutRoute");
        this.$store.commit("saveUnfinishedRoute", {
          params: this.$route.params,
          fullPath: this.$route.fullPath,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // console.log(this.$store.state.unfinishedRoute);
        // eslint-disable-next-line no-constant-condition
        //if (window.navigator.userAgent == "TommyApp/3.x.x (iOS; Android)") {
        if (window.navigator.userAgent.includes("tommy")) {
          //小猴app接入的url由app维护，不需要单独存储
          //检测到小猴环境，进入小猴oauth流程
          window.location = `tommy://oauth.seu.edu.cn?appid=${this.appid}&redirectUri=https://xgbxscwx.seu.edu.cn/`;
        } else if (this.$device.isWxwork) {
          // 企业微信环境下，跳转到 CAS-We-Can 登录页面
          window.location = `https://auth.seu.edu.cn/dist/#/dist/main/login?service=https://xgbxscwx.seu.edu.cn`;
        } else if (this.$device.isWechat) {
          window.location = `https://xgbxscwx.seu.edu.cn/cas-we-can/login?service=https://xgbxscwx.seu.edu.cn`;
        } else {
          window.location = `https://auth.seu.edu.cn/dist/#/dist/main/login?service=https://xgbxscwx.seu.edu.cn`;
        }
        return; //其实并不会执行到这 如果token无效现在已跳转到登录界面
      }
      //用户信息获取成功 先保存一卡通
      this.$store.commit("cardnum", res.data.result.cardnum);
      //加载保存的路由
      if (this.$store.state.hasUnfinishedRoute) {
        // token 有效，并且还有未完成的路由
        //如果保存路由是首页就不用动啦
        if (this.$store.state.unfinishedRoute.fullPath !== "/") {
          this.$router.push({
            path: this.$store.state.unfinishedRoute.fullPath,
            params: this.$store.state.unfinishedRoute.params,
          });
        }
        //清理保存路由
        this.$store.commit("clearUnfinishedRoute");
      }
      //获取权限
      await this.loadPermission();
      loading.close();
    },

  },
  computed: {
    isPreview() {
      return window.location.href.includes("preview")
    },
    hasRole() {
      return this.$store.state.roleHasLoad;
    },
    hasLogin() {
      return this.$store.state.token;
    },
  },
  async created() {

    //先初始化axios参数
    this.initAxios();
    if (this.isPreview) return //预览页面不要管那么多
    //登录流程
    await this.login();
    if (this.$device.isWechat) {
      await this.loadWx();
    }
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: #f0f0f0;
}

#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.content-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.yxl-confirm {
  width: 80%;
}
</style>
