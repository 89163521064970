import ExportJsonExcel from "js-export-excel";
//以数组数据导出excel表格
//map 为字段名到中文的映射表
export default function (map, data, fileName) {
    let option = {
        fileName: fileName,
        datas: [
            //可多张sheet
            {
                sheetData: data, 
                sheetName: fileName, 
                sheetFilter: Object.keys(map),
                sheetHeader: Object.keys(map).map(x=>map[x])
            }
        ]
    };
    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
}