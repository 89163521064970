Date.prototype.format = function(fmt){
    const o = {
      "M+" : this.getMonth()+1,                 //月份
      "d+" : this.getDate(),                    //日
      "h+" : this.getHours(),                   //小时
      "m+" : this.getMinutes(),                 //分
      "s+" : this.getSeconds(),                 //秒
      "q+" : Math.floor((this.getMonth()+3)/3), //季度
      "S"  : this.getMilliseconds()             //毫秒
    };
  
    // RegExp.$的语法已经不推荐使用了
    // if(/(y+)/.test(fmt)){
    //   fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    // }

    fmt = fmt.replace(/(y+)/,(match)=>{
      return this.getFullYear().toString().substring(4 - match.length);
    })
          
    for(let k in o){
      fmt = fmt.replace(new RegExp(`(${k})`), (match)=>{
        return match.length === 1 ? o[k] : `00${o[k]}`.substring(o[k].toString().length);
      }) 
    }
  
    return fmt;
  }