<template>
    <background title="学生资助" desc="目前仅支持web端">
        <!-- <el-container style="justify-content: center; align-items: center;  margin-bottom: 20px;">
            <el-select v-model="selectedRole" placeholder="请选择角色" style="width: 160px; margin-left: 10px;">
                <el-option v-for="(option, index) in roleList" :key="index" :label="option.label"
                    :value="option.value"></el-option>
            </el-select>
            <el-button type="primary" @click="changeRole(selectedRole)" style="margin-left: 20px;">切换</el-button>
        </el-container> -->

        <div class="activity-management">
            <el-tabs v-model="activeMainTab" @tab-click="handleMainTabClick">
                <!-- 谈心谈话 Tab -->
                <el-tab-pane label="谈心谈话" name="talkConversation" v-if="isAdmin || isInstructor">
                    <!--  -->
                    <el-tabs v-model="activeSubTab" @tab-click="handleSubTabClick">
                        <el-tab-pane label="查看谈心谈话记录" name="viewTalkRecords" v-if="isAdmin || isInstructor">
                            <!--  -->
                            <keep-alive>
                                <view-record v-if="activeSubTab === 'viewTalkRecords'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="记录谈心谈话" name="recordTalk" v-if="isAdmin">
                            <keep-alive>
                                <talk-with-student v-if="activeSubTab === 'recordTalk'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>

                <el-tab-pane label="育人活动" name="educationActivity">
                    <el-tabs v-model="activeSubTab" @tab-click="handleSubTabClick">
                        <el-tab-pane label="创建活动" :name="isMobile ? 'createActivitiesMobile' : 'createActivities'"
                            v-if="isAdmin || isClubManager || isTeacher">
                            <keep-alive>
                                <component :is="isMobile ? 'create-activities-mobile' : 'create-activities'"
                                    v-if="activeSubTab === (isMobile ? 'createActivitiesMobile' : 'createActivities')"
                                    :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>

                        <el-tab-pane label="审核活动" name="checkActivity" v-if="isTeacher || isAdmin">
                            <keep-alive>
                                <check-activity v-if="activeSubTab === 'checkActivity'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="活动信息" name="activitiesInfo"
                            v-if="isClubManager || isActivityManager || isAdmin || isTeacher">
                            <keep-alive>
                                <activities-info v-if="activeSubTab === 'activitiesInfo'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="学生信息" name="studentInfo" v-if="isAdmin">
                            <keep-alive>
                                <student-info v-if="activeSubTab === 'studentInfo'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="审核学生申请" name="checkStudent"
                            v-if="isClubManager || isActivityManager || isTeacher || isAdmin">
                            <keep-alive>
                                <check-student v-if="activeSubTab === 'checkStudent'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>

                        <el-tab-pane label="学生信息-辅导员" name="studentInfoTutor" v-if="isInstructor">
                            <keep-alive>
                                <view-activity-tutor v-if="activeSubTab === 'studentInfoTutor'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>

                        <el-tab-pane label="报名活动" name="studentMainPage"
                            v-if="isStudent || isClubManager || isActivityManager">
                            <keep-alive>
                                <student-main-page v-if="activeSubTab === 'studentMainPage'" :key="activeSubTab" />
                            </keep-alive>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>
                <el-tab-pane label="育人课程" name="educationalCourse">
                    <el-tabs v-model="activeSubTab" @tab-click="handleSubTabClick">
                        <el-tab-pane label="创建课程" name="createCourse" v-if="isAdmin">
                            <keep-alive>
                                <create-course v-if="activeSubTab === 'createCourse'"
                                    :key="activeSubTab"></create-course>
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="全部课程" name="allCourses" v-if = "isAdmin || isStudent">
                            <keep-alive>
                                <all-courses v-if="activeSubTab === 'allCourses'" :key="activeSubTab"></all-courses>
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="我负责的课程" name="coursesBelongToMe" v-if="isAdmin || isInstructor">
                            <keep-alive>
                                <courses-in-charge v-if="activeSubTab === 'coursesBelongToMe'"
                                    :key="activeSubTab"></courses-in-charge>
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="已选课程" name="selectCourses" v-if="isStudent">
                            <!--权限先为管理员，方便测试-->
                            <keep-alive>
                                <select-courses v-if="activeSubTab === 'selectCourses'"
                                    :key="activeSubTab"></select-courses>
                            </keep-alive>
                        </el-tab-pane>
                        <el-tab-pane label="学生课程" name="studentCourses" v-if="isAdmin || isInstructor">
                            <!--权限先为管理员，方便测试-->
                            <keep-alive>
                                <student-courses v-if="activeSubTab === 'studentCourses'"
                                    :key="activeSubTab"></student-courses>
                            </keep-alive>
                        </el-tab-pane>
                    </el-tabs>

                </el-tab-pane>

            </el-tabs>
        </div>
    </background>
</template>

<script>
import Background from "../components/Background.vue";
import { Tabs, TabPane } from 'element-ui';
import TalkWithStudent from "../components/studentAssistance/TalkWithStudent";
import ViewRecord from "../components/studentAssistance/ViewRecord.vue";
import ActivitiesInfo from "../components/educationActivity/ActivitiesInfo.vue";
import CreateActivities from "../components/educationActivity/CreateActivities.vue";
import StudentInfo from "../components/educationActivity/StudentInfo.vue";
import CheckStudent from "../components/educationActivity/CheckStudent.vue";
import CheckActivity from "../components/educationActivity/CheckActivity.vue";
import ViewActivityTutor from '../components/educationActivity/ViewActivity-tutor.vue';
import StudentMainPage from "../components/educationActivity/StudentPage/StudentMainPage.vue";
import CreateActivitiesMobile from "../components/educationActivity/CreateActivities-mobile.vue";
import CreateCourse from "../components/educationalCourse/CreateCourse.vue";
import AllCourses from "../components/educationalCourse/AllCourses.vue";
import CoursesInCharge from "../components/educationalCourse/CoursesInCharge.vue";
import selectCourses from "../components/educationalCourse/selectCourses.vue";
import studentCourses from "../components/educationalCourse/studentCourses.vue";

export default {
    components: {
        background: Background,
        'el-tabs': Tabs,
        'el-tab-pane': TabPane,
        'talk-with-student': TalkWithStudent,
        'view-record': ViewRecord,
        'activities-info': ActivitiesInfo,
        'create-activities': CreateActivities,
        'create-activities-mobile': CreateActivitiesMobile,
        'student-info': StudentInfo,
        'check-student': CheckStudent,
        'check-activity': CheckActivity,
        'view-activity-tutor': ViewActivityTutor,
        'student-main-page': StudentMainPage,
        'create-course': CreateCourse,
        'all-courses': AllCourses,
        'courses-in-charge': CoursesInCharge,
        'select-courses': selectCourses,
        'student-courses': studentCourses,
    },
    data() {
        return {
            activeMainTab: 'educationActivity',
            activeSubTab: 'viewTalkRecords',
            role: null,
            roleList: [
                { label: "管理员", value: 1 },
                { label: "社团指导老师", value: 2 },
                { label: "社团负责人", value: 3 },
                { label: "活动负责人", value: 4 },
                { label: "辅导员", value: 5 },
                { label: "学生", value: 6 },
            ],
            selectedRole: 1,
            isMobile: window.innerWidth <= 768,
        };
    },
    computed: {
        isAdmin() {
            return this.role === '管理员';
        },
        isInstructor() {
            return this.role === '辅导员';
        },
        isStudent() {
            return this.role === '学生';
        },
        isTeacher() {
            return this.role === '社团指导老师';
        },
        isClubManager() {
            return this.role === '社团负责人';
        },
        isActivityManager() {
            return this.role === '活动负责人';
        },
    },
    methods: {
        async getRole() {
            try {
                const response = await this.$axios.get("/assistance/role", {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    }
                });
                if (response.data.success) {
                    this.role = response.data.result;
                } else {
                    this.$message({
                        type: "warning",
                        message: "权限不存在",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "查询权限信息时出错",
                });
            }
        },

        handleMainTabClick(tab) {
            this.activeMainTab = tab.name;
            this.activeSubTab = this.getDefaultSubTab(tab.name);
        },

        handleSubTabClick(tab) {
            this.activeSubTab = tab.name;
        },

        getDefaultMainTab() {
            if (this.role === '管理员' || this.role === '辅导员') return 'talkConversation';
            else return "educationActivity";
        },

        getDefaultSubTab(mainTabName) {
            // 定义一个对象来映射主标签页到默认子标签页
            const defaultSubTabsForRoles = {
                "管理员": {
                    talkConversation: 'viewTalkRecords',
                    educationActivity: this.isMobile ? 'createActivitiesMobile' : 'createActivities',
                    educationalCourse: 'createCourse'
                },
                "学生": {
                    educationActivity: 'studentMainPage',
                    educationalCourse: 'allCourses',
                },
                "辅导员": {
                    talkConversation: 'viewTalkRecords',
                    educationActivity: 'studentInfoTutor',
                    educationalCourse: 'coursesBelongToMe'
                },
                "社团负责人": {
                    educationActivity: this.isMobile ? 'createActivitiesMobile' : 'createActivities'
                },
                "活动负责人": {
                    educationActivity: 'checkStudent'
                },
                "社团指导老师": {
                    educationActivity: this.isMobile ? 'createActivitiesMobile' : 'createActivities'
                }
            };

            // 获取当前角色对应的默认标签页映射
            if (this.role in defaultSubTabsForRoles) {
                return defaultSubTabsForRoles[this.role][mainTabName]; // 保险起见，提供一个后备选项
            }
            return this.isMobile ? 'createActivitiesMobile' : 'createActivities'; // 如果没有匹配的角色信息，回退到通用默认值
        },

        async changeRole(roleValue) {
            const headers = {
                "x-api-token": this.$axios.defaults.headers["x-api-token"],
            };
            const params = {
                limit: roleValue,
            };
            try {
                const response = await this.$axios.get('/assistance/limit', { headers, params });
                if (response.data.success) {
                    await this.getRole();  // 重新获取角色
                    this.activeMainTab = this.getDefaultMainTab();  // 根据新角色设置默认主标签页
                    this.activeSubTab = this.getDefaultSubTab(this.activeMainTab);  // 设置默认子标签页
                    this.$message({
                        type: 'success',
                        message: '切换成功',
                    });
                }
            } catch (error) {
                console.error('切换角色失败:', error);
                this.$message.error('切换角色时发生错误');
            }
        }
    },
    created() {
        this.getRole().then(() => {
            // 一旦角色被确认后再设置默认标签页
            if (this.role === '管理员' || this.role === '辅导员') this.activeMainTab = 'talkConversation'
            this.activeSubTab = this.getDefaultSubTab(this.activeMainTab);
        });
    }
};
</script>
<style scoped>
.app-tabs {
    position: flex;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}
</style>
