<template>
  <div v-loading="isLoading">
    <el-row :gutter="24">
      <!-- 左边的列 -->
      <el-col :span="24">
        <el-form class="custom-popper">
          <el-row>
            <el-col :span="16">
              <el-form-item label="学期选择">
                <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%">
                  <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
                    :value="option.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="谈话内容">
                <el-input type="textarea" v-model="talkContent" placeholder="50-500字之间" :rows="4" maxlength="500"
                  show-word-limit style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="谈话日期">
                <el-date-picker v-model="talkTime.date" type="date" placeholder="请选择日期" format="yyyy/MM/dd"
                  value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="谈话时间">
                <el-time-picker v-model="talkTime.time" placeholder="选填具体时间" format="HH:mm:ss" value-format="HH:mm:ss"
                  style="width: 100%"></el-time-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="记录人">
                <el-input v-model="recorder" placeholder="请输入记录人" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="谈话地点">
                <el-select v-model="selectedLocation" placeholder="请选择" style="width: 100%">
                  <el-option v-for="(location, i) in talkLocations" :key="i" :value="location"
                    :label="location.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="查询学生信息">
                <el-input v-model="searchQuery" placeholder="请输入姓名或一卡通号查询" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-button type="primary" @click="searchStudent" style="margin-top: 8px">查询</el-button>
            </el-col>
          </el-row>
          <el-row v-if="studentList.length > 1">
            <el-col :span="16">
              <el-form-item label="选择学生">
                <el-select v-model="selectedStudent" placeholder="请选择学生" @change="updateStudentInfo"
                  style="width: 100%">
                  <el-option v-for="(student, index) in studentList" :key="index"
                    :label="`${student.name}(${student.id})`" :value="student"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <h3>学生信息</h3>
                <p class="info-text">姓名：{{ studentInfo.name }}</p>
                <p class="info-text">一卡通号：{{ studentInfo.cardNumber }}</p>
                <p class="info-text">性别：{{ studentInfo.genderString }}</p>
                <p class="info-text">学院：{{ studentInfo.college }}</p>
                <p class="info-text">年级：{{ studentInfo.grade }}</p>
                <p class="info-text">困难类型：{{ studentInfo.difficultyType }}</p>
                <p class="info-text">困难等级：{{ studentInfo.difficultyLevel }}</p>
                <!-- <p class="info-text">困难说明：{{ studentInfo.difficultyDescription }}</p> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button type="primary" @click="submit" style="margin-top: 8px">提交</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  DatePicker,
  TimePicker,
  Col,
  Row,
} from "element-ui";
export default {
  components: {
    "el-button": Button,
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-select": Select,
    "el-option": Option,
    "el-date-picker": DatePicker,
    "el-time-picker": TimePicker,
    "el-col": Col,
    "el-row": Row,
  },
  data() {
    return {
      selectedTerm: "",
      isLoading: true,
      termList: [],
      talkTime: {
        date: "",
        time: "",
      },
      selectedLocation: "",
      talkLocations: [],
      recorder: "",
      searchQuery: "",
      studentList: [],
      selectedStudent: null,
      studentInfo: {
        name: "",
        cardNumber: "",
        gender: undefined,
        genderString: "",
        college: "",
        grade: undefined,
        difficultyType: "",
        difficultyLevel: undefined,
        difficultyDescription: "",
      },
      talkContent: "",
    };
  },
  methods: {
    async loadTerm() {
      try {
        let termList = await this.$axios.get("/term");
        if (termList.data.success) {
          this.termList = termList.data.result.list.reverse().slice(0, 2);
          this.selectedTerm = termList.data.result.currentTerm.name;
          this.currentTerm = termList.data.result.currentTerm.name;
        } else {
          this.$message({
            type: "warning",
            message: "学期列表加载失败",
          });
        }
      } catch (error) {
        console.error("加载学期列表时出错:", error);
        this.$message({
          type: "error",
          message: "加载学期列表时出错",
        });
      }
    },
    async loadLocation() {
      try {
        const response = await this.$axios.get('/assistance/conversation/address');
        if (response.data.success) {
          this.talkLocations = response.data.result;
        } else {
          this.$message.error('获取数据失败');
        }
      } catch (error) {
        this.$message.error('请求失败');
      }
    },
    async searchStudent() {
      try {
        const response = await this.$axios.get(`/assistance/conversation/search-stu-info`, {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: {
            nameID: this.searchQuery,
          },
        });
        if (response.data.success) {
          if (response.data.result.length === undefined) {
            this.$message({
              type: "warning",
              message: "学生不存在",
            });
            return;
          }
          this.studentList = response.data.result;
          if (this.studentList.length === 1) {
            this.updateStudentInfo(this.studentList[0]);
          }
        } else {
          this.$message({
            type: "warning",
            message: "学生信息查询失败",
          });
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "查询学生信息时出错",
        });
      }
    },
    updateStudentInfo(student) {
      this.selectedStudent = `${student.name}(${student.id})`;
      this.studentInfo = {
        name: student.name,
        cardNumber: student.id,
        gender: student.gender,
        genderString: student.gender,
        college: student.school,
        grade: student.grade,
        difficultyType: student.trouble_type,
        difficultyLevel: student.trouble_grade,
        difficultyDescription: student.trouble_explain,
      };
    },
    async submit() {
      if (
        !this.selectedTerm ||
        !this.talkTime.date ||
        !this.talkTime.time ||
        !this.recorder ||
        !this.selectedLocation ||
        !this.studentInfo.name ||
        !this.studentInfo.grade ||
        !this.talkContent ||
        !this.studentInfo
      ) {
        this.$message({
          type: "warning",
          message: "请填写所有必填项",
        });
        return;
      }
      if (this.talkContent.length < 50 || this.talkContent.length > 500) {
        this.$message({
          type: "warning",
          message: "谈话内容字数在50-500之间",
        });
        return;
      }
      const formData = {
        semester: this.selectedTerm,
        time: this.talkTime.date + " " + this.talkTime.time,
        recorderName: this.recorder,
        address: this.selectedLocation,
        studentInfo: {
          stuName: this.studentInfo.name,
          stuId: this.studentInfo.cardNumber,
          stuSchool: this.studentInfo.college,
          stuGrade: this.studentInfo.grade,
          stuGender: this.studentInfo.gender,
          stuHardRating: this.studentInfo.difficultyLevel,
          stuHardType: this.studentInfo.difficultyType,
          stuHardIntro: this.studentInfo.difficultyDescription,
        },
        content: this.talkContent,
      };
      try {
        const response = await this.$axios.post(`/assistance/conversation/commit`, formData);
        if (response.data.success) {
          this.$message({
            type: "success",
            message: "表单提交成功",
          });
          this.resetForm();
        } else {
          this.$message({
            type: "error",
            message: "表单提交失败",
          });
        }
      } catch (error) {
        console.error("表单提交出错:", error);
        this.$message({
          type: "error",
          message: "表单提交出错",
        });
      }
    },
    resetForm() {
      this.selectedTerm = "";
      this.talkTime.date = "";
      this.talkTime.time = "";
      this.recorder = "";
      this.selectedLocation = "";
      this.talkContent = "";
      this.studentInfo = "";
      this.studentList = [];
      this.searchQuery = "";
      this.selectedStudent = "";
    },
  },
  async created() {
    this.loadTerm();
    this.loadLocation();
    // 全部加载结束后

    this.isLoading = false;
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 20px;
}

.el-row {
  margin-bottom: 5px;
  /* 调整行间距 */
}

.el-col {
  padding-right: 10px;
  /* 调整右侧间距 */
}

.custom-popper {
  margin-left: 30px;
  /* 确保学期选择框左边距 */
}
</style>
