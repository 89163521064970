<template>
    <div v-loading="isLoading">
        <el-container style="justify-content: center; align-items: center; margin-bottom: 40px;">
            <el-autocomplete 
                v-model="searchQuery" 
                :fetch-suggestions="fetchStudentSuggestions"
                placeholder="请输入学生姓名或一卡通号" 
                style="width: 300px;" 
                @select="handleSelect"
                @clear="clearStudentInfo"
            ></el-autocomplete>
            <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 160px; margin-left: 10px; margin-right: 10px;">
                <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
                    :value="option.name"></el-option>
            </el-select>
            <el-button type="primary" @click="fetchCourses">查询</el-button>
            <el-button type="success" style="margin-left: 10px;" @click="exportSelectedCourses">导出选中课程信息</el-button>
        </el-container>

        <el-divider>
            <h3 style="text-align: center; margin-bottom: 20px;">学生课程列表</h3>
        </el-divider>

        <el-table
            :data="studentCourseList"
            style="width: 100%"
            row-key="stuID"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-table
                        v-if="props.row.courseList && props.row.courseList.length"
                        :data="props.row.courseList"
                        style="width: 100%"
                        border
                    >
                        <el-table-column prop="courseType" label="课程名称">
                            <template slot-scope="scope">
                                <el-button type="text" @click="openDetailDialog(scope.row, props.row.stuID)">
                                    {{ scope.row.courseType }}
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="manager" label="课程负责人姓名"></el-table-column>
                        <el-table-column prop="startTime" label="课程开始时间"></el-table-column>
                        <el-table-column prop="site" label="上课地点"></el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column prop="stuID" label="一卡通号"></el-table-column>
            <el-table-column prop="name" label="学生姓名"></el-table-column>
            <el-table-column prop="selectedCount" label="已选课程数"></el-table-column>
        </el-table>


        <el-dialog :visible.sync="detailDialogVisible" title="课程详细信息" width="500px">
            <el-form :model="currentCourse" label-width="120px">
                <el-form-item label="课程名称">
                    <el-input v-model="currentCourse.courseName" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程类型">
                    <el-input v-model="currentCourse.courseType" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程负责人姓名">
                    <el-input v-model="currentCourse.manager" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="授课容量">
                    <el-input v-model="currentCourse.capacity" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程开始时间">
                    <el-input v-model="currentCourse.startTime" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="课程结束时间">
                    <el-input v-model="currentCourse.endTime" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="报名开始时间">
                    <el-input v-model="currentCourse.signupStart" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="报名结束时间">
                    <el-input v-model="currentCourse.signupEnd" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="上课地点">
                    <el-input v-model="currentCourse.site" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="签到情况">
                    <el-collapse>
                        <el-collapse-item v-for="(item, index) in currentCourse.checkInList" :key="index" :title="'签到轮次 ' + item.round">
                            <el-row>
                                <strong>发布时间:</strong> {{ item.releaseTime }}
                            </el-row>
                            <el-row>
                                <strong>签到时间:</strong> {{ item.checkInTime }}
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-form-item>
                <el-form-item label="心得文字">
                    <el-input type="textarea" autosize v-model="currentCourse.text" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="成果照片">
                    <el-col v-for="(image, index) in currentCourse.photos" :key="index" :span="8">
                        <el-image
                            :src="image"
                            :preview-src-list="currentCourse.photos"
                            style="width: 100%; height: 100px; object-fit: cover; margin-bottom: 10px;"
                        ></el-image>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>


<script>
import {
    Button,
    TableColumn,
    Select,
    Option,
    Table,
    Divider,
    Container,
    Autocomplete,
    Collapse,
    CollapseItem,
    Dialog,
    Form,
    FormItem,
    Row,
    Col,
    Image,
} from "element-ui";
import exportToExcel from '../../plugins/export.js';

export default {
    components: {
        "el-table": Table,
        "el-button": Button,
        "el-table-column": TableColumn,
        "el-select": Select,
        "el-option": Option,
        "el-divider": Divider,
        "el-container": Container,
        "el-autocomplete": Autocomplete,
        "el-dialog": Dialog,
        "el-form": Form,
        "el-form-item": FormItem,
        "el-collapse": Collapse,
        "el-collapse-item": CollapseItem,
        "el-row": Row,
        "el-col": Col,
        "el-image": Image,
    },
    data() {
        return {
            searchQuery: '',
            isLoading: false,
            selectedTerm: '',
            studentInfo: {
                name: '',
                id: ''
            },
            termList: [],
            studentCourseList: [], // 用于存储所有学生的课程信息\
            selectedStudents: [],  // 存储选中的学生信息
            currentCourse: {},
            detailDialogVisible: false,
        };
    },
    methods: {
        async loadTerm() {
            try {
                let termList = await this.$axios.get("/term");
                if (termList.data.success) {
                    this.termList = termList.data.result.list.reverse().slice(0, 2);
                    this.termList.push({ name: '全部学期', startDate: null, endDate: null });
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },

        async fetchCourses() {
            this.isLoading = true;
            const payload = {
                term: this.selectedTerm,
                stuId: this.searchQuery === '' ? null : this.studentInfo.id,
            }
            try {
                const response = await this.$axios.post(`/assistance/course/query-instructor`, payload, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                });
                if (response.data.success) {
                    this.studentCourseList = response.data.result.map(student => ({
                        ...student,
                        courseList: student.courseList.map(course => ({
                            ...course,
                            startTime: this.formatDateString(course.startTime),
                            endTime: this.formatDateString(course.endTime),
                        })),
                    }));
                } else {
                    this.$message.warning("查询失败");
                }
            } catch (error) {
                this.$message.error("查询课程列表时出错");
            }
            this.isLoading = false;
        },

        async fetchStudentSuggestions(queryString, callback) {
            if (!queryString) {
                callback([]);
                return;
            }
            try {
                const response = await this.$axios.get('/assistance/activity/manager/admin/search-stu-info', {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: { nameID: queryString }
                });
                if (response.data.success && Array.isArray(response.data.result)) {
                    const suggestions = response.data.result.map(student => ({
                        value: `${student.name} (${student.id})`,
                        id: student.id
                    }));
                    callback(suggestions);
                } else {
                    callback([]);
                }
            } catch (error) {
                console.error('Error fetching student suggestions:', error);
                callback([]);
            }
        },

        formatDateString(dateString) {
            const date = new Date(dateString);

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },
        
        handleSelect(item) {
            this.studentInfo = {
                name: item.value.split(' (')[0],
                id: item.id,
            };
            this.fetchCourses();
        },

        clearStudentInfo() {
            this.studentInfo = {
                name: '',
                id: ''
            };
            this.fetchCourses();
        },

        handleSelectionChange(val) {
            this.selectedStudents = val;
        },

        getCourseType(courseType) {
            const mandatoryCourses = [
                '资助专员答辩',
                '资助宣传大使答辩',
                '家访优秀团队答辩',
                '资助政策宣讲'
            ];

            const electiveCourses = [
                '德育培训班',
                '智育培训班',
                '体育培训班',
                '美育培训班',
                '劳育培训班'
            ];

            if (mandatoryCourses.includes(courseType)) {
                return '必修';
            } else if (electiveCourses.includes(courseType)) {
                return '选修';
            } else {
                return '其他';
            }
        },

        exportSelectedCourses() {
            if (this.selectedStudents.length === 0) {
                this.$message.warning('请选择至少一个学生');
                return;
            }

            const map = {
                name: '学生姓名',
                stuID: '一卡通号',
                courseType: '课程名称',
                courseName: '课程类型',
                term: '所属学期',
                manager: '课程负责人',
                capacity: '课容量',
                startTime: '课程开始时间',
                endTime: '课程结束时间',
                site: '上课地点',
            };

            const exportData = this.selectedStudents.flatMap(student =>
                student.courseList.map(course => ({
                    name: student.name,
                    stuID: student.stuID,
                    courseType: course.courseType,
                    courseName: this.getCourseType(course.courseType),
                    term: course.term,
                    manager: course.manager,
                    capacity: course.capacity,
                    startTime: course.startTime,
                    endTime: course.endTime,
                    site: course.site,
                }))
            );

            exportToExcel(map, exportData, '学生课程信息');
        },

        openDetailDialog(course, studentId) {
            this.isLoading = true;
            this.$axios.get(`/assistance/course/detail-stu`, {
                params: {
                    courseID: course.id,
                    stuID: studentId
                }
            }).then(response => {
                if (response.data.success) {
                    this.currentCourse = response.data.result;
                    const mandatoryCourses = [
                        '资助专员答辩',
                        '资助宣传大使答辩',
                        '家访优秀团队答辩',
                        '资助政策宣讲'
                    ];

                    const electiveCourses = [
                        '德育培训班',
                        '智育培训班',
                        '体育培训班',
                        '美育培训班',
                        '劳育培训班',
                        '其他'
                    ];

                    if (mandatoryCourses.includes(this.currentCourse.courseName)) {
                        this.currentCourse.courseType = '必修';
                    } else if (electiveCourses.includes(this.currentCourse.courseName)) {
                        this.currentCourse.courseType = '选修';
                    } else {
                        this.currentCourse.courseType = '其他';
                    }
                    this.detailDialogVisible = true;
                } else {
                    this.$message.error('获取课程详细信息失败');
                }
            }).catch(error => {
                console.error('获取课程详细信息出错:', error);
                this.$message.error('获取课程详细信息时出错');
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
    
    async created() {
        this.isLoading = true;
        await this.loadTerm();
        await this.fetchCourses();
        this.isLoading = false;
    },
};
</script>

<style scoped>
::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
  color: black !important;
  /* 设置文本颜色为黑色 */
}
</style>
