<template>
  <div>
    <div class="bg">
      <img
        class="blue-block block-mobile"
        src="../assets/background/small-blue-block.svg"
      />
      <img
        class="blue-block block-pc"
        src="../assets/background/small-blue-block-pc.svg"
      />
      <img
        class="orange-block block-mobile"
        src="../assets/background/small-orange-block.svg"
      />
      <img
        class="orange-block block-pc"
        src="../assets/background/small-orange-block-pc.svg"
      />
    </div>
    <div class="app-content-wrapper">
      <div class="header">
        <img
          v-if="!isHome"
          class="home-buttom"
          src="../assets/home.svg"
          @click="gotoHome"
        />
        <img
          v-if="!isHome"
          class="back-buttom"
          src="../assets/back.svg"
          @click="goBack"
        />
        <div v-else style="margin-left: 10px"></div>
        <img
          class="seu-logo"
          src="../assets/seu-logo.svg"
          v-if="this.$device.isDesktop"
        />
        <div
          class="title"
          :style="this.$device.isDesktop ? 'font-size:18px' : 'font-size:18px'"
        >
          东南学工
        </div>
        <div class="space"></div>
        <div>
          <el-select
            :style="this.$device.isDesktop ? 'width:150px' : 'width:120px'"
            v-model="roleId"
            :size="this.$device.isDesktop ? 'large' : 'mini'"
            class="role-select"
            filterable
            v-if="permissionList.length > 1"
            @change="changeRole"
          >
            <el-option
              :style="$device.isDesktop ? 'width:50vw' : 'width:90vw'"
              v-for="item in permissionList"
              :key="item.identityId"
              :label="roleDisplay(item)"
              :value="item.identityId"
            ></el-option>
          </el-select>
        </div>
        <img
          v-if="!isTommy"
          class="logout-button"
          src="../assets/logout.svg"
          @click="logout"
        />
      </div>
      <div class="app-content">
        <h2 class="app-title">{{ title }}</h2>
        <p v-if="desc" class="app-desc">{{ desc }}</p>
        <div v-else style="margin-top: 20px"></div>
        <div class="panel">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import roleMap from "../static/roleMap.json";
export default {
  components: {
    "el-select": Select,
    "el-option": Option,
  },
  data() {
    return {
      isWechat: true,
    };
  },
  props: {
    title: { type: String, required: true },
    desc: { type: String, default: "" },
    isHome: { type: Boolean, default: false },
  },
  computed: {
    isTommy() {
      return window.navigator.userAgent.includes("tommy");
    },
    permissionList() {
      return this.$store.state.permission;
    },
    roleId: {
      get: function () {
        return this.$store.state.role
          ? this.$store.state.role.identityId
          : "null";
      },
      set: function (val) {
        this.$store.commit(
          "role",
          this.$store.state.permission.find((x) => val == x.identityId)
        );
        this.$axios.defaults.headers[
          "x-api-role"
        ] = this.$store.state.role.identityId;
      },
    },
  },
  methods: {
    roleDisplay(role) {
      return role.role + "-" + role.department;
    },
    changeRole(val) {
      this.roleId = val;
      window.location.reload();
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoHome() {
      this.$router.replace("/");
    },
    async logout() {
        await this.$axios.get("/logout");
        if(this.isWechat){
           this.$store.commit("clearCache");
           window.location = `https://xgbxscwx.seu.edu.cn/cas-we-can/logout?service=https://xgbxscwx.seu.edu.cn`;
        }else{
           this.$store.commit("clearCache");
           window.location = `https://auth.seu.edu.cn/dist/#/dist/logout?redirectUrl=https://xgbxscwx.seu.edu.cn`;
        }
    },
  },
  created() {
    this.isWechat = this.$device.isWechat;
    this.role = this.$store.state.role;
  },
};
</script>

<style lang="less" scoped>
.role-select {
  width: 150px;
  margin-right: 10px;
}
.bg {
  z-index: -100;
  width: 100%;
  top: 0;
  position: fixed;
  @media screen and (min-width: 600px) {
    .block-mobile {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    .block-pc {
      display: none;
    }
  }
  .blue-block {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: -98;
    // animation: blueSlideIn 1s;
  }
  .orange-block {
    width: 60%;
    position: fixed;
    right: 0;
    top: 160px;
    z-index: -99;
    // animation: orangeSlideIn 2s;
  }

  @keyframes blueSlideIn {
    from {
      top: -20px;
    }
    to {
      top: 0;
    }
  }
  @keyframes orangeSlideIn {
    from {
      top: 100px;
    }
    to {
      top: 160px;
    }
  }
}

.header {
  position: absolute;
  top: 5px;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  // animation: slideIn 1s;
  width: 100%;
  max-width: 1200px;
  .home-buttom {
    width: 30px;
    margin-left: 20px;
  }
  .back-buttom {
    width: 30px;
    margin-left: 10px;
  }
  .seu-logo {
    width: 50px;
    margin-left: 7px;
  }
  .title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-left: 7px;
  }
  .space {
    flex-grow: 1;
  }
  .logout-button {
    width: 25px;
    margin-right: 20px;
  }
  @media screen and (min-width: 600px) {
    .logout-button {
      margin-right: 40px;
    }
    .home-buttom {
      margin-left: 40px;
    }
  }
  @keyframes slideIn {
    from {
      margin-top: -5px;
    }
    to {
      margin-top: 5px;
    }
  }
}
@media screen and (min-width: 600px) {
  .header {
    top: 15px;
  }
}
.app-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-content {
  position: absolute;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: 50px;
  // animation: appContentSlideIn 1s;
  .app-title {
    color: #fff;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 30px;
  }
  .app-desc {
    color: #fff;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 16px;
  }
  .panel {
    background: #fff;
    margin: 0 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 20px 15px 17px 15px;
  }
  @media screen and (min-width: 600px) {
    .panel {
      margin: 0 40px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .app-title {
      margin-left: 40px;
      margin-right: 40px;
      margin-top: 30px;
    }
    .app-desc {
      margin-left: 40px;
    }
  }
}
@keyframes appContentSlideIn {
  from {
    opacity: 0;
    top: 30px;
  }
  to {
    opacity: 1;
    top: 50px;
  }
}
</style>