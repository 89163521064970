<template>
    <div v-loading="isLoading">
        <el-form class="custom-popper">
            <el-row :gutter="10" type="flex" class="responsive-layout">
                <el-col :xs="12" :sm="12" :md="24">
                    <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%;">
                        <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
                            :value="option.name"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24">
                    <el-input v-model="searchQuery" placeholder="输入姓名或一卡通号查询" style="width: 100%;"></el-input>
                </el-col>
                <el-col :xs="6" :sm="6" :md="8">
                    <el-button type="primary" @click="fetchFilteredActivities" style="width: 100%;">查询</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 20px;">
                <el-col :span="24">
                    <el-checkbox label="全选" v-model="selectAll" @change="toggleSelectAll"
                        style="margin-right: 10px;">全选</el-checkbox>
                    <el-button type="primary" @click="exportExcel">一键导出Excel</el-button>
                </el-col>
            </el-row>
            <el-table :data="paginatedActivities" style="width: 100%; margin-top: 20px;"
                :default-sort="{ prop: 'beginTime', order: 'ascending' }" @selection-change="handleSelectionChange"
                @filter-change="handleFilterChange">
                <el-table-column prop="stuName" label="姓名"></el-table-column>
                <el-table-column prop="stuId" label="一卡通号"></el-table-column>
                <el-table-column prop="beginTime" label="开始时间" sortable></el-table-column>
                <el-table-column prop="activityName" label="活动名称"></el-table-column>
                <el-table-column prop="club" label="负责社团" :filters="clubTypes" column-key="club"
                    :filter-multiple="false">
                </el-table-column>
                <el-table-column prop="type" label="活动类型" :filters="activityTypes" column-key="type"
                    :filter-multiple="false"></el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-checkbox @change="toggleSelect" v-model="scope.row.selected">导出Excel</el-checkbox>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next, jumper" :current-page.sync="currentPage"
                :page-size="pageSize" :total="totalRecords" @current-change="handlePageChange">
                第{{ currentPage }}页
            </el-pagination>
        </el-form>
    </div>
</template>


<script>
import exportToExcel from '../../plugins/export.js';
import {
    Button,
    Form,
    Table,
    Input,
    TableColumn,
    Row,
    Col,
    Checkbox,
    Pagination,
    Select,
    Option,
} from "element-ui";

export default {
    components: {
        "el-option": Option,
        "el-select": Select,
        "el-input": Input,
        "el-button": Button,
        "el-table": Table,
        "el-table-column": TableColumn,
        "el-row": Row,
        "el-col": Col,
        "el-form": Form,
        "el-checkbox": Checkbox,
        "el-pagination": Pagination
    },
    data() {
        return {
            isLoading: true,
            searchQuery: '',
            activities: [],
            activityTypes: [
                { text: '日常管理', value: '日常管理' },
                { text: '思想引领', value: '思想引领' },
                { text: '成长互助', value: '成长互助' },
                { text: '品牌活动', value: '品牌活动' },
                { text: '资助中心', value: '资助中心' },
            ],
            clubTypes: [
                { text: '唐仲英爱心社', value: '唐仲英爱心社' },
                { text: '伯藜学社', value: '伯藜学社' },
                { text: '瑞华筑梦公益平台', value: '瑞华筑梦公益平台' },
                { text: '曾宪梓春晖公益平台', value: '曾宪梓春晖公益平台' },
                { text: '新地郭氏公益平台', value: '新地郭氏公益平台' },
            ],
            filters: {
                club: null,
                activityType: null
            },
            selectedTerm: '',
            termList: [],
            selectAll: false,
            currentPage: 1,
            totalRecords: 0,
            pageSize: 25,
        };
    },
    async created() {
        this.loadTerm();
        await this.fetchAllActivities();
        this.isLoading = false;
    },
    methods: {
        async loadTerm() {
            try {
                const response = await this.$axios.get("/term");
                if (response.data.success) {
                    this.termList = response.data.result.list.reverse().slice(0, 2);
                    this.termList.push({ endDate: undefined, startDate: undefined, name: "全部学期" });
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                console.error("加载学期列表时出错:", error);
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },
        handleFilterChange(filters) {
            this.filters.club = filters.club ? filters.club[0] : this.filters.club;
            this.filters.activityType = filters.type ? filters.type[0] : this.filters.activityType;
            this.fetchFilteredActivities();
        },
        async fetchAllActivities() {
            try {
                const response = await this.$axios.get(`/assistance/activity/instructor/all`, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        semester: this.selectedTerm,
                    },
                });
                if (response.data.success) {
                    this.activities = response.data.result.map((activity) => ({
                        beginTime: this.formatDate(activity.beginTime),
                        activityName: activity.activityName,
                        club: activity.club,
                        type: activity.type,
                        stuName: activity.stuName,
                        stuId: activity.stuId,
                        selected: false,
                    }));
                    this.totalRecords = this.activities.length;
                } else {
                    this.$message({
                        type: 'warning',
                        message: '查询失败'
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '查询记录时出错'
                });
            } finally {
                this.isLoading = false;
            }
        },
        async fetchFilteredActivities() {
            try {
                console.log(this.selectedTerm);
                console.log(this.activities);
                const response = await this.$axios.get('/assistance/activity/instructor/query-stu', {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        keyWord: this.searchQuery,
                        semester: this.selectedTerm,
                        club: this.filters.club,
                        activityType: this.filters.activityType
                    },
                });
                if (response.data.success) {
                    this.activities = response.data.result.map((activity) => ({
                        beginTime: this.formatDate(activity.beginTime),
                        activityName: activity.activityName,
                        club: activity.club,
                        type: activity.type,
                        stuName: activity.stuName,
                        stuId: activity.stuId,
                        selected: false,
                    }));
                    this.totalRecords = this.activities.length;
                    console.log(this.activities);
                } else {
                    this.$message({
                        type: 'warning',
                        message: '获取列表失败'
                    })
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '获取列表出错'
                })
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        handlePageChange(newPage) {
            this.currentPage = newPage;
        },
        handleSelectionChange(selectedRows) {
            this.activities.forEach(activity => {
                activity.selected = selectedRows.includes(activity);
            });
        },
        toggleSelect(row) {
            row.selected = !row.selected;
            this.selectAll = this.activities.every(activity => activity.selected);
        },
        toggleSelectAll() {
            this.activities.forEach(activity => {
                activity.selected = this.selectAll;
            });
        },
        async fetchStudentActivities() {
            if (!this.searchQuery) {
                this.$message({
                    type: 'warning',
                    message: '请输入查询条件',
                });
                return;
            }

            try {
                const response = await this.$axios.get(`/assistance/activity/instructor/stu`, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        keyWord: this.searchQuery,
                        semester: this.selectedTerm,
                    },
                });
                if (response.data.success) {
                    this.activities = response.data.result.map((activity) => ({
                        beginTime: this.formatDate(activity.beginTime),
                        activityName: activity.activityName,
                        club: activity.club,
                        type: activity.type,
                        stuName: activity.stuName,
                        stuId: activity.stuId,
                        selected: false,
                    }));
                    this.totalRecords = this.activities.length;
                } else {
                    this.$message({
                        type: 'error',
                        message: '查询失败',
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '查询活动时出错',
                });
            }
        },
        async exportExcel() {
            const selectedActivities = this.activities.filter(activity => activity.selected);
            if (selectedActivities.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择要导出的记录'
                });
                return;
            }
            const map = {
                activityName: '活动名称',
                beginTime: '开始时间',
                club: '负责社团',
                type: '活动类型',
                stuName: '学生姓名',
                stuId: '学生一卡通号'
            };

            const data = selectedActivities.map(item => ({
                activityName: item.activityName,
                beginTime: item.beginTime,
                club: item.club,
                type: item.type,
                stuName: item.stuName,
                stuId: item.stuId
            }));

            exportToExcel(map, data, '育人活动记录');

            this.$message({
                type: 'success',
                message: '导出成功'
            });
        }
    },
    computed: {
        paginatedActivities() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = this.currentPage * this.pageSize;
            return this.activities.slice(start, end);
        }
    },
};
</script>

<style lang="less" scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-row {
    margin-bottom: 5px;
    /* 调整行间距 */
}

.el-col {
    padding-right: 10px;
    /* 调整右侧间距 */
}

.custom-popper {
    margin-left: 15px;
    /* 确保学期选择框左边距 */
}
</style>
