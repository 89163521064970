import Vue from 'vue';
import VueRouter from 'vue-router';
import Assitance from '../views/Assitance.vue';
import DetailedStudentInfomation from '../components/studentAssistance/DetailedStudentInfomation.vue';
import TalkWithStudent from '../components/studentAssistance/TalkWithStudent.vue';
import ViewActivityTutor from '../components/educationActivity/ViewActivity-tutor.vue';
import ViewRecord from '../components/studentAssistance/ViewRecord.vue';
import CheckIn from '../components/educationalCourse/CheckIn.vue';
Vue.use(VueRouter)

const routes = [

  // {
  //   path: '/',
  //   name: 'welcome',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  // },
  // 主页
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName*/'../views/Home.vue')
  },
  {
    path: '/assistance',
    name: 'Assistance',
    component: Assitance
  },
  {
    path: '/talk-with-student',
    name: 'TalkWithStudent',
    component: TalkWithStudent
  },
  {
    path: '/detailed-student-information',
    name: 'DetailedStudentInfomation',
    component: DetailedStudentInfomation
  },
  {
    path: '/view-record',
    name: 'ViewRecord',
    component: ViewRecord
  },
  {
    path: '/view-activity-tutor',
    name: 'ViewActivityTutor',
    component: ViewActivityTutor
  },
  {
    path: '/check-in',
    name: 'CheckIn',
    component: CheckIn
  },
  // 通用提示页
  {
    path: '/mobile-result',
    name: 'mobile-result',
    component: () => import(/* webpackChunkName*/'../views/mobile/ResultPage.vue')
  },
  {
    path: '/admin-list',
    name: 'admin-list',
    component: () => import(/* webpackChunkName*/'../views/AdminList.vue')
  },
  {
    path: '/role-list',
    name: 'role-list',
    component: () => import(/* webpackChunkName*/'../views/RoleList.vue')
  },
  {
    path: '/ncp-basic-info',
    name: 'ncp-basic-info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName*/'../views/NCP/NCPBasicInfo.vue'),
  },

  {
    path: '/extended-info',
    name: 'extended-info',
    component: () => import(/* webpackChunkName: "about" */ '../views/StudentExtendedInfo.vue')
  },
  {
    path: '/mobile/extended-info',
    name: 'mobile-extended-info',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/StudentExtendedInfo.vue')
  },
  // {
  //   path:'/mobile/ncp-basic-info',
  //   name:'mobile-ncp-basic-info',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/mobile/NCPBasicInfo.vue')
  // },
  // {
  //   path:'/mobile/ncp-daily-report',
  //   name:'mobile-ncp-daily-report',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/mobile/NCPDailyReport.vue')
  // },
  // {
  //   path:'/ncp-daily-report',
  //   name:'ncp-daily-report',
  //   component: () => import('../views/NCPDailyReport.vue')
  // },
  {
    path: '/class-Management',
    name: 'class-Management',
    component: () => import(/* webpackChunkName: "about"*/'../views/ClassManagement.vue')
  },
  // {
  //   path:'/ncp-daily-export',
  //   name:'ncp-daily-export',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPDailyExport.vue')
  // },
  // {
  //   path:'/ncp-class-daily-view/:id/:className',
  //   name:'ncp-class-daily-view',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPClassDailyView.vue')
  // },
  // {
  //   path:'/ncp-basic-export',
  //   name:'ncp-basic-export',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPBasicExport.vue')
  // },
  // {
  //   path:'/ncp-class-basic-view/:id/:className',
  //   name:'ncp-class-basic-view',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPClassBasicView.vue')
  // },
  // {
  //   path:'/ncp-Unsubmitted',
  //   name:'ncp-Unsubmitted',
  //   component: () => import('../views/NCPUnsubmitted.vue')
  // },
  // {
  //   path:'/ncp-Unreviewed',
  //   name:'ncp-Unreviewed',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPUnreviewed.vue')
  // },
  // {
  //   path:'/ncp-Unreviewed-View',
  //   name:'ncp-Unreviewed-View',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPUnreviewedView.vue')
  // },
  // {
  //   path:'/ncp-college-Unsubmitted',
  //   name:'ncp-college-Unsubmitted',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPCollegeUnsubmitted.vue')
  // },
  {
    path: '/user-info-view',
    name: 'user-info-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/UserInfoView.vue')
  },
  {
    path: '/user-info-class-view/:id/:className',
    name: 'user-info-class-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/UserInfoClassView.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/user-info-student-view/:cardnum',
    name: 'user-info-student-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/UserInfoStudentView.vue')
  },
  // {
  //   path:'/ncp-history-view/:cardnum',
  //   name:'ncp-history-view',
  //   component: () => import(/* webpackChunkName: "about"*/'../views/NCPHistoryView.vue')
  // },
  {
    path: '/gobal-search',
    name: 'gobal-search',
    component: () => import(/* webpackChunkName: "about"*/'../views/GobalSearch.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/notification-publish',
    name: 'notification-publish',
    component: () => import(/* webpackChunkName: "about"*/'../views/NotificationPublish.vue')
  },
  {
    path: '/notification-view',
    name: 'notification-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/NotificationView.vue')
  },
  {
    path: '/notification-detail/:id',
    name: 'notification-detail',
    component: () => import(/* webpackChunkName: "about"*/'../views/NotificationDetail.vue')
  },
  {
    path: '/department-list',
    name: 'department-list',
    component: () => import(/* webpackChunkName: "about"*/'../views/DepartmentList.vue')
  },
  {
    path: '/identity-list',
    name: '/identity-list',
    component: () => import(/* webpackChunkName: "about"*/'../views/IdentityList.vue')
  },
  {
    path: '/personal-list-view',
    name: '/personal-list-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/PersonalListView.vue')
  },
  {
    path: '/counselor/:counselorId',
    name: 'counselor',
    component: () => import(/* webpackChunkName: "about"*/'../views/Counselor.vue')
  },
  {
    path: '/counselor-check',
    name: 'counselor-check',
    component: () => import(/* webpackChunkName: "about"*/'../views/CounselorCheck.vue')
  },
  {
    path: '/notification-college-view',
    name: 'notification-college-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/NotificationCollegeView.vue')
  },
  {
    path: '/ncp-scan-qrcode',
    name: 'ncp-scan-qrcode',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCPSchoolQrcode/ScanQrcode.vue')
  },
  {
    path: '/ncp-display-qrcode',
    name: 'ncp-display-qrcode',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCPSchoolQrcode/DisplayQrcode.vue')
  },
  {
    path: '/ncp-back-review',
    name: 'ncp-back-review',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCPSchoolQrcode/BackToSchoolReview.vue')
  },
  {
    path: '/ncp-back-export',
    name: 'ncp-back-export',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCPSchoolQrcode/Exports.vue')
  },
  {
    path: '/ncp-back-statistics',
    name: 'ncp-back-statistics',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCPSchoolQrcode/Statistics.vue')
  },
  
  //{
  //  path: '/ncp-back-master-export',
  //  name: 'ncp-back-master-export',
  //  component: () => import(/* webpackChunkName: "about"*/'../views/NCPSchoolQrcode/MasterExports.vue')
  //},
  
  {
    path: '/ncp-back-arrive-view',
    name: 'ncp-back-arrive-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCPSchoolQrcode/ArrivalView.vue')
  },
  {
    path: '/ncp-temperature',
    name: 'ncp-temperature',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCP/NCPTemperature.vue')
  },
  {
    path: '/ncp-temperature-view',
    name: 'ncp-temperature-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCP/NCPTemperatureView.vue')
  },
  {
    path: '/ncp-cohabitant',
    name: 'ncp-cohabitant',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCP/NCPCohabitant.vue')
  },
  {
    path: '/ncp-cohabitant-view',
    name: 'ncp-cohabitant-view',
    component: () => import(/* webpackChunkName: "about"*/'../views/NCP/NCPCohabitantView.vue')
  },
  {
    path: '/yxl-vote',
    name: 'yxl-vote',
    component: () => import(/* webpackChunkName*/'../views/yxl/Vote.vue')
  },
  {
    path: '/vote-publish',
    name: 'vote-publish',
    component: () => import(/* webpackChunkName*/'../views/Vote/VotePublish.vue')
  },
  {
    path: '/vote-design/:id',
    name: 'vote-design',
    component: () => import(/* webpackChunkName*/'../views/Vote/VoteDesign.vue')
  },
  {
    path: '/vote-list',
    name: 'vote-list',
    component: () => import(/* webpackChunkName*/'../views/Vote/VoteList.vue')
  },
  {
    path: '/vote-participate/:id',
    name: 'vote-participate',
    component: () => import(/* webpackChunkName*/'../views/Vote/VoteParticipate.vue')
  },
  {
    path: '/vote-statistics/:id',
    name: 'vote-statistics',
    component: () => import(/* webpackChunkName*/'../views/Vote/VoteStatistics.vue')
  },
  {
    path: '/vote-record/:id',
    name: 'vote-record',
    component: () => import(/* webpackChunkName*/'../views/Vote/VoteRecord.vue')
  },
  {
    path: '/counselor/check/college',
    name: 'counselor/check/college',
    component: () => import(/* webpackChunkName*/'../views/CounselorCheck/College.vue')
  },
  {
    path: '/counselor/check/department',
    name: 'counselor/check/department',
    component: () => import(/* webpackChunkName*/'../views/CounselorCheck/Department.vue')
  },
  {
    path: '/counselor/check/student',
    name: 'counselor/check/student',
    component: () => import(/* webpackChunkName*/'../views/CounselorCheck/Student.vue')
  },
  {
    path: '/counselor/check/view',
    name: 'counselor/check/view',
    component: () => import(/* webpackChunkName*/'../views/CounselorCheck/CheckView.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/counselor/check/detail/:id/:year',
    name: 'counselor/check/detail',
    component: () => import(/* webpackChunkName*/'../views/CounselorCheck/CheckDetail.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/counselor/check/detail/student/:id/:year',
    name: 'counselor/check/detail/student',
    component: () => import(/* webpackChunkName*/'../views/CounselorCheck/CheckDetailStudent.vue')
  },
  {
    path: '/handbook',
    name: 'handbook',
    component: () => import(/* webpackChunkName*/'../views/handbook.vue')
  },
  {
    path: '/drill/notification/publish',
    name: 'drill/notification/publish',
    component: () => import(/* webpackChunkName*/'../views/Drill/NotificationPublish.vue')
  },
  {
    path: '/drill/platoon_manager',
    name: 'drill/platoon_manager',
    component: () => import(/* webpackChunkName*/'../views/Drill/PlatoonManager.vue')
  },
  {
    path: '/drill/leave/apply',
    name: 'drill/leave/apply',
    component: () => import(/* webpackChunkName*/'../views/Drill/LeaveApply.vue')
  },
  {
    path: '/drill/leave/review',
    name: 'drill/leave/review',
    component: () => import(/* webpackChunkName*/'../views/Drill/LeaveReview.vue')
  },
  {
    path: '/drill/rollcall',
    name: 'drill/rollcall',
    component: () => import(/* webpackChunkName*/'../views/Drill/RollCall.vue')
  },
  {
    path: '/drill/rollcall/view',
    name: 'drill/rollcall/view',
    component: () => import(/* webpackChunkName*/'../views/Drill/RollCallView.vue')
  },
  {
    path: '/drill/contact/student',
    name: 'drill/contact/student',
    component: () => import(/* webpackChunkName*/'../views/Drill/StudentContactView.vue')
  },
  {
    path: '/drill/contact',
    name: 'drill/contact',
    component: () => import(/* webpackChunkName*/'../views/Drill/ContactView.vue')
  },
  {
    path: '/mobile/counselor',
    name: 'mobile-counselor',
    component: () => import(/* webpackChunkName*/'../views/mobile/Counselor.vue')
  },
  {
    path: '/mobile/counselor/:counselorId',
    name: 'mobile-counselor-detail',
    component: () => import(/* webpackChunkName*/'../views/mobile/Counselor.vue')
  },
  {
    path: '/meetingroom-book',
    name: 'meetingroom-book',
    component: () => import(/* webpackChunkName*/'../views/MeetingRoomBook.vue')
  },
  {
    path: '/meetingroom-appo/:roomId',
    name: 'meetingroom-appo',
    component: () => import(/* webpackChunkName*/'../views/MeetingRoomAppo.vue'),
  },
  {
    path: '/meetingroom-preview/:roomId',
    name: 'meetingroom-preview',
    component: () => import(/* webpackChunkName*/'../views/MeetingRoomPreview.vue'),
  },
  {
    path: '/counselor/check/figure-recognition/',
    name: 'figure-recognition',
    component: () => import(/* webpackChunkName*/'../views/CounselorCheck/FigureRecognition.vue'),
  },
  {
    path: '/night-clockin/records',
    name: "night-clockin-records",
    component: () => import('../views/ClockInRecords/RecordsPage.vue')
  },
  {
    path: '/night-clockin/statistic',
    name: "night-clockin-statistic",
    component: () => import('../views/ClockInRecords/RecordStatistic.vue')
  },
  {
    path: '/night-clockin',
    name: 'night-clockin',
    component: () => import(/* webpackChunkName*/'../views/Clockin.vue'),
  },
  {
    path: '/counselor/check/progress',
    name: 'counselor-check-progress',
    component: () => import('../views/CounselorCheck/CheckProgress.vue'),
  },
  {
    path: '/winter-cloth/admin',
    name: 'winter-cloth-admin',
    component: () => import("../views/WinterCloth/Admin/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/winter-cloth/admin/view-student/:studentId',
    name: 'winter-cloth-admin',
    component: () => import("../views/WinterCloth/Admin/StudentDetail.vue"),
  },
  {
    path: '/winter-cloth/student',
    name: 'winter-cloth-student',
    component: () => import("../views/WinterCloth/Student/index.vue"),
  },
  {
    path: '/winter-cloth/student/view-selection',
    name: 'winter-cloth-admin',
    component: () => import("../views/WinterCloth/Student/StudentDetail.vue"),
  },
  {
    path: '/homeVisiting/volunteer',
    name: 'home-visit-volunteer',
    component: () => import("../views/HomeVisiting/volunteer/home.vue")
  },
  {
    path: '/homeVisiting/:auth/form/:editType/:cardnum',
    name: 'home-visit-form',
    component: () => import("../views/HomeVisiting/volunteer/form.vue")
  },
  {
    path: '/homeVisiting/:auth/form-mobile/:editType/:cardnum',
    name: 'home-visit-form',
    component: () => import("../views/HomeVisiting/volunteer/form-mobile.vue")
  },
  {
    path: '/homeVisiting/:auth/form/:editType',
    name: 'home-visit-new',
    component: () => import("../views/HomeVisiting/volunteer/form.vue")
  },
  {
    path: '/homeVisiting/admin/',
    name: 'home-visit-admin',
    component: () => import("../views/HomeVisiting/admin/home.vue")
  },
  {
    path: '/homeVisiting/tutor/',
    name: 'home-visit-tutor',
    component: () => import("../views/HomeVisiting/tutor/home.vue")
  },
  {
    path: '/homeVisiting/counselor/',
    name: 'home-visit-counselor',
    component: () => import("../views/HomeVisiting/counselor/home.vue")
  }, {
    path: '/counselor-training-management',
    name: 'counselor-training-management',
    component: () => import('../components/CounselorTrainingManagement.vue'),
  }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   if (from.fullPath === "/") {
//     let ax = axios.create()
//     ax.defaults.headers["x-api-token"] = router.app.$options.store.state.token;
//     let res = await axios.get("/admin");
//     if (!res.data.success) {
//       router.app.$options.store.commit("permission", []);
//       return;
//     }
//     if (res.data.result.length == 0) {
//       router.app.$options.store.commit("permission", []);
//       return;
//     }
//     router.app.$options.store.commit("permission", res.data.result);
//     if (
//       !router.app.$options.store.state.role ||
//       router.app.$options.store.state.permission.findIndex(x => {
//         return x.id == router.app.$options.store.state.role.id;
//       }) == -1
//     ) {
//       router.app.$options.store.commit("role", router.app.$options.store.state.permission[0]);
//     }
//   }

//   next();
// })
// router.beforeRouteEnter((to, from, next)=> {
//   next(vm => {
//     if (from.fullPath === "/") {
//       console.log(vm.$axios)
//     }
//   })
// })

export default router
