<template>
    <div v-loading="isLoading">
        <el-form class="custom-popper">
            <el-row :gutter="10" type="flex">
                <el-col :xs="12" :sm="12" :md="24">
                    <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%;">
                        <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
                            :value="option.name"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24">
                    <el-select v-model="mandatory" placeholder="选择课程类型查询" style="width: 100%;">
                        <el-option v-for="(option, index) in ['选修', '必修']" :key="index" :label="option"
                            :value="option"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="6" :sm="6" :md="8">
                    <el-button type="primary" @click="fetchCourses" style="width: 100%;">查询</el-button>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24">
                    <h3 class="centered-title">全部课程列表</h3>
                    <el-row :gutter="20" style="margin-top: 20px;">
                        <el-col :span="16">
                            <el-button type="primary" @click="exportExcel" style="margin-top: 8px;"
                                v-if="!isStudent()">一键导出Excel</el-button>
                            <el-checkbox v-model="selectAll" @change="toggleSelectAll"
                                v-if="!isStudent()">全选</el-checkbox>
                        </el-col>
                    </el-row>
                    <el-table :data="paginatedCourseList" border stripe>
                        <el-table-column prop="courseName" label="课程名称">
                            <template slot-scope="scope">
                                <el-button type="text" @click="openDetailDialog(scope.row)">
                                    {{ scope.row.courseName }}
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="startTime" label="课程开始时间"></el-table-column>
                        <el-table-column prop="courseType" label="课程类型">
                            <template slot-scope="scope">
                                {{ scope.row.courseType }}({{ getMandatory(scope.row.courseType) }})
                            </template>

                        </el-table-column>
                        <el-table-column label="授课容量" align="center">
                            <template slot-scope="scope">
                                <span
                                    :style="{ color: scope.row.selectedCount >= scope.row.capacity || scope.row.capacity === null ? 'red' : 'green' }">
                                    {{ scope.row.selectedCount }}/{{ scope.row.capacity }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="manager" label="课程负责人"></el-table-column>
                        <el-table-column label="选课" width="200" align="center" v-if="isStudent()">
                            <template slot-scope="scope">
                                <el-button v-if="!scope.row.signedUp && scope.row.withinTime" type="primary"
                                    @click="signupForCourse(scope.row)" :key="3">
                                    {{ judgecanSignUp(scope.row) }}
                                </el-button>
                                <el-button v-else-if="scope.row.signedUp && scope.row.withinTime" type="danger"
                                    @click="signoutFromCourse(scope.row)" :key="2">
                                    退课
                                </el-button>
                                <el-button v-else type="default" :disabled="true" :key="1">
                                    {{ judgecanSignUp(scope.row) }}
                                </el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" width="180" align="center" v-if="!isStudent()">
                            <template slot-scope="scope">
                                <el-checkbox @change="toggleSelect" v-model="scope.row.selected">导出</el-checkbox>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>

            <el-pagination background layout="total, prev, pager, next, jumper" :current-page.sync="currentPage"
                :page-size="pageSize" :total="totalCourses" @current-change="showDifferentPage">
            </el-pagination>
        </el-form>

        <!-- 课程详情弹窗 -->
        <el-dialog :visible.sync="detailDialogVisible" title="课程详细信息" width="600px">
            <el-form :model="currentCourse" label-width="120px">
                <el-form-item label="课程类型">
                    <el-select v-model="currentCourse.courseType" :disabled="isStudent() || !isEditing"
                        placeholder="请选择课程类型">
                        <el-option v-for="(option, index) in courseTypeList" :key="index" :label="option"
                            :value="option"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="课程名称">
                    <p style="font-weight: bold;">{{ currentCourse.courseName }} ({{
                        getMandatory(currentCourse.courseType) }})</p>
                </el-form-item>
                <el-form-item label="课程负责人姓名">
                    <el-autocomplete v-model="currentCourse.manager" :fetch-suggestions="fetchChargePersonSuggestions"
                        placeholder="请输入课程负责人姓名" :disabled="isStudent() || !isEditing"
                        @select="handleSelectChargePerson">
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="面向对象">
                    <p>{{ this.targetStudentString }}</p>
                    <el-checkbox-group v-model="targetArray" :disabled="isStudent() || !isEditing">
                        <el-checkbox label="社团骨干"></el-checkbox>
                        <el-checkbox label="至善宣讲团"></el-checkbox>
                        <el-checkbox label="资助专员"></el-checkbox>
                        <el-checkbox label="全体贫困学生"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="授课容量">
                    <el-input v-model="currentCourse.capacity" :disabled="isStudent() || !isEditing"
                        style="width: 100px;"></el-input>
                </el-form-item>
                <el-form-item label="课程开始时间">
                    <el-datetime-picker v-model="currentCourse.startTime" type="datetime"
                        value-format="yyyy-MM-dd HH:mm" :disabled="isStudent() || !isEditing" placeholder="选择课程开始时间"
                        format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>
                <el-form-item label="课程结束时间">
                    <el-datetime-picker v-model="currentCourse.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm"
                        :disabled="isStudent() || !isEditing" placeholder="选择课程结束时间" format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>

                <el-form-item label="报名开始时间">
                    <el-datetime-picker v-model="currentCourse.signupStart" type="datetime"
                        value-format="yyyy-MM-dd HH:mm" :disabled="isStudent() || !isEditing" placeholder="选择报名开始时间"
                        format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>
                <el-form-item label="报名结束时间">
                    <el-datetime-picker v-model="currentCourse.signupEnd" type="datetime"
                        value-format="yyyy-MM-dd HH:mm" :disabled="isStudent() || !isEditing" placeholder="选择报名结束时间"
                        format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>
                <el-form-item label="上课地点" style="width: 300px;">
                    <el-input v-model="currentCourse.site" :disabled="isStudent() || !isEditing"></el-input>
                </el-form-item>
                <el-form-item label="报名情况" v-if="!isStudent()">
                    <el-button type="primary" @click="openParticipantDialog">查看名单</el-button>
                    <el-button type="primary" @click="exportParticipantList">导出报名名单</el-button>
                </el-form-item>
                <el-form-item label="签到情况" v-if="!isStudent()">
                    <el-button type="primary" @click="openSignInDialog">查看名单</el-button>
                    <el-button type="primary" @click="exportSignInList">导出签到名单</el-button>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="detailDialogVisible = false">关闭</el-button>
                <el-button v-if="!isStudent() && !isEditing" type="primary" @click="isEditing = true">修改</el-button>
                <el-button v-if="!isStudent() && isEditing" type="primary" @click="saveChanges">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="signInDialogVisible" title="签到名单" width="600px">
            <el-table :data="formattedCheckInList" border stripe>
                <el-table-column prop="studentName" label="学生姓名"></el-table-column>
                <el-table-column v-for="round in checkInRounds" :key="round" :prop="'round_' + round"
                    :label="'签到轮次 ' + round">
                    <template slot-scope="scope">
                        <span :style="{ color: scope.row['round_' + round] === '未签到' ? 'red' : 'black' }">
                            {{ scope.row['round_' + round] }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="已签到轮数/总轮数">
                    <template slot-scope="scope">
                        <span>
                            {{ Object.values(scope.row).filter(val => val === '已签到').length }}/{{ checkInRounds.length
                            }}
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="signInDialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="participantDialogVisible" title="报名名单" width="600px">
            <el-table :data="participantList" border stripe>
                <el-table-column prop="stuId" label="一卡通号"></el-table-column>
                <el-table-column prop="stuName" label="姓名"></el-table-column>
                <el-table-column prop="identity" label="身份"></el-table-column>
                <el-table-column prop="club" label="社团"></el-table-column>
                <el-table-column prop="position" label="社团内职位"></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="participantDialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    Button,
    Form,
    TableColumn,
    Select,
    Option,
    Col,
    Row,
    Table,
    Dialog,
    FormItem,
    Pagination,
    Autocomplete,
    DatePicker,
    Checkbox,
    CheckboxGroup,
} from "element-ui";
import exportToExcel from '../../plugins/export.js';
export default {
    components: {
        "el-table": Table,
        "el-button": Button,
        "el-form": Form,
        "el-table-column": TableColumn,
        "el-select": Select,
        "el-option": Option,
        "el-col": Col,
        "el-row": Row,
        "el-dialog": Dialog,
        "el-form-item": FormItem,
        "el-pagination": Pagination,
        "el-autocomplete": Autocomplete,
        "el-datetime-picker": DatePicker,
        "el-checkbox": Checkbox,
        "el-checkbox-group": CheckboxGroup,
    },
    data() {
        return {
            isLoading: true,
            selectedTerm: "",
            selectedType: "",
            termList: [],
            courseTypeList: [
                "思辨力",
                "学习力",
                "创造力",
                "领导力",
                "德育",
                "智育",
                "体育",
                "美育",
                "劳育",
            ],
            currentPage: 1,
            pageSize: 10,
            totalCourses: 0,
            detailDialogVisible: false,
            signInDialogVisible: false,
            participantDialogVisible: false,
            studentType: ['社团骨干', '至善宣讲团', '资助专员', '全体贫困学生'],
            targetArray: [],
            managerName: null,
            managerId: null,
            isEditing: false,
            mandatory: '',
            courseList: [],
            currentCourse: {},
            checkInList: [],
            participantList: [],
            selectAll: false,
            role: null,
            targetStudent: null,
        };
    },
    methods: {
        async getRole() {
            try {
                const response = await this.$axios.get("/assistance/role", {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    }
                });
                if (response.data.success) {
                    this.role = response.data.result;
                    console.log(this.role);
                } else {
                    this.$message({
                        type: "warning",
                        message: "权限不存在",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "查询权限信息时出错",
                });
            }
        },
        isStudent() {
            return this.role === '学生';
        },
        async loadTerm() {
            try {
                let termList = await this.$axios.get("/term");
                if (termList.data.success) {
                    this.termList = termList.data.result.list.reverse().slice(0, 2);
                    this.termList.push({ name: '全部学期', startDate: null, endDate: null });
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },
        async fetchChargePersonSuggestions(searchInCharge, callback) {
            if (!searchInCharge) {
                callback([]);
                return;
            }
            try {
                const response = await this.$axios.get(`/assistance/course/search-tea-info`, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        nameID: searchInCharge
                    }
                })
                if (response.data.success) {
                    this.suggestions = response.data.result.map(charger => ({
                        value: `${charger.name} (${charger.id})`,
                        id: charger.id
                    }
                    ));
                    callback(this.suggestions);
                } else {
                    callback([]);
                }
            } catch (error) {
                callback([]);
            }
        },
        async signupForCourse(course) {
            try {
                const response = await this.$axios.post('/assistance/course/signup', {
                    courseId: course.id
                }, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    }
                });

                if (response.data.success) {
                    this.$message({
                        type: 'success',
                        message: `报名成功：${course.courseName}`
                    });
                    course.selectedCount += 1;
                    course.signedUp = true;

                } else {
                    this.$message({
                        type: 'error',
                        message: `报名失败：${response.data.result}`
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '报名过程中出现错误'
                });
            }
        },
        async signoutFromCourse(course) {
            if (!course.withinTime) {
                this.$message({
                    type: 'warning',
                    message: '不在选课时间内'
                });
                return;
            }
            try {
                const response = await this.$axios.delete('/assistance/course/signout', {
                    params: { courseId: course.id },
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    }
                });

                if (response.data.success) {
                    this.$message({
                        type: 'success',
                        message: `退课成功：${course.courseName}`
                    });

                    course.selectedCount -= 1;
                    course.signedUp = false;

                } else {
                    this.$message({
                        type: 'error',
                        message: `退课失败：${response.data.result}`
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '退课过程中出现错误'
                });
            }
        },
        judgecanSignUp(course) {
            if (!course.withinTime) {
                return '不在报名时间内';
            } else if (course.signedUp) {
                return '已报名';
            } else {
                return '报名';
            }
        },
        exportParticipantList() {
            const map = {
                stuId: '一卡通号',
                stuName: '姓名',
                identity: '身份',
                club: '社团',
                position: '社团内职位',
            };

            const data = this.participantList.map(participant => ({
                stuId: participant.stuId,
                stuName: participant.stuName,
                identity: participant.identity,
                club: participant.club,
                position: participant.position,
            }));

            exportToExcel(map, data, `${this.currentCourse.courseName}-${this.currentCourse.manager}-报名名单导出`);

            this.$message({
                type: 'success',
                message: '报名名单导出成功'
            });
        },
        exportSignInList() {
            const map = {
                studentName: '学生姓名',
            };

            // 动态生成签到轮次列
            this.checkInRounds.forEach(round => {
                map['round_' + round] = '签到轮次' + round;
            });

            map['signedRounds'] = '已签到轮数/总轮数';

            const data = this.formattedCheckInList.map(student => {
                const row = {
                    studentName: student.studentName,
                };

                // 填充每个签到轮次的数据
                this.checkInRounds.forEach(round => {
                    row['round_' + round] = student['round_' + round] || '未签到';
                });

                // 计算并添加已签到轮数/总轮数
                const signedCount = Object.values(student).filter(val => val === '已签到').length;
                row['signedRounds'] = `${signedCount}/${this.checkInRounds.length}`;

                return row;
            });

            // 导出为Excel
            exportToExcel(map, data, `${this.currentCourse.courseName}-签到名单导出`);

            this.$message({
                type: 'success',
                message: '签到名单导出成功'
            });
        },

        handleSelectChargePerson(item) {
            this.managerId = item.id;
            this.managerName = item.value.split(' (')[0];
            this.currentCourse.manager = this.managerName;
        },
        showDifferentPage(page) {
            this.currentPage = page;
        },
        toggleSelectAll() {
            this.courseList.forEach(record => {
                record.selected = this.selectAll;
            });
        },
        toggleSelect() {
            for (let i = 0; i < this.courseList.length; i++) {
                if (this.courseList[i].selected === false) {
                    this.selectAll = false;
                    return;
                }
            }
            this.selectAll = true;
        },
        judgeMandatory() {
            if (this.mandatory === null) {
                return null;
            }
            if (this.selectedType.includes('育')) {
                this.mandatory = '选修'
            } else {
                this.mandatory = '必修'
            }

        },
        async fetchCourses() {
            const params = {
                isMine: 1,
            };
            if (this.selectedTerm !== "") {
                params.term = this.selectedTerm;
            }
            if (this.mandatory !== '') {
                params.isMandatory = this.mandatory == '选修' ? 1 : 2;
            }
            try {
                const response = await this.$axios.post(`/assistance/course/query`, params, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                });
                if (response.data.success) {
                    this.courseList = response.data.result.map(course => ({
                        id: course.id,
                        courseName: course.courseName,
                        courseType: course.courseType,
                        manager: course.manager,
                        term: course.term,
                        capacity: course.capacity,
                        selectedCount: course.selectedCount,
                        startTime: this.formatDate(course.startTime),
                        endTime: this.formatDate(course.endTime),
                        signupStart: this.formatDate(course.signupStart),
                        signupEnd: this.formatDate(course.signupEnd),
                        site: course.site,
                        signedUp: course.signedUp === 1 ? true : false,
                        withinTime: course.withinTime === 1 ? true : false,
                        managerId: course.managerId,
                        selected: false,
                        targetStudent: course.targetStudent,
                    }));
                    this.totalCourses = this.courseList.length;
                } else {
                    this.$message.warning("查询失败");
                }
            } catch (error) {
                this.$message.error("查询课程列表时出错");
            }
        },

        async getParticipantAndCheckList() {
            try {
                const response = await this.$axios.get('/assistance/course/detail', {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        id: this.currentCourse.id,
                    }
                });
                if (response.data.success) {
                    this.checkInList = response.data.result.checkInList;
                    this.checkInList.forEach(item => { item.releaseTime = this.formatDate(item.releaseTime) });
                    this.participantList = response.data.result.participantList;
                } else {
                    this.$message({
                        type: "warning",
                        message: "查询名单失败"
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "查询名单时出错"
                });
            }

        },
        async exportExcel() {
            const selectedCourses = this.courseList.filter(course => course.selected);

            if (selectedCourses.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择要导出的记录'
                });
                return;
            }

            const map = {
                courseName: '课程名称',
                courseType: '课程类型',
                manager: '课程负责人',
                managerId: '负责人一卡通号',
                term: '学期',
                capacity: '课程容量',
                selectedCount: '已选人数',
                startTime: '课程开始时间',
                endTime: '课程结束时间',
                signupStart: '报名开始时间',
                signupEnd: '报名结束时间',
                site: '上课地点',
                targetStudent: '面向对象',
            };

            const data = selectedCourses.map(course => ({
                courseName: course.courseName,
                courseType: course.courseType,
                manager: course.manager,
                term: course.term,
                capacity: course.capacity,
                selectedCount: course.selectedCount,
                startTime: course.startTime,
                endTime: course.endTime,
                signupStart: course.signupStart,
                signupEnd: course.signupEnd,
                site: course.site,
                canSignUp: course.selectedCount < course.capacity ? '是' : '否',
                managerId: course.managerId,
                targetStudent: course.targetStudent,
            }));

            exportToExcel(map, data, '课程记录导出');

            this.$message({
                type: 'success',
                message: '导出成功'
            });
        },
        getMandatory(courseType) {
            if (courseType == undefined || courseType == null) return;
            if (courseType.includes('育')) {
                return '选修';
            } else {
                return '必修';
            }

        },

        async openDetailDialog(course) {
            this.currentCourse = { ...course };
            await this.getParticipantAndCheckList();
            this.isEditing = false;
            this.detailDialogVisible = true;
            this.targetArray = this.currentCourse.targetStudent.split(',').map(item => item.trim());
        },
        async openParticipantDialog() {
            this.participantDialogVisible = true;
        },
        openSignInDialog() {
            this.signInDialogVisible = true;
        },
        async saveChanges() {
            const payload = {
                id: this.currentCourse.id,
                courseType: this.currentCourse.courseType,
                courseName: this.currentCourse.courseName,
                manager: this.currentCourse.managerId,
                term: this.currentCourse.term,
                capacity: this.currentCourse.capacity,
                startTime: this.currentCourse.startTime,
                endTime: this.currentCourse.endTime,
                signupStart: this.currentCourse.signupStart,
                signupEnd: this.currentCourse.signupEnd,
                site: this.currentCourse.site,
                targetStudent: this.targetArray,
            };
            this.$axios.post(`/assistance/course/update`, payload, {
                headers: {
                    "x-api-token": this.$axios.defaults.headers["x-api-token"],
                },
            })
                .then(response => {
                    if (response.data.success) {
                        this.$message.success('修改已保存');
                        this.isEditing = false;
                        this.detailDialogVisible = false;
                        this.fetchCourses();
                    } else {
                        this.$message.error('保存失败，请重试');
                    }
                })
                .catch(() => {
                    this.$message.error('保存失败，请重试');
                });
        },
    },
    async created() {
        this.isLoading = true;
        await this.loadTerm();
        await this.getRole();
        await this.fetchCourses();
        this.isLoading = false;
    },
    computed: {

        targetStudentString() {
            return this.targetArray.join(',');
        },
        paginatedCourseList() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = this.currentPage * this.pageSize;
            return this.courseList.slice(start, end);
        },
        checkInRounds() {
            if (this.role === "学生") return;
            return this.checkInList.map(item => item.checkInRound);
        },
        formattedCheckInList() {
            const students = {};
            if (this.role === "学生") return;
            this.checkInList.forEach(checkIn => {
                checkIn.presentStuList.forEach(student => {
                    if (!students[student.stuId]) {
                        students[student.stuId] = {
                            studentName: student.stuName,
                            stuId: student.stuId
                        };
                    }
                    students[student.stuId]['round_' + checkIn.checkInRound] = '已签到';
                });

                checkIn.absentStuList.forEach(student => {
                    if (!students[student.stuId]) {
                        students[student.stuId] = {
                            studentName: student.stuName,
                            stuId: student.stuId
                        };
                    }
                    students[student.stuId]['round_' + checkIn.checkInRound] = '未签到';
                });
            });

            return Object.values(students);
        },
    },
};
</script>

<style scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-row {
    margin-bottom: 5px;
}

.el-col {
    padding-right: 10px;
}

.custom-popper {
    margin-left: 15px;
}

.centered-title {
    text-align: center;
    margin-bottom: 20px;
}

.dialog-footer {
    text-align: right;
}

::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
    color: black !important;
    /* 设置文本颜色为黑色 */
}

@media screen and (max-width: 768px) {
    .el-row {
        flex-direction: column;
    }
}
</style>
