<template>
  <div v-loading="isLoading">
    <el-divider>
      <h3 style="text-align: center; margin-bottom: 20px;">审核活动</h3>
    </el-divider>

    <el-divider>
      <h4 style="text-align: center; margin-bottom: 20px;">待审核活动列表</h4>
    </el-divider>
    <el-table :data="activities" style="width: 100%;" @filter-change="handleFilterChange">
      <el-table-column prop="startTime" label="开始时间">
        <template slot="header" scope="scope">
          <span>{{ scope.column.label }}</span>
          <el-button size="mini" :icon="order ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            @click="toggleSort()"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="活动名称"></el-table-column>
      <el-table-column prop="club" label="负责社团" :filters="clubs" :filter-multiple="false" column-key="club">
      </el-table-column>
      <el-table-column prop="type" label="活动类型" :filters="activityTypes" :filter-multiple="false" column-key="type">
      </el-table-column>
      <el-table-column prop="state" label="完成情况" :filters="statusFilters" :filter-multiple="false"  column-key="state">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="viewDetails(scope.row)" size="small" style="margin-left: -10px;">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
      <el-pagination @current-change="handlePageChange" :current-page="currentPage" :page-size="pageSize"
        :total="totalActivities" layout="total, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <el-dialog :visible.sync="detailDialogVisible" title="活动详情" width="60%">
      <div v-if="currentActivity" class="details-grid">
        <el-form :model="currentActivity" label-position="left" label-width="120px">
          <el-form-item label="活动名称：">
            <el-input v-model="currentActivity.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="开始时间：">
            <el-input v-model="currentActivity.startTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="结束时间：">
            <el-input v-model="currentActivity.endTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="负责社团：">
            <el-input v-model="currentActivity.club" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动类型：">
            <el-input v-model="currentActivity.type" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动内容：">
            <el-input v-model="currentActivity.content" type="textarea" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动时长（h）：">
            <el-input v-model="currentActivity.duration" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动所属学期：">
            <el-input v-model="currentActivity.semester" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="负责人：">
            <el-input v-model="currentActivity.managerList" type="textarea" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="参与人：">
            <el-input v-model="currentActivity.participantList" type="textarea" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动面向对象：">
            <el-input v-model="currentActivity.activityTargetDescription" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="报名开始时间：">
            <el-input v-model="currentActivity.activitySignupStartTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="报名结束时间：">
            <el-input v-model="currentActivity.activitySignupEndTime" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="passActivity" type="primary">通过</el-button>
          <el-button @click="rejectActivity" type="primary">驳回</el-button>
          <el-button @click="detailDialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>


<script>
import {
  Button,
  Input,
  Table,
  TableColumn,
  Divider,
  Dialog,
  Pagination,
  Form,
  FormItem,
} from "element-ui"

export default {
  components: {
    "el-button": Button,
    "el-input": Input,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-divider": Divider,
    "el-dialog": Dialog,
    "el-pagination": Pagination,
    "el-form": Form,
    "el-form-item": FormItem,
  },
  data() {
    return {
      isLoading: true,
      activities: [], // 当前页显示的活动数据
      allActivities: [], // 所有活动数据
      currentActivity: {},
      detailDialogVisible: false,
      currentPage: 1,
      pageSize: 25,
      totalActivities: 0,
      order: false,
      currentId: 0,
      filters: {
        club: null,
        activityType: null,
        finishStatus: null,
      },
      clubs: [
        { text: '唐仲英爱心社', value: '唐仲英爱心社' },
        { text: '伯藜学社', value: '伯藜学社' },
        { text: '瑞华筑梦公益平台', value: '瑞华筑梦公益平台' },
        { text: '曾宪梓春晖公益平台', value: '曾宪梓春晖公益平台' },
        { text: '新地郭氏公益平台', value: '新地郭氏公益平台' },
      ],
      activityTypes: [
        { text: '日常管理', value: '日常管理' },
        { text: '思想引领', value: '思想引领' },
        { text: '成长互助', value: '成长互助' },
        { text: '品牌活动', value: '品牌活动' },
        { text: '资助中心', value: '资助中心' },
      ],
      statusFilters: [
        { text: '未开始', value: '未开始' },
        { text: '进行中', value: '进行中' },
        { text: '已结束', value: '已结束' }
      ],
    };
  },

  async created() {
    await this.fetchActivities(this.currentPage);
    // 全部加载结束后
    this.isLoading = false;
  },

  methods: {
    toggleSort() {
      this.order = !this.order; // 切换布尔值，true为升序，false为降序
      this.sortActivities();
    },

    async fetchActivities() {
      this.isLoading = true;
      const params = {
        club: this.filters.club,
        activityType: this.filters.activityType,
        finishStatus: this.filters.finishStatus,
      };
      try {
        const response = await this.$axios.get('/assistance/activity/manager/unchecked-query', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params
        });
        if (response.data.success) {
          // 获取并存储所有活动数据
          this.allActivities = response.data.result.map(activity => ({
            ...activity,
            startTime: this.formatDateString(activity.startTime),
          }));

          // 根据 order 进行排序
          this.sortActivities();

          // 初始化分页数据
          this.totalActivities = this.allActivities.length;
          this.paginateActivities();
        } else {
          this.$message.error('加载活动列表失败');
        }
      } catch (error) {
        console.error('Failed to fetch activities:', error);
        this.$message.error('加载活动列表失败');
      } finally {
        this.isLoading = false;
      }
    },

    sortActivities() {
      this.allActivities.sort((a, b) => {
        const dateA = new Date(a.startTime);
        const dateB = new Date(b.startTime);
        return this.order ? dateA - dateB : dateB - dateA;
      });
      this.paginateActivities();
    },

    paginateActivities() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.activities = this.allActivities.slice(start, end);
    },

    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.paginateActivities();
    },

    viewDetails(activity) {
      this.$axios.get(`/assistance/activity/manager/detail`, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        },
        params: { activityId: activity.id }
      })
        .then(response => {
          if (response.data.success) {
            this.currentActivity = response.data.result;
            this.currentActivity.activityTargetDescription = this.getActivityTargetDescription(this.currentActivity.activityTargetList);
            this.currentActivity.startTime = this.formatDateString(this.currentActivity.startTime);
            this.currentActivity.endTime = this.formatDateString(this.currentActivity.endTime);
            this.currentActivity.activitySignupStartTime = this.formatDateString(this.currentActivity.activitySignupStartTime);
            this.currentActivity.activitySignupEndTime = this.formatDateString(this.currentActivity.activitySignupEndTime);
            this.detailDialogVisible = true;
            this.currentId = activity.id;
          } else {
            this.$message.error('获取活动详情失败');
          }
        })
        .catch(error => {
          console.error('获取活动详情时发生错误:', error);
          this.$message.error('获取活动详情时发生错误');
        });
    },

    getActivityTargetDescription(targetList) {
      const targetMapping = {
        '0': '全体学生',
        '1': '唐仲英爱心社',
        '2': '伯藜学社',
        '3': '瑞华筑梦公益平台',
        '4': '曾宪梓春晖公益平台',
        '5': '新地郭氏公益平台',
        '6': '家庭经济困难学生'
      };
      return targetList.split(',').map(id => targetMapping[id]).join(', ');
    },

    passActivity() {
      this.$axios.put('/assistance/activity/manager/admin/check', {
        activityId: this.currentId,
        approvalStatus: true
      }, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        }
      })
        .then(response => {
          if (response.data.success) {
            this.$message.success('活动审核通过');
            this.detailDialogVisible = false;
            this.currentPage = 1;
            this.fetchActivities(this.currentPage);
          } else {
            this.$message.error('活动审核失败');
          }
        })
        .catch(error => {
          console.error('活动审核通过时发生错误:', error);
          this.$message.error('活动审核通过时发生错误');
        });
    },

    rejectActivity() {
      this.$axios.put('/assistance/activity/manager/admin/check', {
        activityId: this.currentId,
        approvalStatus: false
      }, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        }
      })
        .then(response => {
          if (response.data.success) {
            this.$message.success('活动已驳回');
            this.detailDialogVisible = false;
            this.currentPage = 1;
            this.fetchActivities(this.currentPage);
          } else {
            this.$message.error('活动驳回失败');
          }
        })
        .catch(error => {
          console.error('活动驳回时发生错误:', error);
          this.$message.error('活动驳回时发生错误');
        });
    },

    formatDateString(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    handleFilterChange(filters) {
      this.filters.club = filters.club ? filters.club[0] : this.filters.club;
      this.filters.activityType = filters.type ? filters.type[0] : this.filters.activityType;
      this.filters.finishStatus = filters.state ? filters.state[0] : this.filters.finishStatus;
      this.currentPage = 1;
      this.fetchActivities();
    },
  }
};
</script>


<style scoped>
.el-button {
  margin-right: 5px;
  margin-left: 20px;
}

.el-divider {
  margin-top: 30px;
  /* 调整顶部间距 */
}

::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
  color: black !important;
  /* 设置文本颜色为黑色 */
}
</style>
