<template>
    <div v-loading="isLoading">
        <el-row :gutter="24">
            <el-col :span="24">
                <el-form class="custom-popper">

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="所属学期" required>
                                <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%">
                                    <el-option v-for="(term, index) in termList" :key="index" :label="term.name"
                                        :value="term.name"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程类型" required>
                                <el-select v-model="courseType" placeholder="请选择课程类型" style="width: 100%">
                                    <el-option v-for="(type, index) in courseTypeOptions" :key="index" :value="type"
                                        :label="type"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程名称" required>
                                <el-input v-model="courseName" placeholder="请输入课程名称" style="width: 100%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="面向对象">
                                <p>{{ this.targetStudentString }}</p>
                                <el-checkbox-group v-model="targetArray">
                                    <el-checkbox label="社团骨干"></el-checkbox>
                                    <el-checkbox label="至善宣讲团"></el-checkbox>
                                    <el-checkbox label="资助专员"></el-checkbox>
                                    <el-checkbox label="全体贫困学生"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程容量">
                                <el-input v-model="capacity" placeholder="请输入课程容量" style="width: 100%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="上课地点">
                                <el-input v-model="site" placeholder="请输入上课地点" style="width: 100%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="负责人" required>
                                <el-container style="width: 100%; align-items: center;">
                                    <el-autocomplete v-model="inChargeSearch"
                                        :fetch-suggestions="fetchChargePersonSuggestions" placeholder="请输入负责人姓名或一卡通号"
                                        style="width: 300px;" @select="handleSelectChargePerson">
                                    </el-autocomplete>
                                </el-container>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程开始时间">
                                <el-datetime-picker v-model="startTime" type="datetime" placeholder="请选择课程开始时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程结束时间">
                                <el-datetime-picker v-model="endTime" type="datetime" placeholder="请选择课程结束时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="报名开始时间">
                                <el-datetime-picker v-model="signupStart" type="datetime" placeholder="请选择报名开始时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="报名结束时间">
                                <el-datetime-picker v-model="signupEnd" type="datetime" placeholder="请选择报名结束时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" @click="submit" style="margin-top: 8px">提交</el-button>
                            <el-button type="danger" @click="resetForm" style="margin-top: 8px">重置</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {
    Button,
    Form,
    FormItem,
    Input,
    Select,
    Option,
    DatePicker,
    Col,
    Row,
    Container,
    Autocomplete,
    Checkbox,
    CheckboxGroup,
} from "element-ui";

export default {
    components: {
        "el-button": Button,
        "el-form": Form,
        "el-form-item": FormItem,
        "el-input": Input,
        "el-select": Select,
        "el-option": Option,
        "el-col": Col,
        "el-row": Row,
        "el-datetime-picker": DatePicker,
        "el-container": Container,
        "el-autocomplete": Autocomplete,
        "el-checkbox": Checkbox,
        "el-checkbox-group": CheckboxGroup,
    },
    data() {
        return {
            courseType: null,
            selectedTerm: null,
            courseName: "",
            capacity: null,
            inChargeSearch: "",
            suggestions: null,
            managerId: null,
            managerName: null,
            site: null,
            startTime: null,
            endTime: null,
            signupStart: null,
            signupEnd: null,
            termList: [],
            targetArray: [],
            isLoading: true,
            courseTypeOptions: [
                "思辨力",
                "学习力",
                "创造力",
                "领导力",
                "德育",
                "智育",
                "体育",
                "美育",
                "劳育",
            ],
            targetStudent: ['社团骨干', '至善宣讲团', '资助专员', '全体贫困学生'],
            selectedTargetStudent: null,
        };
    },

    methods: {
        async submit() {
            if (!this.validateForm()) {
                return;
            }
            try {
                const params = {
                    courseType: this.courseType,
                    courseName: this.courseName,
                    manager: this.managerId,
                    term: this.selectedTerm,
                    capacity: this.capacity,
                    startTime: this.startTime,
                    endTime: this.endTime,
                    signupStart: this.signupStart,
                    signupEnd: this.signupEnd,
                    site: this.site,
                    targetStudent: this.targetArray,
                };
                const response = await this.$axios.post(`/assistance/course/create`, params, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                });
                if (response.data.success) {
                    this.resetForm();
                    this.$message({
                        type: "success",
                        message: "表单提交成功",
                    });
                } else {
                    this.resetForm();
                    this.$message({
                        type: "warning",
                        message: response.data.reason,
                    });
                }
            } catch (error) {
                this.resetForm();
                this.$message({
                    type: "error",
                    message: "表单提交出错",
                });
            }
        },

        async loadTerm() {
            try {
                let termList = await this.$axios.get("/term");
                if (termList.data.success) {
                    this.termList = termList.data.result.list.reverse().slice(0, 2);
                    this.selectedTerm = termList.data.result.currentTerm.name;
                    this.currentTerm = termList.data.result.currentTerm.name;
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },
        async fetchChargePersonSuggestions(searchInCharge, callback) {
            if (!searchInCharge) {
                callback([]);
                return;
            }
            try {
                const response = await this.$axios.get(`/assistance/course/search-tea-info`, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        nameID: searchInCharge
                    }
                });
                if (response.data.success) {
                    this.suggestions = response.data.result.map(charger => ({
                        value: `${charger.name} (${charger.id})`,
                        id: charger.id
                    }));
                    callback(this.suggestions);
                } else {
                    callback([]);
                }
            } catch (error) {
                callback([]);
            }
        },
        handleSelectChargePerson(item) {
            this.managerId = item.id;
            this.managerName = item.value.split(' (')[0];
        },
        validateForm() {
            if (!this.courseType || !this.courseName) {
                this.$message.warning("请填写课程名称或类型");
                return false;
            }
            if (!this.selectedTerm) {
                this.$message.warning("请填写学期");
                return false;
            }
            if (!this.inChargeSearch) {
                this.$message.warning("请填写负责人信息");
                return false;
            }
            if (!this.targetArray) {
                this.$message.warning("请填写面向对象");
                return false;
            }
            if (this.capacity && isNaN(this.capacity)) {
                this.$message.warning("课程容量必须为数字");
                return false;
            }
            return true;
        },

        resetForm() {
            this.courseType = null;
            this.courseName = "";
            this.selectedTerm = null;
            this.capacity = null;
            this.inChargeSearch = "";
            this.startTime = null;
            this.endTime = null;
            this.signupStart = null;
            this.signupEnd = null;
            this.site = null;
            this.targetArray = [];
        },
    },
    async created() {
        this.loadTerm();
        this.isLoading = false;
    },
    computed: {
        targetStudentString() {
            return this.targetArray.join(',');
        },
    }
};
</script>

<style lang="less" scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-row {
    margin-bottom: 5px;
    /* 调整行间距 */
}

.el-col {
    padding-right: 10px;
    /* 调整右侧间距 */
}

.custom-popper {
    margin-left: 30px;
    /* 确保表单左边距 */
}
</style>
