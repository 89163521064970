<template>
  <div v-loading="isLoading">
    <el-divider>
      <h3 style="text-align: center; margin-bottom: 20px;">查看活动信息</h3>
    </el-divider>
    <el-container style="justify-content: center; align-items: center;  margin-bottom: 40px;">
      <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 160px; margin-left: 10px;">
        <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
          :value="option.name"></el-option>
      </el-select>
      <el-select v-model="selectedMonth" placeholder="请选择月份" style="width: 160px; margin-left: 10px;">
        <el-option v-for="month in monthList" :key="month.value" :label="month.label" :value="month.value"></el-option>
      </el-select>
      <el-button type="primary" @click="fetchActivities(currentPage)">查询</el-button>
    </el-container>
    <el-divider>
      <h4 style="text-align: center; margin-bottom: 20px;">育人活动列表</h4>
    </el-divider>
    <el-table ref="multipleTable" :data="activities" style="width: 100%;" @selection-change="handleSelectionChange"
      @filter-change="handleFilterChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="startTime" label="开始时间">
        <template slot="header" scope="scope">
          <span>{{ scope.column.label }}</span>
          <el-button size="mini" :icon="order ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            @click="toggleSort()"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="活动名称"></el-table-column>
      <el-table-column prop="club" label="负责社团" :filters="clubs" column-key="club"
        :filter-multiple="false"></el-table-column>
      <el-table-column prop="type" label="活动类型" :filters="activityTypes" column-key="type"
        :filter-multiple="false"></el-table-column>
      <el-table-column prop="checkStatus" label="审核状态" :filters="checkFilters" column-key="check"
        :filter-multiple="false">
        <template slot-scope="scope">
          <span :style="getCheckStatusStyle(scope.row.checkStatus)">{{ getCheckStatusLabel(scope.row.checkStatus)
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="完成情况" :filters="statusFilters" column-key="state"
        :filter-multiple="false"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="viewDetails(scope.row)" size="small" style="margin-left: -5px;">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
      <el-button type="primary" @click="exportSelectedActivities" style="margin-right: 20px;">导出选中活动</el-button>
      <el-pagination @current-change="handlePageChange" :current-page="currentPage" :page-size="pageSize"
        :total="totalActivities" layout="total, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <el-dialog :visible.sync="detailDialogVisible" title="活动详情" width="60%">
      <div v-if="currentActivity" class="details-grid">
        <el-form :model="currentActivity" label-position="left" label-width="120px">
          <el-form-item label="活动名称：">
            <el-input v-model="currentActivity.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="开始时间：">
            <el-input v-model="currentActivity.startTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="结束时间：">
            <el-input v-model="currentActivity.endTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="负责社团：">
            <el-input v-model="currentActivity.club" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动类型：">
            <el-input v-model="currentActivity.type" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动内容：">
            <el-input v-model="currentActivity.content" type="textarea" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动时长（h）：">
            <el-input v-model="currentActivity.duration" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动所属学期：">
            <el-input v-model="currentActivity.semester" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="负责人：">
            <el-collapse v-model="collapseStatus">
              <el-collapse-item title="查看负责人">
                <div v-for="(manager, index) in currentActivity.managerList" :key="index" class="selected-item">
                  {{ manager.name }} ({{ manager.id }})
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
          <el-form-item label="参与人：">
            <el-collapse v-model="collapseStatus">
              <el-collapse-item title="查看参与人">
                <div v-for="(participant, index) in currentActivity.participantList" :key="index" class="selected-item">
                  {{ participant.stuName }} ({{ participant.stuId }}) {{ participant.club }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
          <el-form-item label="活动面向对象：">
            <el-input v-model="currentActivity.activityTargetDescription" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="报名开始时间：">
            <el-input v-model="currentActivity.activitySignupStartTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="报名结束时间：">
            <el-input v-model="currentActivity.activitySignupEndTime" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="openEditBasicInfo" v-if="currentActivity.canModify" type="primary">修改活动基本信息</el-button>
          <el-button @click="openEditParticipants" v-if="currentActivity.canModify" type="primary">修改活动参与人</el-button>
          <el-button type="primary" @click="exportParticipant">导出参与人名单</el-button>
          <el-button @click="deleteActivity" v-if="currentActivity.canModify" type="danger">删除活动</el-button>
          <el-button @click="detailDialogVisible = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="editBasicDialogVisible" title="编辑活动基本信息" width="60%">
      <el-form :model="currentActivity" label-position="left" label-width="120px" ref="activityForm">
        <el-form-item label="活动名称：">
          <el-input v-model="currentActivity.name"></el-input>
        </el-form-item>
        <el-form-item label="开始时间：">
          <el-date-picker v-model="currentActivity.startTime" type="datetime" placeholder="选择开始时间"
            value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：">
          <el-date-picker v-model="currentActivity.endTime" type="datetime" placeholder="选择结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="负责社团：">
          <el-select v-model="currentActivity.club" placeholder="请选择负责社团">
            <el-option label="唐仲英爱心社" value="唐仲英爱心社"></el-option>
            <el-option label="伯藜学社" value="伯藜学社"></el-option>
            <el-option label="瑞华筑梦公益平台" value="瑞华筑梦公益平台"></el-option>
            <el-option label="曾宪梓春晖公益平台" value="曾宪梓春晖公益平台"></el-option>
            <el-option label="新地郭氏公益平台" value="新地郭氏公益平台"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动类型：">
          <el-select v-model="currentActivity.type" placeholder="请选择活动类型">
            <el-option label="日常管理" value="日常管理"></el-option>
            <el-option label="思想引领" value="思想引领"></el-option>
            <el-option label="成长互助" value="成长互助"></el-option>
            <el-option label="品牌活动" value="品牌活动"></el-option>
            <el-option label="资助中心" value="资助中心"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动内容：" :autosize="{ minRows: 2, maxRows: 4 }" prop="content" class="no-required"
          :rules="[{ required: true, message: '请输入活动内容', trigger: 'blur' }, { min: 50, max: 300, message: '活动内容必须在50到300字之间', trigger: 'blur' }]">
          <el-input type="textarea" maxlength="300" show-word-limit v-model="currentActivity.content"></el-input>
        </el-form-item>
        <el-form-item label="活动时长（h）：">
          <el-input v-model="currentActivity.duration"></el-input>
        </el-form-item>
        <el-form-item label="活动所属学期：">
          <el-select v-model="currentActivity.semester" placeholder="请选择学期">
            <el-option v-for="(term, index) in termList" :key="index" :label="term.name" :value="term.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动负责人" prop="participantList">
          <el-collapse v-model="collapseStatus">
            <el-collapse-item title="查看负责人">
              <el-autocomplete v-model="managerQuery" :fetch-suggestions="fetchManagerSuggestions"
                placeholder="请输入负责人姓名或一卡通号" @select="handleManagerSelect" style="width: 60%;"></el-autocomplete>
              <div v-for="(manager, index) in currentActivity.managerList" :key="index" class="selected-item">
                {{ manager.name }} ({{ manager.id }})
                <el-button @click="removeManager(index)" type="text" size="mini">移除</el-button>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <el-form-item label="活动面向对象：">
          <el-checkbox-group v-model="currentActivity.activityTargetList">
            <el-checkbox v-for="option in options" :key="option.value" :label="option.value"
              :disabled="isOptionDisabled(option.value)">
              {{ option.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="报名开始时间：">
          <el-date-picker v-model="currentActivity.activitySignupStartTime" type="datetime" placeholder="选择报名开始时间"
            value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="报名结束时间：">
          <el-date-picker v-model="currentActivity.activitySignupEndTime" type="datetime" placeholder="选择报名结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="saveBasicInfo">{{ role === '管理员' ? '保存修改' : '提交申请' }}</el-button>
        <el-button @click="editBasicDialogVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="uploadDialogVisible" title="上传选项">
      <span>您希望如何处理上传的活动参与人名单？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelUpload">取消</el-button>
        <el-button type="primary" @click="replaceParticipants">覆盖</el-button>
        <el-button type="primary" @click="addParticipants">添加</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editParticipantsDialogVisible" title="编辑活动参与人" width="60%">
      <el-form ref="participantsForm">
        <el-form-item label="活动参与人" prop="participantList">
          <el-collapse v-model="collapseStatus">
            <el-collapse-item title="查看参与人">
              <el-autocomplete v-model="participantQuery" :fetch-suggestions="fetchParticipantSuggestions"
                placeholder="请输入参与人姓名或一卡通号" @select="handleParticipantSelect" style="width: 60%;"></el-autocomplete>
              <div v-for="(participant, index) in currentActivity.participantList" :key="index" class="selected-item">
                {{ participant.name }} ({{ participant.id }})
                <el-button @click="removeParticipant(index)" type="text" size="mini">移除</el-button>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div class="upload-buttons">
            <el-upload class="upload-demo" ref="upload" action="" :http-request="handleFileUpload"
              :before-upload="beforeUpload" :on-change="handleChange" :show-file-list="false" accept=".xls,.xlsx">
              <el-button type="primary">点击上传活动参与人名单</el-button>
            </el-upload>
            <el-button @click="download">点击下载模板</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="saveParticipants">保存修改</el-button>
        <el-button @click="editParticipantsDialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import exportToExcel from '../../plugins/export.js';
import {
  Button,
  Input,
  Table,
  TableColumn,
  Divider,
  Container,
  Autocomplete,
  Select,
  Dialog,
  Pagination,
  Form,
  FormItem,
  Option,
  Upload,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  Collapse,
  CollapseItem,
} from "element-ui"

export default {
  components: {
    "el-button": Button,
    "el-input": Input,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-divider": Divider,
    "el-container": Container,
    "el-autocomplete": Autocomplete,
    "el-select": Select,
    "el-dialog": Dialog,
    "el-pagination": Pagination,
    "el-form": Form,
    "el-form-item": FormItem,
    "el-option": Option,
    "el-upload": Upload,
    "el-checkbox-group": CheckboxGroup,
    "el-checkbox": Checkbox,
    "el-date-picker": DatePicker,
    "el-collapse": Collapse,
    "el-collapse-item": CollapseItem,
  },
  data() {
    return {
      isLoading: true,
      selectedTerm: '',
      selectedMonth: 0,  // 默认选择全部月份
      monthList: [
        { value: 0, label: '全部月份' },
        { value: 1, label: '1月' },
        { value: 2, label: '2月' },
        { value: 3, label: '3月' },
        { value: 4, label: '4月' },
        { value: 5, label: '5月' },
        { value: 6, label: '6月' },
        { value: 7, label: '7月' },
        { value: 8, label: '8月' },
        { value: 9, label: '9月' },
        { value: 10, label: '10月' },
        { value: 11, label: '11月' },
        { value: 12, label: '12月' }
      ],
      activities: [], // 当前页显示的活动数据
      allActivities: [], // 所有活动数据
      currentActivity: {},
      detailDialogVisible: false,
      filters: {
        club: null,
        activityType: null,
        finishStatus: null,
        checkStatus: null,
      },
      selectedActivities: [],
      clubs: [
        { text: '唐仲英爱心社', value: '唐仲英爱心社' },
        { text: '伯藜学社', value: '伯藜学社' },
        { text: '瑞华筑梦公益平台', value: '瑞华筑梦公益平台' },
        { text: '曾宪梓春晖公益平台', value: '曾宪梓春晖公益平台' },
        { text: '新地郭氏公益平台', value: '新地郭氏公益平台' },
      ],
      activityTypes: [
        { text: '日常管理', value: '日常管理' },
        { text: '思想引领', value: '思想引领' },
        { text: '成长互助', value: '成长互助' },
        { text: '品牌活动', value: '品牌活动' },
        { text: '资助中心', value: '资助中心' },
      ],
      statusFilters: [
        { text: '未开始', value: '未开始' },
        { text: '进行中', value: '进行中' },
        { text: '已结束', value: '已结束' }
      ],
      checkFilters: [
        { text: '已通过', value: '已通过' },
        { text: '审核中', value: '审核中' },
        { text: '驳回', value: '驳回' }
      ],
      currentPage: 1,
      currentId: 0,
      pageSize: 25,
      totalActivities: 0,
      order: false,  // 默认升序
      managerQuery: '',
      participantQuery: '',
      termList: [],
      isSelectionChangeEnabled: true,
      uploadParticipants: [],  // 用于保存从文件上传的参与人
      uploadDialogVisible: false,  // 控制上传对话框的显示
      editParticipantsDialogVisible: false,
      editBasicDialogVisible: false,
      buttonName: '',
      role: '',
      testResult1: [],
      testResult2: [],
    };
  },

  async created() {
    await this.loadTerm();
    await this.getRole();
    await this.fetchActivities();
  },

  computed: {
    isOptionDisabled() {
      return (value) => {
        // 确保 currentActivity.activityTargetList 已定义且是一个数组
        if (!Array.isArray(this.currentActivity.activityTargetList)) {
          return false;
        }

        // 如果选择了“全体学生”，禁用其他选项
        if (this.currentActivity.activityTargetList.includes(0) && value !== 0) {
          return true;
        }
        // 如果选择了其他选项，禁用“全体学生”
        if (this.currentActivity.activityTargetList.length > 0 && value === 0 && !this.currentActivity.activityTargetList.includes(0)) {
          return true;
        }

        return false;
      };
    }
  },

  methods: {
    async loadTerm() {
      try {
        let termList = await this.$axios.get("/term");
        if (termList.data.success) {
          this.termList = termList.data.result.list.filter(term => term.startDate >= 1724025600000).reverse();
          this.termList.unshift({ name: "全部学期" });  // 添加 "全部学期" 选项
          this.selectedTerm = "全部学期";  // 设置默认值为 "全部学期"
        } else {
          this.$message({
            type: "warning",
            message: "学期列表加载失败",
          });
        }
      } catch (error) {
        console.error("加载学期列表时出错:", error);
        this.$message({
          type: "error",
          message: "加载学期列表时出错",
        });
      }
    },

    download() {
      const data = [
        { id: '123456789', name: '小东' },
        { id: '987654321', name: '小南' }
      ]; // 示例数据

      const map = {
        id: '一卡通号',
        name: '姓名'
      }; // 字段映射表，用于Excel表头

      exportToExcel(map, data, "参与人名单");
    },

    async getRole() {
      try {
        const response = await this.$axios.get("/assistance/role", {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          }
        });
        if (response.data.success) {
          this.role = response.data.result;
        } else {
          this.$message({
            type: "warning",
            message: "权限不存在",
          });
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "查询权限信息时出错",
        });
      }
    },

    toggleSort() {
      this.order = !this.order; // 切换布尔值，true为升序，false为降序
      this.sortActivities();
    },

    async fetchActivities() {
      this.isLoading = true;
      this.isSelectionChangeEnabled = false; // 禁用 handleSelectionChange

      const headers = {
        "x-api-token": this.$axios.defaults.headers["x-api-token"],
      };
      const params = {
        club: this.filters.club,
        activityType: this.filters.activityType,
        finishStatus: this.filters.finishStatus,
        checkStatus: this.filters.checkStatus,
        semester: this.selectedTerm,
      };

      try {
        const response = await this.$axios.get('/assistance/activity/manager/all-unpaged', { headers, params });
        const activitiesFromResponse = response.data.result;

        // 更新活动的选中状态
        activitiesFromResponse.forEach(activity => {
          const found = this.selectedActivities.some(item => item.id === activity.id);
          this.$set(activity, 'selected', found);
        });

        this.allActivities = activitiesFromResponse.map(activity => ({
          ...activity,
          startTime: this.formatDateString(activity.startTime),
        }));
        
        if (this.selectedMonth > 0) {
          this.allActivities = this.allActivities.filter(activity => {
            const activityMonth = new Date(activity.startTime).getMonth() + 1; // getMonth() 返回 0-11
            return activityMonth === this.selectedMonth;
          });
        }
        this.sortActivities();

        this.totalActivities = this.allActivities.length;  // 更新总页数

        this.paginateActivities();

        //使用 $nextTick 确保 DOM 更新后执行
        this.$nextTick(() => {
          this.activities.forEach(activity => {
            const found = this.selectedActivities.some(item => item.id === activity.id);
            this.$refs.multipleTable.toggleRowSelection(activity, found);
          });
          this.isSelectionChangeEnabled = true; // 启用 handleSelectionChange
        });

        this.isLoading = false;
      } catch (error) {
        console.error('Failed to fetch activities:', error);
        this.$message.error('加载活动列表失败');
        this.isLoading = false;
      }
    },

    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.paginateActivities();
      this.isSelectionChangeEnabled = false;
      // 在分页后恢复已选择的项目
      this.$nextTick(() => {
        this.activities.forEach(activity => {
          const found = this.selectedActivities.some(item => item.id === activity.id);
          this.$refs.multipleTable.toggleRowSelection(activity, found);
        });
        this.isSelectionChangeEnabled = true;
      });
    },

    sortActivities() {
      this.allActivities.sort((a, b) => {
        const dateA = new Date(a.startTime);
        const dateB = new Date(b.startTime);
        return this.order ? dateA - dateB : dateB - dateA;
      });
      this.paginateActivities();
    },

    paginateActivities() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.activities = this.allActivities.slice(start, end);
    },

    handleFileUpload({ file }) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          // eslint-disable-next-line no-undef
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          // eslint-disable-next-line no-undef
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const participantArray = json.slice(1).map(row => ({
            id: row[0],
            name: row[1]
          }));
          this.uploadParticipants = participantArray;
          this.uploadDialogVisible = true;  // 显示选择对话框
        } catch (error) {
          console.error('Error parsing file:', error);
        }
      };
      reader.readAsArrayBuffer(file);
    },

    // 完全覆盖现有的参与人列表
    replaceParticipants() {
      this.currentActivity.participantList = this.uploadParticipants;
      this.uploadDialogVisible = false;
    },

    // 将新上传的参与人添加到现有列表中
    addParticipants() {
      const existingIds = new Set(this.currentActivity.participantList.map(p => p.id));
      const newParticipants = this.uploadParticipants.filter(p => !existingIds.has(p.id));
      this.currentActivity.participantList.push(...newParticipants);
      this.uploadDialogVisible = false;
    },

    // 取消上传操作
    cancelUpload() {
      this.uploadDialogVisible = false;
    },

    // 文件上传之前的处理
    beforeUpload(file) {
      const isXLS = file.type === 'application/vnd.ms-excel';
      const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isValid = isXLS || isXLSX;
      if (!isValid) {
        this.$message.error('只能上传 .xls 或 .xlsx 文件');
      }
      return isValid;
    },

    // 文件选择后的处理
    // eslint-disable-next-line no-unused-vars
    handleChange(file, fileList) {
      console.log('选择的文件:', file);
    },

    async viewDetails(activity) {
      try {
        const response = await this.$axios.get(`/assistance/activity/manager/detail`, {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { activityId: activity.id },
        });
        if (response.data.success) {
          this.currentActivity = response.data.result;
          this.currentActivity.activityTargetDescription = this.getActivityTargetDescription(this.currentActivity.activityTargetList);
          this.currentActivity.startTime = this.formatDateString(this.currentActivity.startTime);
          this.currentActivity.endTime = this.formatDateString(this.currentActivity.endTime);
          this.currentActivity.activitySignupStartTime = this.formatDateString(this.currentActivity.activitySignupStartTime);
          this.currentActivity.activitySignupEndTime = this.formatDateString(this.currentActivity.activitySignupEndTime);
          // Parse manager and participant lists
          const managerList = await this.fetchStudentNames(this.currentActivity.managerList ? this.currentActivity.managerList.split(',') : []);
          this.currentActivity.managerList = managerList;
          this.detailDialogVisible = true;
          this.currentId = activity.id;
        } else {
          this.$message.error('获取活动详情失败');
        }
      } catch (error) {
        console.error('获取活动详情时发生错误:', error);
        this.$message.error('获取活动详情时发生错误');
      }
    },
    exportParticipant() {
      const map = {
        stuName: '学生姓名',
        stuId: '学生一卡通号',
        stuSchool: '学生学院',
        stuClub: '学生所属社团'
      };
      const data = this.currentActivity.participantList.map(item => ({
        stuName: item.stuName,
        stuId: item.stuId,
        stuSchool: item.stuSchool,
        stuClub: item.club
      }));
      exportToExcel(map, data, '活动参与者');

    },
    async deleteActivity() {
      try {
        const response = await this.$axios.delete(`/assistance/activity/manager/advanced/delete`, {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { activityId: this.currentId },
        });
        if (response.data.success) {
          this.$message.success("删除活动成功");
          this.detailDialogVisible = false;
          this.fetchActivities();
        } else {
          this.$message.error('删除活动失败');
        }
      } catch (error) {
        console.error('删除活动时发生错误:', error);
        this.$message.error('删除活动时发生错误');
      }
    },

    async openEditBasicInfo() {
      this.editBasicDialogVisible = true;
      this.detailDialogVisible = false;
      this.currentActivity.activityTargetList = this.currentActivity.activityTargetList.split(',').map(Number);
      const managerList = this.fetchStudentNames(this.currentActivity.managerList.split(','));
      this.currentActivity.managerList = await managerList;
    },

    async openEditParticipants() {
      this.editParticipantsDialogVisible = true;
      this.detailDialogVisible = false;
      const participantList = this.fetchStudentNames(this.currentActivity.participantList.split(','));
      this.currentActivity.participantList = await participantList;
    },

    getActivityTargetDescription(targetList) {
      const targetMapping = {
        '0': '全体学生',
        '1': '唐仲英爱心社',
        '2': '伯藜学社',
        '3': '瑞华筑梦公益平台',
        '4': '曾宪梓春晖公益平台',
        '5': '新地郭氏公益平台',
        '6': '家庭经济困难学生'
      };
      return targetList.split(',').map(id => targetMapping[id]).join(', ');
    },

    saveBasicInfo() {
      this.$refs.activityForm.validate((valid) => {
        if (valid) {
          // 表单校验通过后再提交数据
          const requestData = {
            activityClub: this.currentActivity.club,
            activityContent: this.currentActivity.content,
            activityEndTime: this.currentActivity.endTime,
            activityId: this.currentId,
            activityName: this.currentActivity.name,
            activityPeriod: this.currentActivity.duration,
            activitySemester: this.currentActivity.semester,
            activityStartTime: this.currentActivity.startTime,
            activityTargetList: this.currentActivity.activityTargetList.join(','), // 转换回字符串
            activityType: this.currentActivity.type,
            managerList: this.currentActivity.managerList.map(manager => `${manager.id}`).join(','),
            participantList: this.currentActivity.participantList.map(participant => `${participant.id}`).join(','),
            activitySignupStartTime: this.currentActivity.activitySignupStartTime,
            activitySignupEndTime: this.currentActivity.activitySignupEndTime,
          };
          this.$axios.put(`/assistance/activity/manager/advanced/change-basic`, requestData, {
            headers: {
              "x-api-token": this.$axios.defaults.headers["x-api-token"],
            }
          }).then(() => {
            this.editBasicDialogVisible = false;
            this.fetchActivities();
            this.$message.success('活动信息更新成功');
          })
            .catch(error => {
              console.error('保存活动失败:', error);
              this.$message.error('保存活动失败');
            });
        } else {
          this.$message.error('表单校验失败，请检查输入内容');
        }
      });
    },

    saveParticipants() {
      // 保存参与人信息到后端
      const requestData = {
        activityId: this.currentId,
        participantList: this.currentActivity.participantList.map(participant => `${participant.id}`).join(','),
      };
      this.$axios.put(`/assistance/activity/manager/advanced/change-participant`, requestData, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        }
      }).then(() => {
        this.editParticipantsDialogVisible = false;
        this.fetchActivities();  // 刷新活动列表
        this.$message.success('参与人信息更新成功');
      })
        .catch(error => {
          console.error('保存参与人失败:', error);
          this.$message.error('保存参与人失败');
        });
    },

    handleSelectionChange(selected) {
      if (!this.isSelectionChangeEnabled) return; // 如果 handleSelectionChange 被禁用，则不执行

      const selectedIds = selected.map(activity => activity.id);

      // 更新 selectedActivities
      this.selectedActivities = [
        ...this.selectedActivities.filter(activity => !this.activities.some(a => a.id === activity.id)),
        ...selected
      ];

      // 更新 activities 的 selected 属性
      this.activities.forEach(activity => {
        activity.selected = selectedIds.includes(activity.id);
      });
    },

    filterStatus(value, row) {
      return row.state === value;
    },

    getCheckStatusLabel(status) {
      switch (status) {
        case '待指导教师审核':
        case '待管理员审核':
          return '审核中';
        case '已通过审核':
          return '已通过';
        case '未通过审核':
          return '驳回';
        default:
          return '未知状态';
      }
    },

    getCheckStatusStyle(status) {
      switch (status) {
        case '待指导教师审核':
          return { color: '#FFA500' };
        case '待管理员审核':
          return { color: '#FFA500' };
        case '已通过审核':
          return { color: 'green' };
        case '未通过审核':
          return { color: 'red' };
        default:
          return { color: 'black' };
      }
    },

    async exportSelectedActivities() {
      if (this.selectedActivities.length === 0) {
        this.$message.warning('请至少选择一个活动进行导出');
        return;
      }

      const idList = this.selectedActivities.map(a => a.id).join(',');

      try {
        const response = await this.$axios.get(`/assistance/activity/manager/admin/export`, {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { idList }
        });

        if (response.data.success) {
          const activities = response.data.result;
          const map = {
            activityName: '活动名称',
            activityBeginTime: '活动开始时间',
            activityEndTime: '活动结束时间',
            activityDuration: '活动时长',
            activitySemester: '活动所属学期',
            activityClub: '活动负责社团',
            activityType: '活动类型',
            activityContent: '活动内容',
            activityManager: '活动负责人姓名',
            activityParticipantCount: '活动参与人数'
          };

          const data = activities.map(item => ({
            activityName: item.activityName,
            activityBeginTime: this.formatDateString(item.activityBeginTime),
            activityEndTime: this.formatDateString(item.activityEndTime),
            activityDuration: item.activityDuration,
            activitySemester: item.activitySemester,
            activityClub: item.activityClub,
            activityType: item.activityType,
            activityContent: item.activityContent,
            activityManager: item.activityManager,
            activityParticipantCount: item.activityParticipantCount
          }));

          exportToExcel(map, data, '育人活动记录');

          this.$message({
            type: 'success',
            message: '导出成功'
          });
        } else {
          this.$message.error('获取活动信息失败');
        }
      } catch (error) {
        console.error('导出活动失败:', error);
        this.$message.error('导出活动时发生错误');
      }
    },


    async fetchStudentName(id) {
      try {
        const response = await this.$axios.get('/assistance/activity/manager/admin/search-stu-info', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { nameId: id },
        });
        if (response.data.success) {
          return response.data.result.name;
        } else {
          this.$message.error('获取学生姓名失败');
          return '';
        }
      } catch (error) {
        console.error('获取学生姓名时发生错误:', error);
        this.$message.error('获取学生姓名时发生错误');
        return '';
      }
    },

    async fetchStudentNames(list) {
      const promises = list.map(async (item) => {
        const [name, id] = item.split('(');
        return {
          name: name ? name.trim() : '',
          id: id ? id.replace(')', '').trim() : ''
        };
      });
      return Promise.all(promises);
    },

    async fetchManagerSuggestions(queryString, callback) {
      if (!queryString) {
        callback([]);
        return;
      }
      try {
        const response = await this.$axios.get('/assistance/activity/manager/admin/search-stu-info', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { nameID: queryString }
        });
        if (response.data.success) {
          const suggestions = response.data.result.map(student => ({
            value: `${student.name} (${student.id})`,
            id: student.id
          }));
          callback(suggestions);
        } else {
          callback([]);
        }
      } catch (error) {
        console.error('Error fetching student suggestions:', error);
        callback([]);
      }
    },

    handleManagerSelect(item) {
      this.currentActivity.managerList.push({
        name: item.value.split(' (')[0],
        id: item.id
      });
      this.managerQuery = '';
    },

    removeManager(index) {
      this.currentActivity.managerList.splice(index, 1);
    },

    async fetchParticipantSuggestions(queryString, callback) {
      if (!queryString) {
        callback([]);
        return;
      }
      try {
        const response = await this.$axios.get('/assistance/activity/manager/admin/search-stu-info', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { nameID: queryString }
        });
        if (response.data.success) {
          const suggestions = response.data.result.map(student => ({
            value: `${student.name} (${student.id})`,
            id: student.id
          }));
          callback(suggestions);
        } else {
          callback([]);
        }
      } catch (error) {
        console.error('Error fetching student suggestions:', error);
        callback([]);
      }
    },

    handleParticipantSelect(item) {
      this.currentActivity.participantList.push({
        name: item.value.split(' (')[0],
        id: item.id
      });
      this.participantQuery = '';
    },

    removeParticipant(index) {
      this.currentActivity.participantList.splice(index, 1);
    },

    formatDateString(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    handleFilterChange(filters) {
      this.filters.club = filters.club ? filters.club[0] : this.filters.club;
      this.filters.activityType = filters.type ? filters.type[0] : this.filters.activityType;
      this.filters.finishStatus = filters.state ? filters.state[0] : this.filters.finishStatus;
      this.filters.checkStatus = filters.check ? filters.check[0] : this.filters.checkStatus;
      this.currentPage = 1;
      this.fetchActivities();
    },
  }
};
</script>

<style scoped>
.el-button {
  margin-right: 5px;
  margin-left: 20px;
}

.el-container {
  margin-bottom: 20px;
  /* 调整底部间距 */
}

.el-divider {
  margin-top: 20px;
  /* 调整顶部间距 */
}

.selected-item {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.upload-demo {
  margin: 20px 0;
}

.selected-item .el-button {
  margin-left: 10px;
}

.no-required .el-form-item__label::before {
  content: none;
}

.upload-buttons {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: flex-start;
  /* 水平开始位置对齐 */
  gap: 10px;
  /* 按钮之间的间距 */
}

/* 如果上面的不工作，可以尝试使用深度选择器 */
::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
  color: black !important;
  /* 设置文本颜色为黑色 */
}
</style>
