<template>
  <div v-loading="isLoading">
    <el-divider>
      <h3 style="text-align: center; margin-bottom: 20px;">审核学生申请</h3>
    </el-divider>

    <el-divider>
      <h4 style="text-align: center; margin-bottom: 20px;">待审核申请列表</h4>
    </el-divider>
    <el-table ref="multipleTable" :data="applies" style="width: 100%;" @selection-change="handleSelectionChange" @filter-change="handleFilterChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="stuName" label="姓名" width="75"></el-table-column>
      <el-table-column prop="stuId" label="一卡通号" width="100"></el-table-column>
      <el-table-column prop="activityName" label="活动名称"></el-table-column>
      <el-table-column prop="startTime" label="活动开始时间"></el-table-column>
      <el-table-column prop="applyTime" label="申请时间">
        <template slot="header" scope="scope">
          <span>{{ scope.column.label }}</span>
          <el-button size="mini" :icon="order ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            @click="toggleSort()"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="applyType" label="申请类型" :filters="applyTypes" :filter-multiple="false" column-key="type"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="button-container">
            <el-button @click="pass(scope.row)" size="small" type="success">通过</el-button>
            <el-button @click="reject(scope.row)" size="small" type="danger">退回</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px;">
      <div>
        <el-button type="success" @click="passSelected">通过选中</el-button>
        <el-button type="danger" @click="rejectSelected">退回选中</el-button>
      </div>
      <el-pagination @current-change="handlePageChange" :current-page="currentPage" :page-size="pageSize"
        :total="totalApplies" layout="total, prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>


<script>
import {
  Button,
  Table,
  TableColumn,
  Divider,
  Pagination,
} from "element-ui"

export default {
  components: {
    "el-button": Button,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-divider": Divider,
    "el-pagination": Pagination,
  },
  data() {
    return {
      isLoading: true,
      allApplies: [],
      applies: [],
      selectedApplies: [], // 保存已选中的申请
      selectedAppliesIds: new Set(), // 用于保存已选中的申请 ID
      currentPage: 1,
      pageSize: 25,
      totalApplies: 0,
      order: false,  // 默认降序
      filters: {
        applyType: null,
      },
      currentId: 0,
      applyTypes: [
        { text: '报名', value: '报名' },
        { text: '取消报名', value: '取消报名' }
      ],
      isSelectionChangeEnabled: true, // 控制 handleSelectionChange 是否启用
    };
  },

  async created() {
    await this.fetchApplies(this.currentPage);
  },

  methods: {
    toggleSort() {
      this.order = !this.order; // 切换布尔值，true为升序，false为降序
      this.sortApplies();
    },

    async fetchApplies() {
      this.isLoading = true;
      this.isSelectionChangeEnabled = false; // 禁用 handleSelectionChange

      const params = {
        applyType: this.filters.applyType,
      };
      try {
        const response = await this.$axios.get('/assistance/activity/manager/check/query', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params
        });
        const appliesFromResponse = response.data.result;

        // 更新申请的选中状态
        appliesFromResponse.forEach(apply => {
          const uniqueId = `${apply.activityId}-${apply.stuId}`;
          const found = this.selectedAppliesIds.has(uniqueId);
          this.$set(apply, 'selected', found);
        });

        this.allApplies = response.data.result.map(apply => ({
          ...apply,
          startTime: this.formatDateString(apply.startTime),
          applyTime: this.formatDateString(apply.applyTime),
        }));

        this.sortApplies();

        this.totalApplies = this.allApplies.length;  // 更新总页数

        this.paginateApplies();
        // 使用 $nextTick 确保 DOM 更新后执行
        this.$nextTick(() => {
          this.applies.forEach(apply => {
            const uniqueId = `${apply.activityId}-${apply.stuId}`;
            const found = this.selectedAppliesIds.has(uniqueId);
            this.$refs.multipleTable.toggleRowSelection(apply, found);
          });
          this.isSelectionChangeEnabled = true; // 重新启用 handleSelectionChange
        });

        this.isLoading = false;
      } catch (error) {
        console.error('Failed to fetch applies:', error);
        this.$message.error('加载申请列表失败');
        this.isLoading = false;
      }
    },

    sortApplies() {
      this.allApplies.sort((a, b) => {
        const dateA = new Date(a.applyTime);
        const dateB = new Date(b.applyTime);
        return this.order ? dateA - dateB : dateB - dateA;
      });
      this.paginateApplies();
    },

    paginateApplies() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.applies = this.allApplies.slice(start, end);
    },

    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.fetchApplies(newPage);
    },

    handleSelectionChange(selected) {
      if (!this.isSelectionChangeEnabled) return; // 如果 handleSelectionChange 被禁用，则不执行

      const selectedIds = selected.map(apply => `${apply.activityId}-${apply.stuId}`);

      // 更新 selectedApplies 和 selectedAppliesIds
      this.selectedApplies = [
        ...this.selectedApplies.filter(apply => !this.applies.some(a => a.activityId === apply.activityId && a.stuId === apply.stuId)),
        ...selected
      ];

      this.selectedAppliesIds = new Set(this.selectedApplies.map(apply => `${apply.activityId}-${apply.stuId}`));

      // 更新 applies 的 selected 属性
      this.applies.forEach(apply => {
        const uniqueId = `${apply.activityId}-${apply.stuId}`;
        apply.selected = selectedIds.includes(uniqueId);
      });
    },

    pass(apply) {
      this.$axios.post('/assistance/activity/manager/check/pass', {
        activityId: apply.activityId,
        stuId: apply.stuId
      }, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        }
      })
        .then(response => {
          if (response.data.success) {
            this.$message.success('申请审核通过');
            this.fetchApplies(this.currentPage);
          } else {
            this.$message.error('申请审核失败');
          }
        })
        .catch(error => {
          console.error('申请审核通过时发生错误:', error);
          this.$message.error('申请审核通过时发生错误');
        });
    },

    reject(apply) {
      this.$axios.put('/assistance/activity/manager/check/reject', {
        activityId: apply.activityId,
        stuId: apply.stuId
      }, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        }
      })
        .then(response => {
          if (response.data.success) {
            this.$message.success('申请已驳回');
            this.fetchApplies(this.currentPage);
          } else {
            this.$message.error('申请驳回失败');
          }
        })
        .catch(error => {
          console.error('申请驳回时发生错误:', error);
          this.$message.error('申请驳回时发生错误');
        });
    },

    passSelected() {
      const requests = this.selectedApplies.map(apply => this.$axios.post('/assistance/activity/manager/check/pass', {
        activityId: apply.activityId,
        stuId: apply.stuId
      }, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        }
      }));
      Promise.all(requests)
        .then(() => {
          this.$message.success('选中申请审核通过');
          this.currentPage = 1;
          this.selectedApplies = []; // 清空选中申请数组
          this.selectedAppliesIds.clear(); // 清空选中申请的 IDs
          this.$refs.multipleTable.clearSelection(); // 清空表格选中状态
          this.fetchApplies(this.currentPage);
        })
        .catch(error => {
          console.error('批量申请审核通过时发生错误:', error);
          this.$message.error('批量申请审核通过时发生错误');
        });
    },

    rejectSelected() {
      const requests = this.selectedApplies.map(apply => this.$axios.put('/assistance/activity/manager/check/reject', {
        activityId: apply.activityId,
        stuId: apply.stuId
      }, {
        headers: {
          "x-api-token": this.$axios.defaults.headers["x-api-token"],
        }
      }));
      Promise.all(requests)
        .then(() => {
          this.$message.success('选中申请已驳回');
          this.currentPage = 1;
          this.selectedApplies = []; // 清空选中申请数组
          this.selectedAppliesIds.clear(); // 清空选中申请的 IDs
          this.$refs.multipleTable.clearSelection(); // 清空表格选中状态
          this.fetchApplies(this.currentPage);
        })
        .catch(error => {
          console.error('批量申请驳回时发生错误:', error);
          this.$message.error('批量申请驳回时发生错误');
        });
    },

    formatDateString(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    handleFilterChange(filters) {
      this.filters.applyType = filters.type ? filters.type[0] : this.filters.applyType;
      this.currentPage = 1;
      this.fetchApplies();
    },
  }
};
</script>


<style scoped>
.el-button {
  margin-right: 5px;
  margin-left: 20px;
}

.el-container {
  margin-bottom: 20px;
  /* 调整底部间距 */
}

.el-divider {
  margin-top: 30px;
  /* 调整顶部间距 */
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination-container .left-buttons {
  display: flex;
}

.pagination-container .left-buttons .el-button {
  margin-right: 10px;
}

.button-container {
  display: flex;
  gap: 2px;
  /* 减少按钮之间的间距 */
  margin-left: -30px;
  /* 向左移动按钮 */
}

@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    align-items: stretch;
  }

  .button-container > div {
    margin-bottom: 10px;
  }
}
</style>
