<template>
    <div v-loading="isLoading">
        <el-container style="justify-content: center; align-items: center;  margin-bottom: 40px;">
            <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%; margin-right: 10px;">
                <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
                    :value="option.name"></el-option>
            </el-select>
            <el-select v-model="selectedType" placeholder="选择课程类型查询" style="width: 100%; margin-right: 10px;">
                <el-option v-for="(option, index) in courseTypeList" :key="index" :label="option"
                    :value="option"></el-option>
            </el-select>
            <el-button type="primary" @click="fetchCourses()">查询</el-button>
        </el-container>

        <el-divider>
            <h3 style="text-align: center; margin-bottom: 20px;">全部课程列表</h3>
        </el-divider>

        <el-table :data="paginatedCourseList" border stripe>
            <el-table-column prop="courseName" label="课程名称">
                <template slot-scope="scope">
                    <el-button type="text" @click="openDetailDialog(scope.row)">
                        {{ scope.row.courseName }}
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column prop="startTime" label="课程开始时间"></el-table-column>
            <el-table-column prop="courseType" label="课程类型"></el-table-column>
            <el-table-column label="授课容量" align="center">
                <template slot-scope="scope">
                    <span
                        :style="{ color: scope.row.selectedCount >= scope.row.capacity || scope.row.capacity === null ? 'red' : 'green' }">
                        {{ scope.row.selectedCount }}/{{ scope.row.capacity }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="manager" label="课程负责人"></el-table-column>
            <el-table-column label="选课" width="200" align="center">
                <template slot-scope="scope">
                    <el-button v-if="!scope.row.signedUp && scope.row.withinTime && !scope.row.signedUpHomo" type="primary"
                        @click="signupForCourse(scope.row)" :key="3">
                        报名
                    </el-button>
                    <el-button v-else-if="scope.row.signedUp && scope.row.withinTime" type="danger"
                        @click="signoutFromCourse(scope.row)" :key="2">
                        退课
                    </el-button>
                    <el-button v-else type="default" :disabled="true" :key="1">
                        {{ judgecanSignUp(scope.row) }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="total, prev, pager, next, jumper" :current-page.sync="currentPage"
            :page-size="pageSize" :total="totalCourses" @current-change="showDifferentPage">
        </el-pagination>

        <!-- 课程详情弹窗 -->
        <el-dialog :visible.sync="detailDialogVisible" title="课程详细信息" width="600px">
            <el-form :model="currentCourse" label-width="120px">
                <el-form-item label="课程名称">
                    <p style="font-weight: bold;">{{ currentCourse.courseName }}</p>
                </el-form-item>
                <el-form-item label="课程类型">
                    <p style="font-weight: bold;">{{ currentCourse.courseType }}</p>
                </el-form-item>
                <el-form-item label="课程负责人姓名">
                    <el-autocomplete v-model="currentCourse.manager" :fetch-suggestions="fetchChargePersonSuggestions"
                        placeholder="请输入课程负责人姓名" :disabled="true"
                        @select="handleSelectChargePerson">
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="授课容量">
                    <el-input v-model="currentCourse.capacity" :disabled="true"
                        style="width: 100px;"></el-input>
                </el-form-item>
                <el-form-item label="课程开始时间">
                    <el-datetime-picker v-model="currentCourse.startTime" type="datetime"
                        value-format="yyyy-MM-dd HH:mm" :disabled="true" placeholder="选择课程开始时间"
                        format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>
                <el-form-item label="课程结束时间">
                    <el-datetime-picker v-model="currentCourse.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm"
                        :disabled="true" placeholder="选择课程结束时间" format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>

                <el-form-item label="报名开始时间">
                    <el-datetime-picker v-model="currentCourse.signupStart" type="datetime"
                        value-format="yyyy-MM-dd HH:mm" :disabled="true" placeholder="选择报名开始时间"
                        format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>
                <el-form-item label="报名结束时间">
                    <el-datetime-picker v-model="currentCourse.signupEnd" type="datetime"
                        value-format="yyyy-MM-dd HH:mm" :disabled="true" placeholder="选择报名结束时间"
                        format="yyyy-MM-dd HH:mm">
                    </el-datetime-picker>
                </el-form-item>
                <el-form-item label="上课地点" style="width: 300px;">
                    <el-input v-model="currentCourse.site" :disabled="true"></el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="detailDialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    Button,
    Form,
    TableColumn,
    Select,
    Option,
    Col,
    Row,
    Divider,
    Container,
    Table,
    Dialog,
    FormItem,
    Pagination,
    Autocomplete,
    DatePicker,
    Checkbox,
    Collapse,
} from "element-ui";
import exportToExcel from '../../plugins/export.js';
export default {
    components: {
        "el-table": Table,
        "el-button": Button,
        "el-form": Form,
        "el-table-column": TableColumn,
        "el-select": Select,
        "el-option": Option,
        "el-col": Col,
        "el-row": Row,
        "el-dialog": Dialog,
        "el-form-item": FormItem,
        "el-pagination": Pagination,
        "el-autocomplete": Autocomplete,
        "el-datetime-picker": DatePicker,
        "el-checkbox": Checkbox,
        "el-collapse": Collapse,
        "el-container": Container,
        "el-divider": Divider,
    },
    data() {
        return {
            isLoading: true,
            selectedTerm: "",
            selectedType: "",
            termList: [],
            courseTypeList: ["必修", "选修"],
            currentPage: 1,
            pageSize: 10,
            totalCourses: 0,
            detailDialogVisible: false,
            signInDialogVisible: false,
            participantDialogVisible: false,
            managerName: null,
            managerId: null,
            courseList: [],
            currentCourse: {},
            selectAll: false,
            role: null,
        };
    },
    methods: {
        async getRole() {
            try {
                const response = await this.$axios.get("/assistance/role", {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    }
                });
                if (response.data.success) {
                    this.role = response.data.result;
                    console.log(this.role);
                } else {
                    this.$message({
                        type: "warning",
                        message: "权限不存在",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "查询权限信息时出错",
                });
            }
        },
        async loadTerm() {
            try {
                let termList = await this.$axios.get("/term");
                if (termList.data.success) {
                    this.termList = termList.data.result.list.reverse().slice(0, 2);
                    this.termList.push({ name: '全部学期', startDate: null, endDate: null });
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },
        async signupForCourse(course) {
            try {
                const response = await this.$axios.post('/assistance/course/signup', {
                    courseId: course.id
                }, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    }
                });

                if (response.data.success) {
                    this.$message({
                        type: 'success',
                        message: `报名成功：${course.courseName}`
                    });
                    course.selectedCount += 1;
                    course.signedUp = true;

                } else {
                    this.$message({
                        type: 'error',
                        message: `报名失败：${response.data.result}`
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '报名过程中出现错误'
                });
            }
        },
        async signoutFromCourse(course) {
            if (!course.withinTime) {
                this.$message({
                    type: 'warning',
                    message: '不在选课时间内'
                });
                return;
            }
            try {
                const response = await this.$axios.delete('/assistance/course/signout', {
                    params: { courseId: course.id },
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    }
                });

                if (response.data.success) {
                    this.$message({
                        type: 'success',
                        message: `退课成功：${course.courseName}`
                    });

                    course.selectedCount -= 1;
                    course.signedUp = false;

                } else {
                    this.$message({
                        type: 'error',
                        message: `退课失败：${response.data.result}`
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '退课过程中出现错误'
                });
            }
        },
        judgecanSignUp(course) {
            if (!course.withinTime) {
                return '不在报名时间内';
            } else if (course.signedUp) {
                return '已报名';
            } else if (course.signedUpHomo){
                return '已报名同类型课程';
            }
        },
        
        showDifferentPage(page) {
            this.currentPage = page;
        },
        

        async fetchCourses() {
            const params = {
                isMine: 1,
            };
            if (this.selectedTerm !== "") {
                params.term = this.selectedTerm;
            }
            if (this.selectedType === '选修') {
                params.isMandatory = 1;
            } else if (this.selectedType === '必修') {
                params.isMandatory = 2;
            }
            try {
                const response = await this.$axios.post(`/assistance/course/query-stu`, params, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                });
                if (response.data.success) {
                    this.courseList = response.data.result.map(course => ({
                        id: course.id,
                        courseName: course.courseName,
                        courseType: this.judgeType(course.courseType),
                        manager: course.manager,
                        term: course.term,
                        capacity: course.capacity,
                        selectedCount: course.selectedCount,
                        startTime: this.formatDate(course.startTime),
                        endTime: this.formatDate(course.endTime),
                        signupStart: this.formatDate(course.signupStart),
                        signupEnd: this.formatDate(course.signupEnd),
                        site: course.site,
                        signedUp: course.signedup === 1 ? true : false,
                        withinTime: course.withinTime === 1 ? true : false,
                        signedUpHomo: course.signedUpHomo === 1 ? true : false,
                        managerId: course.managerId,
                        selected: false,
                    }));
                    this.totalCourses = this.courseList.length;
                } else {
                    this.$message.warning("查询失败");
                }
            } catch (error) {
                this.$message.error("查询课程列表时出错");
            }
        },
        
        async openDetailDialog(course) {
            this.currentCourse = { ...course };
            console.log(this.currentCourse.startTime);
            this.detailDialogVisible = true;
        },

        judgeType(courseType) {
            const mandatoryCourses = ['思辨力', '学习力', '创造力', '领导力'];
            const electiveCourses = ['德育', '智育', '体育', '美育', '劳育'];

            if (mandatoryCourses.includes(courseType)) {
                return `${courseType}（必修）`;
            } else if (electiveCourses.includes(courseType)) {
                return `${courseType}（选修）`;
            } else {
                return courseType; // 如果不是这两类中的课程，则保持原样
            }
        },


    },
    async created() {
        this.isLoading = true;
        await this.loadTerm();
        await this.getRole();
        await this.fetchCourses();
        this.isLoading = false;
    },
    computed: {
        paginatedCourseList() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = this.currentPage * this.pageSize;
            return this.courseList.slice(start, end);
        },
    },
};
</script>

<style scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-row {
    margin-bottom: 5px;
}

.el-col {
    padding-right: 10px;
}

.custom-popper {
    margin-left: 15px;
}

.centered-title {
    text-align: center;
    margin-bottom: 20px;
}

.dialog-footer {
    text-align: right;
}

::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
    color: black !important;
    /* 设置文本颜色为黑色 */
}

@media screen and (max-width: 768px) {
    .el-row {
        flex-direction: column;
    }
}
</style>
