<template>
	<div>
		<el-container style="justify-content: center; align-items: center;  margin-bottom: 20px;">
			<el-input v-model="keyWord" placeholder="请输入活动名称进行搜索" style="width: 300px;"></el-input>
			<el-button type="primary" @click="fetchActivities" style="margin-left: 20px;">查询</el-button>
		</el-container>
		<el-table :data="activities" style="width: 100%;" @filter-change="handleFilterChange">
			<el-table-column prop="beginTime" label="开始时间">
				<template slot="header" scope="scope">
				<span>{{ scope.column.label }}</span>
				<el-button size="mini" :icon="order ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
					@click="toggleSort()"></el-button>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="活动名称"></el-table-column>
			<el-table-column prop="raiser" label="发起单位" :filters="clubs" :filter-multiple="false" column-key="club"></el-table-column>
			<el-table-column prop="activityType" label="活动类型" :filters="activityTypes" :filter-multiple="false" column-key="type"></el-table-column>
			<el-table-column prop="activityState" label="活动状态" :filters="statusFilters" :filter-multiple="false"  column-key="state"></el-table-column>
			<el-table-column prop="activitySignupStatus" label="活动报名进度">
				<template slot-scope="scope">
					<span :style="getStatusStyle(scope.row.activitySignupStatus)">{{ scope.row.activitySignupStatus
						}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="170">
				<template slot-scope="scope">
					<div class="button-group">
						<el-button @click="viewDetails(scope.row)" size="mini">查看</el-button>
						<el-button v-if="scope.row.activitySignupStatus === '报名审核通过' && scope.row.canWithdraw"
							@click="cancelSignup(scope.row)" size="mini">申请取消</el-button>
						<el-button v-if="scope.row.activitySignupStatus === '报名申请待审核' && scope.row.canWithdraw"
							@click="cancelSignup(scope.row)" size="mini">取消报名</el-button>
						<el-button v-if="scope.row.activitySignupStatus === '取消报名待审核'" @click="signup(scope.row)"
							size="mini">撤回取消</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog :visible.sync="detailDialogVisible" title="活动详情" width="60%">
			<div v-if="currentActivity" class="details-grid">
				<el-form :model="currentActivity" label-position="left" label-width="120px">
					<el-form-item label="活动名称：">
						<el-input v-model="currentActivity.name" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动开始月份：">
						<el-input v-model="currentActivity.month" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动开始时间：">
						<el-input v-model="currentActivity.startTime" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动结束时间：">
						<el-input v-model="currentActivity.endTime" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="负责社团：">
						<el-input v-model="currentActivity.club" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动类型：">
						<el-input v-model="currentActivity.type" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动时长（h）：">
						<el-input v-model="currentActivity.duration" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动所属学期：">
						<el-input v-model="currentActivity.semester" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动负责人：">
						<el-input v-model="currentActivity.manager" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="活动状态：">
						<el-input v-model="currentActivity.status" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="报名开始时间：">
						<el-input v-model="currentActivity.activitySignupStartTime" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="报名结束时间：">
						<el-input v-model="currentActivity.activitySignupEndTime" :disabled="true"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	Button,
	Input,
	Table,
	TableColumn,
	Container,
	Dialog,
	Form,
	FormItem,
} from "element-ui"

export default {
	components: {
		"el-button": Button,
		"el-input": Input,
		"el-table": Table,
		"el-table-column": TableColumn,
		"el-container": Container,
		"el-dialog": Dialog,
		"el-form": Form,
		"el-form-item": FormItem,
	},

	data() {
		return {
			keyWord: '',
			activities: [],
			currentActivity: {},
			detailDialogVisible: false,
			filters: {
				club: null,
				activityType: null,
				finishStatus: null,
			},
			clubs: [
				{ text: '唐仲英爱心社', value: '唐仲英爱心社' },
				{ text: '伯藜学社', value: '伯藜学社' },
				{ text: '瑞华筑梦公益平台', value: '瑞华筑梦公益平台' },
				{ text: '曾宪梓春晖公益平台', value: '曾宪梓春晖公益平台' },
				{ text: '新地郭氏公益平台', value: '新地郭氏公益平台' },
			],
			activityTypes: [
				{ text: '日常管理', value: '日常管理' },
				{ text: '思想引领', value: '思想引领' },
				{ text: '成长互助', value: '成长互助' },
				{ text: '品牌活动', value: '品牌活动' },
				{ text: '资助中心', value: '资助中心' },
			],
			statusFilters: [
				{ text: '未开始', value: '未开始' },
				{ text: '进行中', value: '进行中' },
				{ text: '已结束', value: '已结束' }
			],
			order: false,
		};
	},

	async created() {
		await this.fetchActivities();
	},

	methods: {
		toggleSort() {
			this.order = !this.order; // 切换布尔值，true为升序，false为降序
			this.sortActivities();
		},

		async fetchActivities() {
			if (this.keyWord === '') {
				const params = {
					club: this.filters.club,
					activityType: this.filters.activityType,
					finishStatus: this.filters.finishStatus,
				};
				this.$axios.get('/assistance/activity/stu/query-selected', {
					headers: {
						"x-api-token": this.$axios.defaults.headers["x-api-token"],
					},
					params
				})
					.then(response => {
						this.activities = response.data.result.map(activity => ({
							...activity,
							beginTime: this.formatDateString(activity.beginTime),
						}));

						// 根据 order 进行排序
						this.sortActivities();
					})
					.catch(error => {
						console.error('Failed to fetch activities:', error);
						this.$message.error('加载活动列表失败');
					});
			} else {
				const params = {
					keyWord: this.keyWord,
					club: this.filters.club,
					activityType: this.filters.activityType,
					finishStatus: this.filters.finishStatus,
				};
				this.$axios.get('/assistance/activity/stu/query-selected', {
					headers: {
						"x-api-token": this.$axios.defaults.headers["x-api-token"],
					},
					params
				})
					.then(response => {
						this.activities = response.data.result.map(activity => ({
							...activity,
							beginTime: this.formatDateString(activity.beginTime),
						}));

						// 根据 order 进行排序
						this.sortActivities();
					})
					.catch(error => {
						console.error('Failed to fetch activities:', error);
						this.$message.error('加载活动列表失败');
					});
			}
		},

		sortActivities() {
			this.activities.sort((a, b) => {
				const dateA = new Date(a.beginTime);
				const dateB = new Date(b.beginTime);
				return this.order ? dateA - dateB : dateB - dateA;
			});
		},

		getStatusStyle(status) {
			switch (status) {
				case '报名申请待审核':
					return { color: '#FFA500' };
				case '取消报名待审核':
					return { color: '#FFA500' };
				case '报名审核通过':
					return { color: 'green' };
				case '取消报名审核未通过':
					return { color: 'red' };
				case '报名审核未通过':
					return { color: 'red' };
				default:
					return { color: 'black' };
			}
		},

		formatDateString(dateString) {
			const date = new Date(dateString);

			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');

			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},

		async viewDetails(activity) {
			try {
				const response = await this.$axios.get('/assistance/activity/stu/detail', {
					headers: {
						"x-api-token": this.$axios.defaults.headers["x-api-token"],
					},
					params: { activityId: activity.id }
				});
				if (response.data.success) {
					this.currentActivity = response.data.result;
					this.currentActivity.startTime = this.formatDateString(this.currentActivity.startTime);
					this.currentActivity.endTime = this.formatDateString(this.currentActivity.endTime);
					this.currentActivity.activitySignupStartTime = this.formatDateString(this.currentActivity.activitySignupStartTime);
					this.currentActivity.activitySignupEndTime = this.formatDateString(this.currentActivity.activitySignupEndTime);
					this.detailDialogVisible = true;
				} else {
					this.$message.error('获取活动详情失败');
				}
			} catch (error) {
				console.error('获取活动详情时发生错误:', error);
				this.$message.error('获取活动详情时发生错误');
			}
		},

		async cancelSignup(activity) {
			try {
				const response = await this.$axios.delete('/assistance/activity/stu/withdraw', {
					headers: {
						"x-api-token": this.$axios.defaults.headers["x-api-token"],
					},
					params: { activityId: activity.id }
				});
				if (response.data.success) {
					this.$message({
						type: 'success',
						message: response.data.result,
					});
					this.fetchActivities();
				} else {
					this.$message.error('取消报名失败');
				}
			} catch (error) {
				console.error('取消报名时发生错误:', error);
				this.$message.error('取消报名时发生错误');
			}
		},

		async signup(activity) {
			try {
				const response = await this.$axios.delete('/assistance/activity/stu/withdraw', {
					headers: {
						"x-api-token": this.$axios.defaults.headers["x-api-token"],
					},
					params: { activityId: activity.id }
				});
				if (response.data.success) {
					this.$message({
						type: 'success',
						message: '撤回取消报名成功',
					});
					this.fetchActivities();
				} else {
					this.$message.error('撤回取消报名失败');
				}
			} catch (error) {
				console.error('撤回取消报名时发生错误:', error);
				this.$message.error('撤回取消报名时发生错误');
			}
		},

		handleFilterChange(filters) {
			this.filters.club = filters.club ? filters.club[0] : this.filters.club;
			this.filters.activityType = filters.type ? filters.type[0] : this.filters.activityType;
			this.filters.finishStatus = filters.state ? filters.state[0] : this.filters.finishStatus;
			this.fetchActivities();
		},
	}
}
</script>

<style scoped>
.button-group {
	display: flex;
	gap: 2px;
	margin-left: -10px;
}

::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
	color: black !important;
	/* 设置文本颜色为黑色 */
}
</style>