console.log('Powered by Herald Studio - 信使计划工作室')
console.log(`
 _    _                _     _    _____ _             _ _       
| |  | |              | |   | |  / ____| |           | (_)      
| |__| | ___ _ __ __ _| | __| | | (___ | |_ _   _  __| |_  ___  
|  __  |/ _ \\ '__/ _\` | |/ _\` |  \\___ \\| __| | | |/ _\` | |/ _ \\ 
| |  | |  __/ | | (_| | | (_| |  ____) | |_| |_| | (_| | | (_) |
|_|  |_|\\___|_|  \\__,_|_|\\__,_| |_____/ \\__|\\__,_|\\__,_|_|\\___/ 


`)