<template>
    <div class="checkin-page">
        <h1>{{ message }}</h1>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: "已成功签到",
            qrcode: "", // 用于存储二维码内容
        };
    },
    async created() {
        // 在组件创建时调用接口获取二维码内容
        try {
            const res = await this.$axios.post("/assistance/course/checkin", {
                qrcode: this.$route.query.token
            });
            if (res.data.success) {
                this.$message.success("获取二维码成功");
            } else {
                this.$message.error(res.data.result || "获取二维码失败");
            }
        } catch (error) {
            this.$message.error("获取二维码时出错");
        }
    }
};
</script>

<style scoped>
.checkin-page {
    text-align: center;
    padding: 20px;
}
</style>