<template>
    <div v-loading="isLoading">
        <el-row :gutter="24">
            <el-col :span="24">
                <el-form class="custom-popper">

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="所属学期" required>
                                <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%">
                                    <el-option v-for="(term, index) in termList" :key="index" :label="term.name"
                                        :value="term.name"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程名称" required>
                                <el-select v-model="courseName" placeholder="请选择课程名称" style="width: 100%">
                                    <el-option v-for="(name, i) in allCourseName" :key="i" :value="name"
                                        :label="name"></el-option>
                                </el-select>
                                <el-row v-if="courseName !== ''">
                                    <p class=" info-text">课程类型：{{ judgeType(this.courseName) }}</p>
                                </el-row>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程容量">
                                <el-input v-model="capacity" placeholder="请输入课程容量" style="width: 100%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="上课地点">
                                <el-input v-model="site" placeholder="请输入上课地点" style="width: 100%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="负责人" required>
                                <el-container style="width: 100%; align-items: center;">
                                    <el-autocomplete v-model="inChargeSearch"
                                        :fetch-suggestions="fetchChargePersonSuggestions" placeholder="请输入负责人姓名或一卡通号"
                                        style="width: 300px;" @select="handleSelectChargePerson">
                                    </el-autocomplete>
                                </el-container>
                            </el-form-item>
                        </el-col>
                    </el-row>





                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程开始时间">
                                <el-datetime-picker v-model="startTime" type="datetime" placeholder="请选择课程开始时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="课程结束时间">
                                <el-datetime-picker v-model="endTime" type="datetime" placeholder="请选择课程结束时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="报名开始时间">
                                <el-datetime-picker v-model="signupStart" type="datetime" placeholder="请选择报名开始时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="报名结束时间">
                                <el-datetime-picker v-model="signupEnd" type="datetime" placeholder="请选择报名结束时间"
                                    format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 100%">
                                </el-datetime-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>


                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" @click="submit" style="margin-top: 8px">提交</el-button>
                            <el-button type="danger" @click="resetForm" style="margin-top: 8px">重置</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {
    Button,
    Form,
    FormItem,
    Input,
    Select,
    Option,
    DatePicker,
    Col,
    Row,
    Container,
    Autocomplete,
} from "element-ui";

export default {
    components: {
        "el-button": Button,
        "el-form": Form,
        "el-form-item": FormItem,
        "el-input": Input,
        "el-select": Select,
        "el-option": Option,
        "el-col": Col,
        "el-row": Row,
        "el-datetime-picker": DatePicker,
        "el-container": Container,
        "el-autocomplete": Autocomplete,
    },
    data() {
        return {
            courseType: null,
            selectedTerm: null,
            courseName: "",
            capacity: null,
            inChargeSearch: "",
            suggestions: null,
            managerId: null,
            managerName: null,
            site: null,
            startTime: null,
            endTime: null,
            signupStart: null,
            signupEnd: null,
            termList: [],
            isLoading: true,
            allCourseName: [
                "资助专员答辩",
                "资助宣传大使答辩",
                "家访优秀团队答辩",
                "资助政策宣讲",
                "德育培训班",
                "智育培训班",
                "体育培训班",
                "美育培训班",
                "劳育培训班",
            ],
        };
    },

    methods: {
        async submit() {
            if (!this.validateForm()) {
                return;
            }
            try {
                const params = {
                    courseType: this.courseName,
                    manager: this.managerId,
                    term: this.selectedTerm,
                    capacity: this.capacity,
                    startTime: this.startTime,
                    endTime: this.endTime,
                    signupStart: this.signupStart,
                    signupEnd: this.signupEnd,
                    site: this.site,
                };
                const response = await this.$axios.post(`/assistance/course/create`, params, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                });
                if (response.data.success) {
                    this.resetForm();
                    this.$message({
                        type: "success",
                        message: "表单提交成功",
                    });

                } else {
                    this.resetForm();
                    this.$message({
                        type: "warning",
                        message: response.data.reason,
                    });
                }
            } catch (error) {
                this.resetForm();
                this.$message({
                    type: "error",
                    message: "表单提交出错",
                });
            }
        },

        async loadTerm() {
            try {
                let termList = await this.$axios.get("/term");
                if (termList.data.success) {
                    this.termList = termList.data.result.list.reverse().slice(0, 2);
                    this.selectedTerm = termList.data.result.currentTerm.name;
                    this.currentTerm = termList.data.result.currentTerm.name;
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },
        judgeType(courseName) {
            if (courseName === null) return;
            if (!courseName.includes("育")) {
                this.courseType = "必修";
                return "必修"
            } else {
                this.courseType = "选修";
                return "选修"
            }

        },
        async fetchChargePersonSuggestions(searchInCharge, callback) {
            if (!searchInCharge) {
                callback([]);
                return;
            }
            try {
                const response = await this.$axios.get(`/assistance/course/search-tea-info`, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        nameID: searchInCharge
                    }
                });
                if (response.data.success) {
                    this.suggestions = response.data.result.map(charger => ({
                        value: `${charger.name} (${charger.id})`,
                        id: charger.id
                    }
                    ));
                    callback(this.suggestions);
                } else {
                    callback([]);
                }
            } catch (error) {
                callback([]);
            }
        },
        handleSelectChargePerson(item) {
            this.managerId = item.id;
            this.managerName = item.value.split(' (')[0];
        },
        validateForm() {
            if (!this.courseName || !this.selectedTerm || !this.inChargeSearch) {
                this.$message.warning("请填写所有必填项");
                return false;
            }
            if (this.capacity && isNaN(this.capacity)) {
                this.$message.warning("课程容量必须为数字");
                return false;
            }
            return true;
        },

        resetForm() {
            this.courseName = "";
            this.courseType = null;
            this.selectedTerm = null;
            this.courseName = null;
            this.capacity = null;
            this.inChargeSearch = "";
            this.startTime = null;
            this.endTime = null;
            this.signupStart = null;
            this.signupEnd = null;
            this.site = null;
        },

        async searchInCharge() {
            if (!this.inChargeSearch) {
                this.$message.warning("请输入一卡通号以搜索负责人");
                return;
            }
            try {
                const response = await this.$axios.get(`/api/incharge/${this.inChargeSearch}`);
                if (response.data.success) {
                    this.$message.success(`负责人信息：${response.data.name}`);
                } else {
                    this.$message.error("未找到负责人");
                }
            } catch (error) {
                this.$message.error("搜索负责人信息出错");
            }
        },
    },
    async created() {
        this.loadTerm();
        this.isLoading = false;
    },
};
</script>

<style lang="less" scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-row {
    margin-bottom: 5px;
    /* 调整行间距 */
}

.el-col {
    padding-right: 10px;
    /* 调整右侧间距 */
}

.custom-popper {
    margin-left: 30px;
    /* 确保表单左边距 */
}
</style>