<template>
  <div v-loading="isLoading">
    <el-divider>
      <h3 style="text-align: center; margin-bottom: 20px;">报名活动</h3>
    </el-divider>
    <el-form class="custom-popper" ref="personalInfo" :model="personalInfo" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="学生姓名" prop="stuName">
            <el-input v-model="personalInfo.stuName" style="width: 80%;" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学生一卡通号" prop="stuCardNum">
            <el-input v-model="personalInfo.stuCardNum" style="width: 80%;" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="学生入学年份" prop="stuGrade">
            <el-input v-model="personalInfo.stuGrade" style="width: 80%;" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学生所属学院" prop="stuSchool">
            <el-input v-model="personalInfo.stuSchool" style="width: 80%;" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-divider>
      <h4 style="text-align: center; margin-bottom: 20px;">育人活动列表</h4>
    </el-divider>

    <el-tabs v-model="activeType" @tab-click="handleClick" :tab-position="tabPosition">
      <el-tab-pane label="全部活动" name="allActivities">
        <all-activities v-if="activeType === 'allActivities'" :key="activeType" />
      </el-tab-pane>
      <el-tab-pane label="可报名活动" name="availableActivities">
        <available-activities v-if="activeType === 'availableActivities'" :key="activeType" />
      </el-tab-pane>
      <el-tab-pane label="已报名活动" name="selectedActivities">
        <selected-activities v-if="activeType === 'selectedActivities'" :key="activeType" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import AllActivities from './AllActivities.vue';
import AvailableActivities from './AvailableActivities.vue';
import SelectActivities from './SelectActivities.vue';

import {
  Form,
  FormItem,
  Input,
  Col,
  Row,
  Divider,
  Tabs,
  TabPane,
} from "element-ui";

export default {
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-col": Col,
    "el-row": Row,
    "el-divider": Divider,
    "el-tabs": Tabs,
    "el-tab-pane": TabPane,
    "all-activities": AllActivities,
    "available-activities": AvailableActivities,
    "selected-activities": SelectActivities,
  },

  data() {
    return {
      isLoading: true,
      personalInfo: [],
      activeType: 'allActivities',
      tabPosition: 'left',
    };
  },

  async created() {
    await this.loadInfo();
    this.isLoading = false;
  },

  methods: {
    async loadInfo() {
      try {
        const response = await this.$axios.get('/assistance/activity/stu/personal-info', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          }
        });
        if (response.data.success) {
          this.personalInfo = response.data.result;
        } else {
          this.$message({
            type: 'warning',
            message: '获取失败',
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    handleClick(tab) {
      this.activeType = tab.name;
    }
  }
};
</script>

<style scoped>
.custom-popper {
  margin-left: 30px;
}
</style>