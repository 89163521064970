<template>
  <background title="学生资助" desc="目前仅支持web端">
    <div class="detail-page">
      <h2>谈心谈话</h2>
      <el-form :model="recordDetail">
        <el-row :span="40">
          <el-col :span="24">
            <el-form-item label="谈话学期">
              <el-select v-model="recordDetail.term" :disabled="!isEditing" placeholder="请选择学期">
                <el-option v-for="(term, index) in termList" :key="index" :label="term.name"
                  :value="term.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="谈话时间">
              <el-date-picker v-model="recordDetail.time" :disabled="!isEditing" type="datetime"
                placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="记录人">
              <el-input v-model="recordDetail.recorder" :disabled="!isEditing"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="谈话地点">
              <el-select v-model="recordDetail.location" :disabled="!isEditing" placeholder="请选择" style="width: 100%">
                <el-option v-for="(location, i) in talkLocations" :key="i" :value="location"
                  :label="location.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="谈话内容">
              <el-input type="textarea" v-model="recordDetail.talkContent" placeholder="50-500字之间" :rows="4"
                maxlength="500" show-word-limit style="width: 100%" :disabled="!isEditing"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="查询学生信息" v-if="isEditing">
              <el-input v-model="searchQuery" placeholder="请输入姓名或一卡通号查询" :disabled="!isEditing"></el-input>
              <el-button type="primary" @click="searchStudent" style="margin-top: 8px" v-if="isEditing">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="选择学生" v-if="studentList.length > 1 && isEditing">
              <el-select v-model="selectedStudent" placeholder="请选择学生" @change="updateStudentInfo"
                :disabled="!isEditing" v-if="studentList.length > 1 && isEditing">
                <el-option v-for="(student, index) in studentList" :key="index"
                  :label="`${student.name}(${student.id})`" :value="student"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <p>姓名：<span class="info-text">{{ recordDetail.studentInfo.name }}</span></p>
              <p>一卡通号：<span class="info-text">{{ recordDetail.studentInfo.cardNumber }}</span></p>
              <p>性别：<span class="info-text">{{ recordDetail.studentInfo.gender }}</span></p>
              <p>学院：<span class="info-text">{{ recordDetail.studentInfo.college }}</span></p>
              <p>年级：<span class="info-text">{{ recordDetail.studentInfo.grade }}</span></p>
              <p>困难类型：<span class="info-text">{{ recordDetail.studentInfo.difficultyType }}</span></p>
              <p>困难等级：<span class="info-text">{{ recordDetail.studentInfo.difficultyLevel }}</span></p>
              <!-- <p>困难说明：<span class="info-text">{{ recordDetail.studentInfo.difficultyDescription }}</span></p> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24" class="button-group">
          <el-button type="primary" @click="isEditing ? saveRecord() : editRecord()">修改记录</el-button>
          <el-button type="danger" @click="deleteRecord">删除记录</el-button>
          <el-button @click="goBack">返回</el-button>
        </el-col>
      </el-row>
    </div>
  </background>
</template>


<script>
import { Button, Form, FormItem, Col, Row, Select, Option, DatePicker, Input } from "element-ui";
import Background from "../Background.vue";

export default {
  components: {
    "el-button": Button,
    "el-form": Form,
    "el-form-item": FormItem,
    "el-col": Col,
    "el-row": Row,
    "el-select": Select,
    "el-option": Option,
    "el-date-picker": DatePicker,
    "el-input": Input,
    "background": Background,
  },
  data() {
    return {
      recordDetail: {
        term: "",
        time: "",
        recorder: "",
        location: "",
        studentInfo: {
          name: "",
          cardNumber: "",
          gender: "",
          college: "",
          grade: "",
          difficultyType: "",
          difficultyLevel: "",
          difficultyDescription: "",
        },
        talkContent: "",
      },
      isLoading: true,
      isEditing: false,
      talkLocations: [],
      termList: [],
      searchQuery: "",
      studentList: [],
      selectedStudent: null,
      showStudent: null,
    };
  },
  methods: {
    async loadTerm() {
      try {
        const response = await this.$axios.get("/term");
        if (response.data.success) {
          this.termList = response.data.result.list.reverse().slice(0, 2);
        } else {
          this.$message({
            type: "warning",
            message: "学期列表加载失败",
          });
        }
      } catch (error) {
        console.error("加载学期列表时出错:", error);
        this.$message({
          type: "error",
          message: "加载学期列表时出错",
        });
      }
    },
    async loadLocation() {
      try {
        const response = await this.$axios.get('/assistance/conversation/address');
        if (response.data.success) {
          this.talkLocations = response.data.result;
        } else {
          this.$message.error('获取数据失败');
        }
      } catch (error) {
        this.$message.error('请求失败');
      }
    },
    async searchStudent() {
      try {
        const response = await this.$axios.get(`/assistance/conversation/search-stu-info`, {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: {
            nameID: this.searchQuery,
          },
        });
        if (response.data.success) {
          if (response.data.result.length === undefined) {
            this.$message({
              type: "warning",
              message: "学生不存在",
            });
            return;
          }
          this.studentList = response.data.result;
          if (this.studentList.length === 1) {
            this.selectedStudent = this.studentList[0];
          }
        } else {
          this.$message({
            type: "warning",
            message: "学生信息查询失败",
          });
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "查询学生信息时出错",
        });
      }
    },
    updateStudentInfo(student) {
      this.selectedStudent = `${student.name}(${student.id})`;
      this.recordDetail.studentInfo = {
        name: student.name,
        cardNumber: student.id,
        gender: student.gender,
        college: student.school,
        grade: student.grade,
        difficultyType: student.trouble_type,
        difficultyLevel: student.trouble_grade,
        difficultyDescription: student.trouble_explain,
      };
    },
    async deleteRecord() {
      try {
        const response = await this.$axios.delete(
          `/assistance/conversation/delete`,
          {
            headers: {
              "x-api-token": this.$axios.defaults.headers["x-api-token"],
            },
            params: {
              conversationId: this.getConversationId()
            }
          }
        );
        if (response.data.success) {
          this.$message({
            type: "success",
            message: "记录删除成功",
          });
          this.goBack();
        } else {
          this.$message({
            type: "error",
            message: "记录删除失败",
          });
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "更新记录时出错",
        });
      }
    },
    goBack() {
      this.$router.push({ name: "Assistance" });
    },
    editRecord() {
      this.isEditing = true;
    },
    async saveRecord() {
      if (this.recordDetail.talkContent.length < 50 || this.recordDetail.talkContent.length > 500) {
        this.$message({
          type: "warning",
          message: "谈话内容的字数在50-500字之间"
        });
        return;
      }
      try {
        const payload = {
          id: this.getConversationId(),
          time: this.recordDetail.time,
          recorderName: this.recordDetail.recorder,
          address: this.recordDetail.location,
          stuInfo: {
            stuName: this.recordDetail.studentInfo.name,
            stuSchool: this.recordDetail.studentInfo.college,
            stuID: this.recordDetail.studentInfo.cardNumber,
            stuGrade: this.recordDetail.studentInfo.grade,
            stuHardRating: this.recordDetail.studentInfo.difficultyLevel,
            stuHardType: this.recordDetail.studentInfo.difficultyType,
            stuHardIntro: this.recordDetail.studentInfo.difficultyDescription,
          },
          content: this.recordDetail.talkContent,
          semester: this.recordDetail.term
        };
        const response = await this.$axios.put(
          `/assistance/conversation/edit`,
          payload
        );
        if (response.data.success) {
          this.$message({
            type: "success",
            message: "记录更新成功",
          });
          this.isEditing = false;
        } else {
          this.$message({
            type: "warning",
            message: "记录更新失败",
          });
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "更新记录时出错",
        });
      }
    },
    getConversationId() {
      const id = this.$route.params.id || localStorage.getItem('conversationId');
      if (!id) {
        this.$message({
          type: "error",
          message: "无法获取会话ID",
        });
        throw new Error("无法获取会话ID");
      }
      localStorage.setItem('conversationId', id);
      return id;
    },
  },
  async created() {
    this.loadTerm();
    this.loadLocation();
    try {
      const response = await this.$axios.get(
        `/assistance/conversation/detail`,
        {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: {
            conversation_id: this.getConversationId(),
          },
        }
      );
      if (response.data.success) {
        const result = response.data.result;
        this.recordDetail.term = result.semester;
        this.recordDetail.time = result.time;
        this.recordDetail.recorder = result.recorder;
        this.recordDetail.location = result.address;

        this.recordDetail.studentInfo.name = result.name;
        this.recordDetail.studentInfo.cardNumber = result.id;
        this.recordDetail.studentInfo.gender = result.gender;
        this.recordDetail.studentInfo.college = result.school;
        this.recordDetail.studentInfo.grade = result.grade;
        this.recordDetail.studentInfo.difficultyType = result.trouble_type;
        this.recordDetail.studentInfo.difficultyLevel = result.trouble_grade;
        this.recordDetail.studentInfo.difficultyDescription = result.trouble_explain;
        this.recordDetail.talkContent = result.content;

        this.$message({
          type: "success",
          message: "数据加载成功",
        });
      } else {
        this.$message({
          type: "warning",
          message: "数据加载失败",
        });
      }
    } catch (error) {
      this.$message({
        type: "error",
        message: "加载记录详情时出错",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
</script>


<style scoped>
.detail-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.button-group {
  text-align: center;
  margin-top: 20px;
}

.button-group .el-button {
  margin: 0 10px;
}

.info-text {
  font-size: 18px;
}
</style>
