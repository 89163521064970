<template>
    <div v-loading="isLoading">
        <el-form class="custom-popper">
            <el-row :gutter="10" type="flex">
                <el-col :xs="16" :sm="16" :md="24">
                    <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 100%;">
                        <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
                            :value="option.name"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24">
                    <el-input v-model="searchQueryRecords" placeholder="输入姓名或一卡通号查询" style="width: 100%;"></el-input>
                </el-col>
                <el-col :xs="6" :sm="6" :md="8">
                    <el-button type="primary" @click="fetchRecords" style="width: 100%;">查询</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 20px;">
                <el-col :span="16">
                    <el-button type="primary" @click="exportExcel" style="margin-top: 8px;">一键导出Excel</el-button>
                    <el-checkbox v-model="selectAll" @change="toggleSelectAll">全选</el-checkbox>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <h3>谈话记录列表</h3>
                    <div style="overflow-y: auto;">
                        <el-table :data="paginatedRecordList" border fit>
                            <el-table-column prop="name" label="姓名"></el-table-column>
                            <el-table-column prop="cardNumber" label="一卡通号"></el-table-column>
                            <el-table-column prop="talkTime" label="谈话时间"></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-checkbox @change="toggleSelect"
                                        v-model="scope.row.selected">导出Excel</el-checkbox>
                                </template>
                            </el-table-column>
                            <el-table-column label="详情" width="150">
                                <template slot-scope="scope">
                                    <el-button @click="goToDetail(scope.row.conversationId)">详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>
            <el-pagination background layout="total, prev, pager, next, jumper" :current-page.sync="currentPage"
                :page-size="pageSize" :total="totalRecords" @current-change="showDifferentPage">
                第{{ currentPage }}页
            </el-pagination>

        </el-form>
    </div>
</template>


<script>
import {
    Button,
    Form,
    Input,
    Checkbox,
    Col,
    Row,
    TableColumn,
    Table,
    Pagination,
    Select,
    Option
} from "element-ui";
import exportToExcel from '../../plugins/export.js';

export default {
    components: {
        "el-button": Button,
        "el-form": Form,
        "el-input": Input,
        "el-col": Col,
        "el-checkbox": Checkbox,
        "el-row": Row,
        "el-table-column": TableColumn,
        "el-table": Table,
        "el-pagination": Pagination,
        "el-select": Select,
        "el-option": Option
    },
    data() {
        return {
            isLoading: true,
            searchQueryRecords: "",
            recordList: [],
            selectAll: false,
            currentPage: 1,
            totalRecords: 0,
            pageSize: 25,
            selectedTerm: '',
            termList: [],
        };
    },
    methods: {
        async loadTerm() {
            try {
                const response = await this.$axios.get("/term");
                if (response.data.success) {
                    this.termList = response.data.result.list.reverse().slice(0, 2);
                    this.termList.push({ name: "全部学期" });
                } else {
                    this.$message({
                        type: "warning",
                        message: "学期列表加载失败",
                    });
                }
            } catch (error) {
                console.error("加载学期列表时出错:", error);
                this.$message({
                    type: "error",
                    message: "加载学期列表时出错",
                });
            }
        },
        async fetchRecords() {
            try {
                const response = await this.$axios.get(`/assistance/conversation/search`, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        keyWord: this.searchQueryRecords,
                        semester: this.selectedTerm,
                    },
                });
                if (response.data.success) {
                    const result = response.data.result;
                    this.totalRecords = result.length;
                    this.recordList = result.map(item => ({
                        conversationId: item.id,
                        name: item.studentName,
                        cardNumber: item.studentId,
                        talkTime: item.conversationTime,
                        selected: false
                    }));
                    this.$message({
                        type: 'success',
                        message: '查询成功'
                    });
                } else {
                    this.$message({
                        type: 'warning',
                        message: '查询失败'
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '查询记录时出错'
                });
            }
        },
        async exportExcel() {
            const selectedIds = this.recordList
                .filter(record => record.selected)
                .map(record => record.conversationId);

            if (selectedIds.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择要导出的记录'
                });
                return;
            }
            try {
                const response = await this.$axios.get(`/assistance/conversation/export`, {
                    headers: {
                        "x-api-token": this.$axios.defaults.headers["x-api-token"],
                    },
                    params: {
                        idList: selectedIds.join(',')
                    },
                });
                if (response.data.success) {
                    const map = {
                        time: '谈话时间',
                        recorderName: '记录人',
                        address: '地点',
                        stuId: '一卡通号',
                        stuName: '学生姓名',
                        stuSchool: '学院',
                        stuGrade: '年级',
                        stuGender: '性别',
                        trouble_grade: '困难等级',
                        trouble_type: '困难类型',
                        content: '谈话内容',
                        semester: '学期'
                    };

                    const data = response.data.result.map(item => ({
                        time: item.time,
                        recorderName: item.recorderName,
                        address: item.address,
                        stuId: item.stuId,
                        stuName: item.stuName,
                        stuSchool: item.stuSchool,
                        stuGrade: item.stuGrade,
                        stuGender: item.stuGender,
                        trouble_grade: item.trouble_grade,
                        trouble_type: item.trouble_type,
                        content: item.content,
                        semester: item.semester
                    }));

                    exportToExcel(map, data, '谈心谈话记录');

                    this.$message({
                        type: 'success',
                        message: '导出成功'
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: '导出失败'
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: '导出记录时出错'
                });
            }
        },
        showDifferentPage(page) {
            this.currentPage = page;
        },
        goToDetail(conversationId) {
            this.$router.push({
                name: 'DetailedStudentInfomation',
                params: { id: conversationId }
            });
        },
        toggleSelectAll() {
            this.recordList.forEach(record => {
                record.selected = this.selectAll;
            });

        },
        toggleSelect() {
            for (let i = 0; i < this.recordList.length; i++) {
                if (this.recordList[i].selected === false) {
                    this.selectAll = false;
                    return;
                }
            }
            this.selectAll = true;
        }
    },
    async created() {
        this.isLoading = true;
        await this.loadTerm();
        await this.fetchRecords();
        this.isLoading = false;
    },
    computed: {
        sortedRecordList() {
            if (this.recordList === null) {
                return [];
            }
            return this.recordList.slice().sort((a, b) => {
                const [dateA, timeA] = a.talkTime.split(' ') || "";
                const [dateB, timeB] = b.talkTime.split(' ') || "";
                if (dateA !== dateB) {
                    return new Date(dateB) - new Date(dateA);
                } else {
                    return timeB.localeCompare(timeA);
                }
            });
        },
        paginatedRecordList() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = this.currentPage * this.pageSize;
            return this.sortedRecordList.slice(start, end);
        }
    },
    watch: {
        selectedTerm() {
            this.fetchRecords();
        },
    }
}
</script>

<style lang="less" scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-row {
    margin-bottom: 5px;
    /* 调整行间距 */
}

.el-col {
    padding-right: 10px;
    /* 调整右侧间距 */
}

.custom-popper {
    margin-left: 15px;
}

.second-col-popper {
    margin-left: 150px;
}

.reduce-margin-top {
    margin-top: -200px;
}
</style>