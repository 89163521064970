import Vue from 'vue'

Vue.prototype.$device = {}
// 原格式match()=='xxx'疑似有些问题，采用了隐式转换，更换为Array的API
Vue.prototype.$device.isWechat = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ?
    window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i).includes('micromessenger') : false;
Vue.prototype.$device.isWxwork = window.navigator.userAgent.toLowerCase().match(/wxwork/i) ?
    window.navigator.userAgent.toLowerCase().match(/wxwork/i).includes('wxwork') : false;
Vue.prototype.$device.isDesktop = window.innerWidth > 480
Vue.prototype.$device.isMobile = window.innerWidth <= 480
Vue.prototype.$device.height = window.innerHeight
Vue.prototype.$device.width = window.innerWidth
Vue.prototype.$device.platform = () => {
    let device = window.innerWidth < 480 ? 'mobile' : 'desktop'
    if (Vue.prototype.$device.isWechat) {
        return `wechat-web-${device}`
    } else {
        return `web-${device}`
    }
}
