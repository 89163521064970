import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import moment from 'moment'

const vuexLocalStorage = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    tipUnlike: '',
    token: '',
    gobalSearchText: "",
    gobalSearchType: "",
    hasUnfinishedRoute: false,
    unfinishedRoute: {},
    permission: [],
    roleHasLoad: false,
    function: {},
    role: {},
    cardnum: "",
    name: "",
    ncpVehicleData:{}
  },
  mutations: {
    saveUnfinishedRoute(state, payload) {
      state.hasUnfinishedRoute = true
      state.unfinishedRoute = payload
    },
    clearUnfinishedRoute(state) {
      state.hasUnfinishedRoute = false
    },
    clearCache(state) {
      state.hasUnfinishedRoute = false
      state.unfinishedRoute = {}
      state.token = ""
      state.function = {}
      state.role = {}
      state.cardnum = ""
      state.permission = []
      state.gobalSearchText = ""
      state.gobalSearchType = ""
      state.name = ""
      state.roleHasLoad = false
    },
    clearCacheWithoutRoute(state){
      state.token = ""
      state.function = {}
      state.role = {}
      state.cardnum = ""
      state.permission = []
      state.gobalSearchText = ""
      state.gobalSearchType = ""
      state.name = ""
      state.roleHasLoad = false
    },
    token(state, payload) {
      state.token = payload
    },
    roleHasLoad(state,payload) {
      state.roleHasLoad = payload
    },
    ncpVehicleData(state, payload){
      state.ncpVehicleData = payload;
    },
    tipUnlike(state){
      state.tipUnlike = moment().format('YYYY-MM-DD')
    },
    permission(state, payload) {
      state.permission = payload
    },
    role(state,payload){
      state.role = payload;
    },
    function(state,payload){
      state.function = payload;
    },
    gobalSearchType(state, payload) {
      state.gobalSearchType = payload
    },
    gobalSearchText(state, payload) {
      state.gobalSearchText = payload
    },
    cardnum(state, payload) {
      state.cardnum = payload
    },
    name(state, payload) {
      state.name = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
