<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div v-loading="isLoading">
    <el-divider>
      <h3 style="text-align: center; margin-bottom: 20px;">创建活动</h3>
    </el-divider>
    <el-form class="custom-popper" ref="form" :model="form" :rules="rules" label-width="120px">
      <el-row>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动名称" prop="activityName">
            <el-input v-model="form.activityName" placeholder="请输入活动名称" style="width: 80%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动起止时间" prop="activityTime">
            <el-date-picker v-model="form.activityTime" type="datetimerange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" style="width: 80%;">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动时长(小时)" prop="activityPeriod">
            <el-input-number v-model="form.activityPeriod" :min="0" label="活动时长"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动所属学期" prop="term">
            <el-select v-model="form.term" placeholder="请选择">
              <el-option v-for="(term, index) in termList" :key="term.index" :label="term.name" :value="term.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动负责社团" prop="activityClub">
            <el-select v-model="form.activityClub" placeholder="请选择">
              <el-option v-for="club in clubs" :key="club.value" :label="club.label" :value="club.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动类型" prop="activityType">
            <el-select v-model="form.activityType" placeholder="请选择">
              <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="活动内容" prop="activityContent">
        <el-input type="textarea" placeholder="请输入内容" v-model="form.activityContent"
          :autosize="{ minRows: 2, maxRows: 4 }" maxlength="300" show-word-limit style="width: 80%;">
        </el-input>
      </el-form-item>

      <el-row>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动负责人" prop="managerList">
            <el-autocomplete v-model="managerQuery" :fetch-suggestions="fetchManagerSuggestions"
              placeholder="请输入负责人姓名或一卡通号" @select="handleManagerSelect" style="width: 80%;"></el-autocomplete>
            <div v-for="(manager, index) in form.managerList" :key="index" class="selected-item">
              {{ manager.name }} ({{ manager.id }})
              <el-button @click="removeManager(index)" type="text" size="mini">移除</el-button>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动参与人" prop="participantList">
            <el-autocomplete v-model="participantQuery" :fetch-suggestions="fetchParticipantSuggestions"
              placeholder="请输入参与人姓名或一卡通号" @select="handleParticipantSelect" style="width: 80%;"></el-autocomplete>
            <div v-for="(participant, index) in form.participantList" :key="index" class="selected-item">
              {{ participant.name }} ({{ participant.id }})
              <el-button @click="removeParticipant(index)" type="text" size="mini">移除</el-button>
            </div>
            <div class="upload-buttons">
              <el-upload class="upload-demo" ref="upload" action="" :http-request="handleFileUpload"
                :before-upload="beforeUpload" :on-change="handleChange" :show-file-list="false" accept=".xls,.xlsx">
                <el-button type="primary">点击上传活动参与人名单</el-button>
              </el-upload>
              <el-button @click="download">点击下载模板</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="活动面向对象" prop="activityTargetList">
            <el-checkbox-group v-model="form.activityTargetList">
              <el-checkbox v-for="option in options" :key="option.value" :label="option.value"
                :disabled="isDisabled(option.value)">
                {{ option.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="12">
          <el-form-item label="报名起止时间" prop="activitySignupTime">
            <el-date-picker v-model="form.activitySignupTime" type="datetimerange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" style="width: 80%;">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="form-buttons">
        <el-row justify="center">
          <el-form-item>
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-row>
      </div>
    </el-form>
  </div>
</template>






<script>
import * as XLSX from 'xlsx';
import exportToExcel from '../../plugins/export.js';
import {
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  DatePicker,
  Upload,
  Col,
  Row,
  Checkbox,
  CheckboxGroup,
  InputNumber,
  Divider,
  Autocomplete
} from "element-ui";

export default {
  components: {
    "el-button": Button,
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-select": Select,
    "el-option": Option,
    "el-date-picker": DatePicker,
    "el-upload": Upload,
    "el-col": Col,
    "el-row": Row,
    "el-checkbox": Checkbox,
    "el-checkbox-group": CheckboxGroup,
    "el-input-number": InputNumber,
    "el-divider": Divider,
    "el-autocomplete": Autocomplete,
  },
  data() {
    return {
      isLoading: true,
      form: {
        activityName: '',
        activityTime: [],
        activityPeriod: '',
        term: '',
        activityClub: '',
        activityType: '',
        activityContent: '',
        managerList: [],
        participantList: [],
        activityTargetList: [],
        activitySignupTime: [],
      },
      managerQuery: '',
      participantQuery: '',
      termList: [],
      clubs: [
        { value: '唐仲英爱心社', label: '唐仲英爱心社' },
        { value: '伯藜学社', label: '伯藜学社' },
        { value: '瑞华筑梦公益平台', label: '瑞华筑梦公益平台' },
        { value: '曾宪梓春晖公益平台', label: '曾宪梓春晖公益平台' },
        { value: '新地郭氏公益平台', label: '新地郭氏公益平台' }
      ],
      types: [
        { value: '日常管理', label: '日常管理' },
        { value: '思想引领', label: '思想引领' },
        { value: '成长互助', label: '成长互助' },
        { value: '品牌活动', label: '品牌活动' },
        { value: '资助中心', label: '资助中心' }
      ],
      options: [
        { value: 0, label: '全体学生' },
        { value: 1, label: '唐仲英爱心社' },
        { value: 2, label: '伯藜学社' },
        { value: 3, label: '瑞华筑梦公益平台' },
        { value: 4, label: '曾宪梓春晖公益平台' },
        { value: 5, label: '新地郭氏公益平台' },
        { value: 6, label: '家庭经济困难学生' },
      ],
      rules: {
        activityName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        activityTime: [
          { type: 'array', required: true, message: '请选择活动起止时间', trigger: 'change' }
        ],
        activityPeriod: [
          { required: false, message: '请输入活动时长', trigger: 'blur' }
        ],
        term: [
          { required: true, message: '请选择活动所属学期', trigger: 'change' }
        ],
        activityClub: [
          { required: true, message: '请选择活动负责社团', trigger: 'change' }
        ],
        activityType: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        activityContent: [
          { required: true, message: '请输入活动内容', trigger: 'blur' },
          { min: 50, max: 300, message: '活动内容长度在50到300字之间', trigger: 'blur' }
        ],
        managerList: [
          { required: true, message: '请输入活动负责人', trigger: 'blur' }
        ],
        participantList: [
          { required: false, message: '请输入活动参与人', trigger: 'blur' }
        ],
        activityTargetList: [
          { type: 'array', required: true, message: '请选择活动面向对象', trigger: 'change' }
        ],
        activitySignupTime: [
          { type: 'array', required: true, message: '请选择报名起止时间', trigger: 'change' }
        ]
      },
    };
  },
  computed: {
    activityTargetListString: function () {
      if (this.form.activityTargetList.includes(0)) return '0';
      else return this.form.activityTargetList.join(',');
    },
    managerListString: function () {
      return this.form.managerList.map(manager => manager.id).join(',');
    },
    participantListString: function () {
      return this.form.participantList.map(participant => participant.id).join(',');
    }
  },
  async created() {
    await this.loadTerm();
    this.isLoading = false;
  },
  methods: {
    async loadTerm() {
      try {
        let termList = await this.$axios.get("/term");
        if (termList.data.success) {
          this.termList = termList.data.result.list.filter(term => term.startDate >= 1724025600000).reverse();
          this.selectedTerm = termList.data.result.currentTerm.name;
          this.currentTerm = termList.data.result.currentTerm.name;
        } else {
          this.$message({
            type: "warning",
            message: "学期列表加载失败",
          });
        }
      } catch (error) {
        console.error("加载学期列表时出错:", error);
        this.$message({
          type: "error",
          message: "加载学期列表时出错",
        });
      }
    },

    download() {
      const data = [
        { id: '123456789', name: '小东' },
        { id: '987654321', name: '小南' }
      ]; // 示例数据

      const map = {
        id: '一卡通号',
        name: '姓名'
      }; // 字段映射表，用于Excel表头

      exportToExcel(map, data, "参与人名单");
    },

    isDisabled(value) {
      if (value === 0) return false;
      else if (this.form.activityTargetList.includes(0)) {
        return true;
      }
    },

    beforeUpload(file) {
      const isXLS = file.type === 'application/vnd.ms-excel';
      const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isValid = isXLS || isXLSX;
      if (!isValid) {
        this.$message.error('只能上传 .xls 或 .xlsx 文件');
      }
      return isValid;
    },

    // eslint-disable-next-line no-unused-vars
    handleChange(file, fileList) {
      console.log('选择的文件:', file);
    },

    handleFileUpload({ file }) {
      console.log('Uploaded file object:', file); // Log file object
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const participantArray = json.slice(1).map(row => {
            // Trim whitespace from each cell in the row
            const trimmedRow = row.map(cell => (typeof cell === 'string' ? cell.trim() : cell));

            // Extract the ID and name, which are assumed to be the first two columns
            const id = trimmedRow[0];
            const name = trimmedRow[1];

            // Only include rows where both ID and name are present after trimming
            if (id && name) {
              return { id, name };
            }
          }).filter(Boolean); // Filter out any null or undefined entries

          this.form.participantList = participantArray;

        } catch (error) {
          console.error('Error parsing file:', error);
        }
      };
      reader.readAsArrayBuffer(file);
    },




    async fetchManagerSuggestions(queryString, callback) {
      if (!queryString) {
        callback([]);
        return;
      }
      try {
        const response = await this.$axios.get('/assistance/activity/manager/admin/search-st-info', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { keyWord: queryString }
        });
        if (response.data.success) {
          const suggestions = response.data.result.map(student => ({
            value: `${student.name} (${student.id})`,
            id: student.id
          }));
          callback(suggestions);
        } else {
          callback([]);
        }
      } catch (error) {
        console.error('Error fetching student suggestions:', error);
        callback([]);
      }
    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    handleManagerSelect(item) {
      this.form.managerList.push({
        name: item.value.split(' (')[0],
        id: item.id
      });
      this.managerQuery = ''; // 保持查询输入框为空
      this.$refs.form.validateField('managerList');
    },

    removeManager(index) {
      this.form.managerList.splice(index, 1);
      this.$refs.form.validateField('managerList');
    },

    async fetchParticipantSuggestions(queryString, callback) {
      if (!queryString) {
        callback([]);
        return;
      }
      try {
        const response = await this.$axios.get('/assistance/activity/manager/admin/search-stu-info', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { nameID: queryString }
        });
        if (response.data.success) {
          const suggestions = response.data.result.map(student => ({
            value: `${student.name} (${student.id})`,
            id: student.id
          }));
          callback(suggestions);
        } else {
          callback([]);
        }
      } catch (error) {
        console.error('Error fetching student suggestions:', error);
        callback([]);
      }
    },

    handleParticipantSelect(item) {
      this.form.participantList.push({
        name: item.value.split(' (')[0],
        id: item.id
      });
      this.participantQuery = ''; // 保持查询输入框为空
    },

    removeParticipant(index) {
      this.form.participantList.splice(index, 1);
    },

    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.isLoading = true;
          try {
            const body = {
              activityName: this.form.activityName,
              activityStartTime: this.formatDate(this.form.activityTime[0]),
              activityEndTime: this.formatDate(this.form.activityTime[1]),
              activityPeriod: this.form.activityPeriod,
              activitySemester: this.form.term,
              activityClub: this.form.activityClub,
              activityType: this.form.activityType,
              activityContent: this.form.activityContent,
              managerList: this.managerListString,
              participantList: this.participantListString,
              activityTargetList: this.activityTargetListString,
              activitySignupStartTime: this.formatDate(this.form.activitySignupTime[0]),
              activitySignupEndTime: this.formatDate(this.form.activitySignupTime[1]),
            };
            const res = await this.$axios.post('/assistance/activity/manager/advanced/create', body, {
              headers: {
                "x-api-token": this.$axios.defaults.headers["x-api-token"],
              }
            });
            if (res.data.success) {
              this.$message({
                type: 'success',
                message: '提交成功',
              });
              this.isLoading = false;
              this.resetForm();
            } else {
              this.$message({
                type: 'warning',
                message: '提交失败',
              });
              console.log(body);
              this.isLoading = false;
            }
          } catch (error) {
            console.error('Error:', error);
          }
        } else {
          this.$message.error('请完善表单内容');
          return false;
        }
      });
    },

    resetForm() {
      this.$refs.form.resetFields();
      this.form.managerList = [];
      this.form.participantList = [];
    }
  }
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 20px;
  /* 调整下方间距以增加元素之间的空间 */
}

.el-row {
  margin-bottom: 5px;
  /* 调整行间距 */
}

.el-col {
  padding-right: 10px;
  /* 调整右侧间距 */
}

.custom-popper {
  margin-left: 30px;
  /* 确保学期选择框左边距 */
}

.second-col-popper {
  margin-left: 150px;
}

.reduce-margin-top {
  margin-top: -200px;
}

.upload-demo {
  margin: 20px 0;
}

.form-buttons {
  text-align: center;
  margin-top: 20px;
}

.selected-item {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.selected-item .el-button {
  margin-left: 10px;
}

.upload-buttons {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: flex-start;
  /* 水平开始位置对齐 */
  gap: 10px;
  /* 按钮之间的间距 */
}

</style>