<template>
  <fragment>
    <el-button
      :class="filtered ? 'counselor-search-button-filtered' : ''"
      style="float: right"
      icon="el-icon-search"
      @click="handleOpenDrawer"
      v-if="!isWeiXin && !isMobile"
    >
      搜索
    </el-button>
    <span
      :class="filtered ? 'counselor-search-button-filtered' : 'counselor-search-button-not-filtered'"
      v-else
      @click="handleOpenDrawer"
      style="cursor: pointer; user-select: none; float: right"
    >
      <i class="el-icon-search"></i>
      搜索
    </span>
    <el-drawer :size="isWeiXin || isMobile ? '100%' : '450px'" title="查找" :visible.sync="dialogVisible" @close="handleClose">
      <el-form label-width="80px" style="margin-right: 1.3em" :model="searchData">
        <el-form-item v-for="item in searchProps" :key="item.key" :label="item.label">
          <el-input @input="$forceUpdate()" v-model="searchData[item.key]" v-if="item.type == searchTypes.subString" clearable></el-input>
          <div style="display: flex" v-if="item.type == searchTypes.datePeriod">
            <el-date-picker @input="$forceUpdate()" v-model="searchData[item.key][0]" value-format="timestamp" clearable placeholder="开始日期">
            </el-date-picker>
            <div style="margin: 0 5px">-</div>
            <el-date-picker @input="$forceUpdate()" v-model="searchData[item.key][1]" value-format="timestamp" clearable placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-select v-if="item.type == searchTypes.options" @input="$forceUpdate()" v-model="searchData[item.key]" multiple placeholder="请选择">
            <el-option v-for="option in item.values" :key="option.value" :label="option.label" :value="option.value"> </el-option>
          </el-select>
          <el-select @input="$forceUpdate()" v-if="item.type == searchTypes.tags" v-model="searchData[item.key]" multiple filterable allow-create @change="console.log(searchData[item.key])" />
        </el-form-item>
      </el-form>
      <span style="margin-right: 1.3em; display: flex; justify-content: space-between">
        <span style="margin-left: 80px">
          <el-button @click="handleReset">清 空</el-button>
        </span>
        <span>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleOk">确 定</el-button>
        </span>
      </span>
    </el-drawer>
  </fragment>
</template>

<script>
import { DatePicker, Drawer, Form, FormItem, Option, Select } from 'element-ui';
import { Fragment } from 'vue-fragment';
import moment from 'moment';

export const searchTypes = {
  datePeriod: 'datePeriod',
  subString: 'subString',
  options: 'options',
  tags: 'tags'
};

const searchInitialValue = {
  datePeriod: [null, null],
  subString: '',
  options: [],
  tags: []
};

// 示例 searchProps
// export const Examle = [
//   {
//     lable: '姓名',
//     type: searchTypes.subString,
//     key: 'name'
//   },
//   {
//     label: '性别',
//     type: searchTypes.options,
//     key: 'gender',
//     values: [
//       { label: '男', value: '男' },
//       { label: '女', value: '女' }
//     ]
//   }
// ];

export default {
  components: {
    'el-date-picker': DatePicker,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-drawer': Drawer,
    Fragment,
    'el-select': Select,
    'el-option': Option
  },
  props: {
    searchProps: {
      default: new Array(),
      type: Array
    },
    dataList: {
      default: new Array(),
      type: Array
    }
  },
  data() {
    return {
      console,
      searchData: {},
      searchDataBak: {},
      searchTypes,
      dialogVisible: false,
      filtered: false,

      ok: false
    };
  },
  created() {
    this.initValue();
  },
  computed: {
    isWeiXin() {
      const ua = window.navigator.userAgent.toLowerCase();
      return ua.indexOf('micromessenger') > -1;
    },
    isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    }
  },
  methods: {
    handleOpenDrawer() {
      this.searchDataBak = JSON.parse(JSON.stringify(this.searchData));
      this.dialogVisible = true;
    },
    handleClose() {
      if (!this.ok) this.searchData = this.searchDataBak;
      this.ok = false;
    },
    initValue() {
      for (let searchProp of this.searchProps) this.searchData[searchProp.key] = JSON.parse(JSON.stringify(searchInitialValue[searchProp.type]));
      this.filtered = false;
    },
    handleReset() {
      this.initValue();
      this.$forceUpdate();
    },
    handleOk() {
      this.ok = true;
      this.dialogVisible = false;
      this.checkFiltered();
      this.$emit(
        'ok',
        this.dataList.filter((dataItem) => {
          let result = true;
          for (let searchProp of this.searchProps) {
            switch (searchProp.type) {
              case searchTypes.subString:
                result = result && (this.searchData[searchProp.key] == '' || dataItem[searchProp.key].indexOf(this.searchData[searchProp.key]) != -1);
                break;
              case searchTypes.datePeriod:
                result =
                  result &&
                  (this.searchData[searchProp.key][0] == null || +moment(dataItem[searchProp.key], 'YYYY/MM/DD') >= this.searchData[searchProp.key][0]) &&
                  (this.searchData[searchProp.key][1] == null || +moment(dataItem[searchProp.key], 'YYYY/MM/DD') <= this.searchData[searchProp.key][1]);
                break;
              case searchTypes.options:
                result =
                  result &&
                  (this.searchData[searchProp.key].length == 0 ||
                    this.searchData[searchProp.key].some((selectedItem) => selectedItem == dataItem[searchProp.key]));
                break;
              case searchTypes.tags:
                console.log(this.searchData[searchProp.key], searchProp.getPlain(dataItem))
                result =
                  result &&
                  (this.searchData[searchProp.key].length == 0 || (
                    this.searchData[searchProp.key].some(selected => (searchProp.getPlain(dataItem).findIndex(str => str === selected) != -1))
                  ));
                break;
            }
          }
          return result;
        })
      );
    },
    checkFiltered() {
      this.filtered = this.searchProps.some((prop) => JSON.stringify(this.searchData[prop.key]) != JSON.stringify(searchInitialValue[prop.type]));
    }
  },
  watch: {
    dataList() {
      this.initValue();
    }
  }
};
</script>

<style>
.counselor-search-button-filtered {
  color: red;
}

.counselor-search-button-not-filtered {
  color: #40aaff;
}

.el-drawer__header > span {
  outline: none;
}

.el-drawer {
  outline: none;
}
</style>
