<template>
  <div v-loading="isLoading">
    <el-divider>
      <h3 style="text-align: center; margin-bottom: 20px;">查看学生信息</h3>
    </el-divider>

    <el-container style="justify-content: center; align-items: center;">
      <el-autocomplete v-model="searchQuery" :fetch-suggestions="fetchStudentSuggestions" placeholder="请输入学生姓名或一卡通号"
        style="width: 300px;" @select="handleSelect"></el-autocomplete>
      <el-select v-model="selectedTerm" placeholder="请选择学期" style="width: 160px; margin-left: 10px;">
        <el-option v-for="(option, index) in termList" :key="index" :label="option.name"
          :value="option.name"></el-option>
      </el-select>
      <el-button type="primary" @click="fetchActivities">查询</el-button>
    </el-container>

    <el-divider>
      <h4 style="text-align: center; margin-bottom: 20px;">活动列表</h4>
    </el-divider>

    <el-table :data="activities" style="width: 100%; margin-top: 20px;" @filter-change="handleFilterChange">
      <el-table-column prop="startTime" label="开始时间">
        <template slot="header" scope="scope">
          <span>{{ scope.column.label }}</span>
          <el-button size="mini" :icon="order ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            @click="toggleSort()"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="活动名称"></el-table-column>
      <el-table-column prop="club" label="负责社团" :filters="clubs" :filter-multiple="false" column-key="club">
      </el-table-column>
      <el-table-column prop="type" label="活动类型" :filters="activityTypes" :filter-multiple="false" column-key="type">
      </el-table-column>
      <el-table-column prop="status" label="完成情况" :filters="statusFilters" :filter-multiple="false"  column-key="state">
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="viewDetails(scope.row)" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogVisible" title="活动详情" width="60%">
      <div v-if="activityDetails" class="details-grid">
        <el-form :model="activityDetails" label-position="left" label-width="120px">
          <el-form-item label="活动名称：">
            <el-input v-model="activityDetails.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="开始时间：">
            <el-input v-model="activityDetails.startTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="结束时间：">
            <el-input v-model="activityDetails.endTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="负责社团：">
            <el-input v-model="activityDetails.club" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动类型：">
            <el-input v-model="activityDetails.type" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动内容：" :autosize="{ minRows: 2, maxRows: 4 }">
            <el-input v-model="activityDetails.content" type="textarea" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动时长（h）：">
            <el-input v-model="activityDetails.duration" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动所属学期：">
            <el-input v-model="activityDetails.semester" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="负责人：" :autosize="{ minRows: 2, maxRows: 4 }">
            <el-input v-model="activityDetails.managerList" type="textarea" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="参与人：" :autosize="{ minRows: 2, maxRows: 4 }">
            <el-input v-model="activityDetails.participantList" type="textarea" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="活动面向对象：">
            <el-input v-model="activityDetails.activityTargetDescription" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="报名开始时间：">
            <el-input v-model="activityDetails.activitySignupStartTime" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="报名结束时间：">
            <el-input v-model="activityDetails.activitySignupEndTime" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  Button,
  Input,
  Table,
  TableColumn,
  Divider,
  Container,
  Autocomplete,
  Select,
  Dialog,
  Option,
  FormItem,
  Form
} from "element-ui"

export default {
  components: {
    "el-button": Button,
    "el-input": Input,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-divider": Divider,
    "el-container": Container,
    "el-autocomplete": Autocomplete,
    "el-select": Select,
    "el-dialog": Dialog,
    "el-option": Option,
    "el-form-item": FormItem,
    "el-form": Form,
  },
  data() {
    return {
      searchQuery: '',
      activities: [],
      totalActivities: 0,
      selectedTerm: '',
      termList: [],
      studentInfo: {
        name: '',
        id: ''
      },
      filters: {
        club: null,
        activityType: null,
        finishStatus: null,
      },
      clubs: [
        { text: '唐仲英爱心社', value: '唐仲英爱心社' },
        { text: '伯藜学社', value: '伯藜学社' },
        { text: '瑞华筑梦公益平台', value: '瑞华筑梦公益平台' },
        { text: '曾宪梓春晖公益平台', value: '曾宪梓春晖公益平台' },
        { text: '新地郭氏公益平台', value: '新地郭氏公益平台' },
      ],
      activityTypes: [
        { text: '日常管理', value: '日常管理' },
        { text: '思想引领', value: '思想引领' },
        { text: '成长互助', value: '成长互助' },
        { text: '品牌活动', value: '品牌活动' },
        { text: '资助中心', value: '资助中心' },
      ],
      statusFilters: [
        { text: '未开始', value: '未开始' },
        { text: '进行中', value: '进行中' },
        { text: '已结束', value: '已结束' }
      ],
      activityDetails: null,
      dialogVisible: false,
      isLoading: true,
      order: false
    };
  },

  async created() {
    await this.loadTerm();
    await this.fetchActivities();
  },

  methods: {
    async loadTerm() {
      try {
        let termList = await this.$axios.get("/term");
        if (termList.data.success) {
          this.termList = termList.data.result.list.filter(term => term.startDate >= 1724025600000).reverse();
          this.termList.unshift({ name: "全部学期" });  // 添加 "全部学期" 选项
          this.selectedTerm = "全部学期";
        } else {
          this.$message({
            type: "warning",
            message: "学期列表加载失败",
          });
        }
      } catch (error) {
        console.error("加载学期列表时出错:", error);
        this.$message({
          type: "error",
          message: "加载学期列表时出错",
        });
      }
    },

    async fetchStudentSuggestions(queryString, callback) {
      if (!queryString) {
        callback([]);
        return;
      }
      try {
        const response = await this.$axios.get('/assistance/activity/manager/admin/search-stu-info', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: { nameID: queryString }
        });
        if (response.data.success) {
          const suggestions = response.data.result.map(student => ({
            value: `${student.name} (${student.id})`,
            id: student.id
          }));
          callback(suggestions);
        } else {
          callback([]);
        }
      } catch (error) {
        console.error('Error fetching student suggestions:', error);
        callback([]);
      }
    },

    handleSelect(item) {
      this.studentInfo = {
        name: item.value.split(' (')[0],
        id: item.id,
      };
      this.fetchActivities();
    },

    toggleSort() {
      this.order = !this.order; // 切换布尔值，true为升序，false为降序
      this.sortActivities();
    },

    async fetchActivities() {
      if (!this.studentInfo.id) {
        this.isLoading = false;
        return;
      }
      try {
        this.isLoading = true;
        const response = await this.$axios.get('/assistance/activity/manager/admin/query-stu', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: {
            stuId: this.studentInfo.id,
            semester: this.selectedTerm,
            club: this.filters.club,
            activityType: this.filters.activityType,
            finishStatus: this.filters.finishStatus,
          }
        });
        if (response.data.success) {
          this.activities = response.data.result.map(activity => ({
            ...activity,
            startTime: this.formatDateString(activity.startTime),
          }));

          // 根据 order 进行排序
          this.sortActivities();

          this.isLoading = false;
        } else {
          this.$message.error('获取活动失败');
          this.activities = [];
          this.isLoading = false;
        }
      } catch (error) {
        console.error('Error fetching activities:', error);
        this.activities = [];
        this.isLoading = false;
      }
    },

    sortActivities() {
      this.activities.sort((a, b) => {
        const dateA = new Date(a.startTime);
        const dateB = new Date(b.startTime);
        return this.order ? dateA - dateB : dateB - dateA;
      });
    },

    getActivityTargetDescription(targetList) {
      const targetMapping = {
        '0': '全体学生',
        '1': '唐仲英爱心社',
        '2': '伯藜学社',
        '3': '瑞华筑梦公益平台',
        '4': '曾宪梓春晖公益平台',
        '5': '新地郭氏公益平台',
        '6': '家庭经济困难学生'
      };
      return targetList.split(',').map(id => targetMapping[id]).join(', ');
    },

    async viewDetails(activity) {
      try {
        this.isLoading = true;
        const response = await this.$axios.get('/assistance/activity/manager/detail', {
          headers: {
            "x-api-token": this.$axios.defaults.headers["x-api-token"],
          },
          params: {
            activityId: activity.id  // 假设活动对象中有id属性
          }
        });
        if (response.data.success) {
          this.activityDetails = response.data.result;
          this.activityDetails.activityTargetDescription = this.getActivityTargetDescription(this.activityDetails.activityTargetList);
          this.activityDetails.startTime = this.formatDateString(this.activityDetails.startTime);
          this.activityDetails.endTime = this.formatDateString(this.activityDetails.endTime);
          this.activityDetails.activitySignupStartTime = this.formatDateString(this.activityDetails.activitySignupStartTime);
          this.activityDetails.activitySignupEndTime = this.formatDateString(this.activityDetails.activitySignupEndTime);
          this.dialogVisible = true;  // 控制对话框显示
          this.isLoading = false;
        } else {
          this.$message.error('获取活动详情失败');
          this.isLoading = false;
        }
      } catch (error) {
        this.$message.error('请求活动详情时出错');
        this.isLoading = false;
      }
    },

    formatDateString(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    handleFilterChange(filters) {
      this.filters.club = filters.club ? filters.club[0] : this.filters.club;
      this.filters.activityType = filters.type ? filters.type[0] : this.filters.activityType;
      this.filters.finishStatus = filters.state ? filters.state[0] : this.filters.finishStatus;
      this.fetchActivities();
    },
  }
};
</script>


<style scoped>
.el-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

::v-deep .el-input__inner:disabled,
::v-deep .el-textarea__inner:disabled {
  color: black !important;
  /* 设置文本颜色为黑色 */
}
</style>
